'use client';

import {
  StepSchema,
  TokenGateCondition,
  TokenGateSettingRequirement,
  TokenGateSettingType,
} from '@formo/types';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { StepType } from '~/constants/fields';
import {
  AvailableCondition,
  availableRequirements,
} from '~/constants/token-gate';

import ConditionDrawer from '../_components/settings/TokenGate/ConditionDrawer';
import ConditionModal from '../_components/settings/TokenGate/ConditionModal';
import useFormBuilder from '../hooks/useFormBuilder';

export type CurrentCondition = TokenGateCondition & {
  index: number;
};

type TokenGateContextType = {
  // Available requirements
  availableRequirements: typeof availableRequirements;

  // Type
  type: TokenGateSettingType;
  setType: React.Dispatch<React.SetStateAction<TokenGateSettingType>>;
  onTypeChange: (value: TokenGateSettingType) => void;

  // Count
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  onCountChange: (value: number) => void;

  // Requirements
  requirements: TokenGateSettingRequirement;
  setRequirements: React.Dispatch<
    React.SetStateAction<TokenGateSettingRequirement>
  >;
  requirementsCount: number;
  onAddRequirement: (requirement: TokenGateCondition) => void;
  onUpdateRequirement: (index: number, requirement: TokenGateCondition) => void;

  // Methods
  onEnableRequirement: (type: keyof TokenGateSettingRequirement) => void;
  onDisableRequirement: (type: keyof TokenGateSettingRequirement) => void;

  // Condition modal
  openConditionSelect: keyof TokenGateSettingRequirement | null;
  onOpenConditionSelect: (type: keyof TokenGateSettingRequirement) => void;
  onCloseConditionSelect: () => void;

  // Condition drawer
  openConditionDrawer: AvailableCondition | null;
  onOpenConditionDrawer: (type: AvailableCondition) => void;
  onCloseConditionDrawer: () => void;
  onEditCondition: (
    index: number,
    requirementType: keyof TokenGateSettingRequirement,
    conditionType: NonNullable<
      TokenGateSettingRequirement[typeof requirementType]
    >[number]['type'],
  ) => void;
  onRemoveCondition: (
    index: number,
    requirementType: keyof TokenGateSettingRequirement,
  ) => void;

  // Current condition
  currentCondition: CurrentCondition | null;
  setCurrentCondition: React.Dispatch<
    React.SetStateAction<CurrentCondition | null>
  >;

  tokenGatePage: StepSchema;
};

const noop = async () => {};

export const TokenGateContext = createContext<TokenGateContextType>({
  availableRequirements,
  type: 'all',
  setType: noop,
  onTypeChange: noop,
  count: 1,
  setCount: noop,
  onCountChange: noop,
  requirements: {},
  setRequirements: noop,
  requirementsCount: 0,
  onAddRequirement: noop,
  onUpdateRequirement: noop,
  onEnableRequirement: noop,
  onDisableRequirement: noop,
  openConditionSelect: null,
  onOpenConditionSelect: noop,
  onCloseConditionSelect: noop,
  openConditionDrawer: null,
  onOpenConditionDrawer: noop,
  onCloseConditionDrawer: noop,
  onEditCondition: noop,
  onRemoveCondition: noop,
  currentCondition: null,
  setCurrentCondition: noop,
  tokenGatePage: {
    id: 'token-gate',
    name: 'Access Gate',
    type: StepType.AUTH,
    fields: [],
    defaultNextStep: '',
  },
} as TokenGateContextType);

type TokenGateProviderProps = {
  children: React.ReactNode;
};

const TokenGateProvider: React.FC<TokenGateProviderProps> = ({ children }) => {
  const { tokenGate, setTokenGate } = useFormBuilder();
  const { requirements, type: initialType } = tokenGate || {};

  const [type, setType] = useState<TokenGateSettingType>(initialType || 'all');

  const [tokenGatePage, setTokenGatePage] = useState<StepSchema>({
    id: 'token-gate',
    name: 'Access Gate',
    type: StepType.AUTH,
    fields: [],
    defaultNextStep: '',
  });

  const [count, setCount] = useState<number>(() => {
    if (tokenGate?.type === 'some') {
      return tokenGate.count || 1;
    }
    return 1;
  });

  const [requirementsState, setRequirementsState] =
    useState<TokenGateSettingRequirement>(() => {
      if (requirements) {
        return requirements;
      }
      return {
        discord: [],
        wallet: [],
        twitter: [],
      };
    });

  useEffect(() => {
    setRequirementsState(tokenGate?.requirements);
  }, [tokenGate?.requirements]);

  const [openConditionSelect, setOpenConditionSelect] = useState<
    keyof TokenGateSettingRequirement | null
  >(null);
  const [openConditionDrawer, setOpenConditionDrawer] =
    useState<AvailableCondition | null>(null);
  const [currentCondition, setCurrentCondition] =
    useState<CurrentCondition | null>(null);

  const onTypeChange = (value: TokenGateSettingType) => {
    setType(value);
    const temp =
      value === 'all'
        ? ({ type: 'all' } as const)
        : ({ type: 'some', count: 1 } as const);
    setTokenGate((prev) => ({
      ...prev,
      ...temp,
    }));
  };

  const requirementsCount = useMemo(() => {
    return Object.values(requirementsState).reduce(
      (acc, curr) => acc + curr.length,
      0,
    );
  }, [requirementsState]);

  const onCountChange = (value: number) => {
    const count = Math.max(1, value);
    setCount(count);
    setTokenGate((prev) => ({
      ...prev,
      count,
    }));
  };

  const onAddRequirement = (requirement: TokenGateCondition) => {
    if (!openConditionDrawer) return;
    const { requirementType } = openConditionDrawer;

    const temp = requirementsState[requirementType] || [];
    const cloned = [...temp];
    cloned.push(requirement);

    const newRequirements = {
      ...requirementsState,
      [requirementType]: cloned,
    };
    setRequirementsState(newRequirements);
    setTokenGate((prev) => ({
      ...prev,
      requirements: newRequirements,
    }));
  };

  const onUpdateRequirement = (
    index: number,
    requirement: TokenGateCondition,
  ) => {
    if (!openConditionDrawer) return;
    const { requirementType } = openConditionDrawer;

    const temp = requirementsState[requirementType] || [];
    const cloned = [...temp];
    cloned[index] = requirement;

    const newRequirements = {
      ...requirementsState,
      [requirementType]: cloned,
    };
    setRequirementsState(newRequirements);
    setTokenGate((prev) => ({
      ...prev,
      requirements: newRequirements,
    }));
  };

  const onEnableRequirement = (type: keyof TokenGateSettingRequirement) => {
    const temp = requirementsState[type] || [];
    const cloned = [...temp];
    let name = 'Verify identity';
    switch (type) {
      case 'discord':
        name = 'Verify Discord';
        break;
      case 'twitter':
        name = 'Verify Twitter';
        break;
      case 'farcaster':
        name = 'Verify Farcaster';
        break;
      case 'wallet':
      case 'solana':
        name = 'Verify Wallet';
        break;
      case 'world-id':
        name = 'Verify World ID';
        break;
    }
    cloned.push({
      name: name,
      type: 'verified',
    });
    const newRequirements = {
      ...requirementsState,
      [type]: cloned,
    };
    setRequirementsState(newRequirements);
    setTokenGate((prev) => ({
      ...prev,
      requirements: newRequirements,
    }));
  };

  const onDisableRequirement = (type: keyof TokenGateSettingRequirement) => {
    const newRequirements = {
      ...requirementsState,
      [type]: [],
    };

    setRequirementsState(newRequirements);
    setTokenGate((prev) => ({
      ...prev,
      requirements: newRequirements,
    }));
  };

  const onOpenConditionSelect = (type: keyof TokenGateSettingRequirement) => {
    setOpenConditionSelect(type);
  };

  const onCloseConditionSelect = () => {
    setOpenConditionSelect(null);
  };

  const onOpenConditionDrawer = (condition: AvailableCondition) => {
    setOpenConditionDrawer(condition);
    setOpenConditionSelect(null);
  };

  const onCloseConditionDrawer = () => {
    setOpenConditionDrawer(null);
    setCurrentCondition(null);
  };

  const onEditCondition = (
    index: number,
    requirementType: keyof TokenGateSettingRequirement,
    conditionType: NonNullable<
      TokenGateSettingRequirement[typeof requirementType]
    >[number]['type'],
  ) => {
    const condition = requirementsState[requirementType]?.[index];
    if (!condition) return;
    const availableCondition =
      availableRequirements[requirementType].conditions[
        conditionType as keyof (typeof availableRequirements)[typeof requirementType]['conditions']
      ];
    if (!availableCondition) return;
    setOpenConditionDrawer(availableCondition);
    setCurrentCondition({
      ...condition,
      index,
    });
  };

  const onRemoveCondition = (
    index: number,
    requirementType: keyof TokenGateSettingRequirement,
  ) => {
    const cloned = requirementsState[requirementType] || [];
    const newRequirements = {
      ...requirementsState,
      [requirementType]: cloned.filter((_, i) => i !== index),
    };

    setRequirementsState(newRequirements);
    setTokenGate((prev) => ({
      ...prev,
      requirements: newRequirements,
    }));
  };

  useEffect(() => {
    setRequirementsState(tokenGate?.requirements || {});
    setType(tokenGate?.type || 'all');
    if (tokenGate?.type === 'some') setCount(tokenGate?.count || 1);
    if (requirements) {
      const filteredRequirements = Object.entries(requirements).filter(
        ([_, acc]) => acc.length > 0,
      );

      setTokenGatePage({
        id: 'token-gate',
        name: 'Access Gate',
        type: StepType.AUTH,
        fields: filteredRequirements
          .map(([key]) => key)
          .map((field) => ({
            id: `token-gate-${field}`,
            fieldType: 'identity',
            label: '',
            fieldSpecs: {
              type: field,
              required: false,
              conditions:
                requirements[field as keyof TokenGateSettingRequirement],
            },
          })),
        defaultNextStep: '',
      } as StepSchema);
    }
  }, [tokenGate]);

  return (
    <TokenGateContext.Provider
      value={{
        availableRequirements,
        type,
        setType,
        onTypeChange,
        count,
        setCount,
        onCountChange,
        requirements: requirementsState,
        setRequirements: setRequirementsState,
        requirementsCount,
        onAddRequirement,
        onUpdateRequirement,
        onEnableRequirement,
        onDisableRequirement,
        openConditionSelect,
        onOpenConditionSelect,
        onCloseConditionSelect,
        openConditionDrawer,
        onOpenConditionDrawer,
        onCloseConditionDrawer,
        onEditCondition,
        onRemoveCondition,
        currentCondition,
        setCurrentCondition,
        tokenGatePage,
      }}
    >
      {children}
      <ConditionModal />
      {openConditionDrawer ? (
        <ConditionDrawer />
      ) : (
        <div className="w-full"></div>
      )}
    </TokenGateContext.Provider>
  );
};

export default TokenGateProvider;
