'use client';

import { IdentitySchema } from '@formo/types';
import { useParams } from 'next/navigation';
import { FC, useCallback, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Separator } from '~/components/ui/separator';
import { cn } from '~/lib/utils';
import { hexToRGBA } from '~/utils/hexToRGBA';
import { saveSessionStorage } from '~/utils/storage';

import { IdentityMapper, identityMapper } from '../IdentityMapper';

type IdentityFieldProps = {
  id: string;
  label: string;
  subText?: string;
  fieldSpecs: IdentitySchema['fieldSpecs'];
  theme?: Record<string, any>;
  classNames?: { font?: string };
  isBuilderMode?: boolean;
  hideLabel?: boolean;
};

const IdentityField: FC<IdentityFieldProps> = ({
  id,
  label,
  subText,
  fieldSpecs,
  theme = {},
  classNames: { font } = {},
  isBuilderMode = false,
  hideLabel = false,
  ...props
}) => {
  const { formId } = useParams<{ formId: string }>();
  const { control, getValues } = useFormContext();
  const { changeQuestion } = useFormBuilder();
  const [isCustomLabelFocused, setIsCustomLabelFocused] = useState(false);
  const labelRef = useRef<HTMLSpanElement>(null);

  const additionalValidations = identityMapper[fieldSpecs?.type]?.({
    fieldSpecs,
  })?.validation;

  const handleCustomLabelFocused = useCallback(() => {
    setIsCustomLabelFocused(true);
  }, [changeQuestion, id]);

  const handleCustomLabelBlurred = useCallback(
    (e: React.FocusEvent<HTMLSpanElement>) => {
      const newLabel = e.currentTarget.textContent;
      if (!newLabel) {
        changeQuestion(id, 'Enter your question');
      } else {
        changeQuestion(id, newLabel);
      }
      setIsCustomLabelFocused(false);
    },
    [changeQuestion, id],
  );

  const renderAsterisk = () =>
    fieldSpecs?.required && (
      <span className={cn('px-1 text-lg text-red-500', font)}>*</span>
    );

  const renderLabel = (isBuilder: boolean) => (
    <div
      className="h-max w-full max-w-full"
      style={
        isCustomLabelFocused && isBuilder
          ? {
              backgroundImage: theme?.primary
                ? `linear-gradient(0deg, ${hexToRGBA(theme.primary, 0.03)}, ${hexToRGBA(
                    theme.primary,
                    0.03,
                  )}),linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05))`
                : 'linear-gradient(0deg, rgba(134, 219, 46, 0.03), rgba(134, 219, 46, 0.03)),linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05))',
              cursor: 'pointer',
            }
          : { cursor: 'auto' }
      }
    >
      <div>
        <span
          ref={labelRef}
          contentEditable={isBuilder}
          className={cn(
            'w-max whitespace-pre-wrap rounded-none border-0 px-0.5 text-lg font-medium leading-7 placeholder-gray-400 outline-none focus-visible:ring-0 focus-visible:ring-offset-0',
            font,
          )}
          data-testid="custom-label-input"
          tabIndex={1}
          autoFocus
          onFocus={handleCustomLabelFocused}
          onBlur={handleCustomLabelBlurred}
        >
          {label}
        </span>
        <span>{renderAsterisk()}</span>
      </div>
      {isCustomLabelFocused && isBuilder ? (
        <Separator
          className="h-[2px] w-full"
          style={{
            backgroundColor: theme?.primary ?? 'rgba(134, 219, 46, 0.8)',
          }}
        />
      ) : (
        <div className="h-[2px]"></div>
      )}
    </div>
  );
  return (
    <FormField
      control={control}
      name={id}
      rules={{
        ...additionalValidations,
      }}
      render={({ field }) => {
        return (
          <FormItem>
            {!hideLabel && (
              <FormLabel
                htmlFor={id}
                className="flex w-full items-center"
                style={{
                  cursor: isBuilderMode ? 'pointer' : 'auto',
                  color: theme?.questions ?? '#000000',
                  wordBreak: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                {renderLabel(isBuilderMode)}
              </FormLabel>
            )}
            {subText && (
              <FormDescription
                style={{
                  color: theme?.questions
                    ? hexToRGBA(theme.questions, 0.7)
                    : 'rgba(0, 0, 0, 0.3)',
                }}
                className={cn('text-base', font)}
              >
                {subText}
              </FormDescription>
            )}
            <FormControl>
              <IdentityMapper
                id={id}
                type={fieldSpecs?.type}
                controlledProps={field}
                fieldSpecs={fieldSpecs}
                beforeRedirect={() => {
                  const id = formId.split('-').pop() as string;
                  saveSessionStorage(id, getValues());
                }}
                className="mt-1.5"
                {...theme}
                {...props}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

export default IdentityField;
