'use client';

import { TextSchema } from '@formo/types';
import DOMPurify from 'dompurify';
import Quill from 'quill';
import Theme from 'quill';
import 'quill/dist/quill.snow.css';
import React, { useEffect, useRef, useState } from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import {
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { DisplayTextType } from '~/constants/fields';
import { cn } from '~/lib/utils';
import { hexToRGBA } from '~/utils/hexToRGBA';

interface SnowThemeWithExtendToolbar extends Theme {
  extendToolbar(toolbar: any): void;
}

// Import the Snow theme and assert its type
const SnowTheme = Quill.import('themes/snow') as unknown as new (
  quill: any,
  options: any,
) => SnowThemeWithExtendToolbar;

const currentURL = `${window.location.protocol}//${window.location.host}`;

class SnowThemeFix extends SnowTheme {
  tooltip: any;

  constructor(quill: any, options: any) {
    super(quill, options);
  }

  extendToolbar(toolbar: any) {
    super.extendToolbar(toolbar); // Call the original method

    // Access the tooltip and set the dataset link
    if (this.tooltip && this.tooltip.textbox) {
      this.tooltip.textbox.dataset.link = currentURL;
    }
  }
}

// Register the customized Snow theme
Quill.register('themes/snow', SnowThemeFix, true);

const DEFAULT_SUBTEXT = 'Click to add subtext';
const DEFAULT_HEADING_TEXT = 'Heading text here';
const DEFAULT_TEXT = 'Enter your text here';

type FormFieldTextProps = {
  id: string;
  label: string;
  subText?: string;
  fieldSpecs: TextSchema['fieldSpecs'];
  theme?: Record<string, any>;
  classNames?: { font?: string };
  isBuilderMode?: boolean;
  isSelected?: boolean;
};

const FormFieldText: React.FC<FormFieldTextProps> = ({
  id,
  label,
  subText,
  fieldSpecs,
  theme = {},
  isBuilderMode = false,
  classNames: { font } = {},
}) => {
  const { changeQuestion, changeSubText, selectedElement } = useFormBuilder();
  const [isEditing, setIsEditing] = useState(false);
  const isParagraph = fieldSpecs?.type === DisplayTextType.Paragraph;
  const isHeading = fieldSpecs?.type === DisplayTextType.Heading;
  const [htmlContent, setHtmlContent] = useState<string | null>(null);
  const [selectedText, setSelectedText] = useState<string | null>(null);

  const isSelected = id === selectedElement?.id;

  useEffect(() => {
    if (isEditing) {
      quillInstanceRef.current?.root.focus();
    }
  }, [isEditing]);

  const quillRef = useRef<HTMLDivElement | null>(null);
  const quillInstanceRef = useRef<Quill | null>(null);

  const icons: { bold: string; italic: string; link: string } = Quill.import(
    'ui/icons',
  ) as { bold: string; italic: string; link: string };

  icons.bold = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.66602 1.29041C3.2518 1.29041 2.91602 1.62619 2.91602 2.04041V7.78389V7.78416V13.5276C2.91602 13.9419 3.2518 14.2776 3.66602 14.2776H8.74121C10.772 14.2776 12.4618 12.6798 12.4618 10.6558C12.4618 9.17755 11.5604 7.92662 10.2832 7.35948C10.8883 6.7184 11.2606 5.86228 11.2606 4.91228C11.2606 2.88827 9.57068 1.29041 7.53993 1.29041H3.66602ZM4.41602 8.53416H7.53993C7.55494 8.53416 7.56993 8.53407 7.5849 8.53389H8.74121C9.99173 8.53389 10.9618 9.5076 10.9618 10.6558C10.9618 11.8039 9.99173 12.7776 8.74121 12.7776H4.41602V8.53416ZM7.57539 7.03389C8.80875 7.0157 9.76057 6.04957 9.76057 4.91228C9.76057 3.7641 8.79046 2.79041 7.53993 2.79041H4.41602V7.03389H7.57539Z" fill="#47474C"/>
</svg>`;

  icons.italic = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.0181 1.64913H12.6673C13.0815 1.64913 13.4173 1.98492 13.4173 2.39913C13.4173 2.81334 13.0815 3.14913 12.6673 3.14913H10.5292L7.06493 12.851H9.33398C9.7482 12.851 10.084 13.1867 10.084 13.601C10.084 14.0152 9.7482 14.351 9.33398 14.351H6.02691C6.00946 14.3516 5.99193 14.3516 5.97435 14.351H3.33398C2.91977 14.351 2.58398 14.0152 2.58398 13.601C2.58398 13.1867 2.91977 12.851 3.33398 12.851H5.47217L8.93646 3.14913H6.66732C6.2531 3.14913 5.91732 2.81334 5.91732 2.39913C5.91732 1.98492 6.2531 1.64913 6.66732 1.64913H9.98323C9.99483 1.64886 10.0065 1.64886 10.0181 1.64913Z" fill="#47474C"/>
</svg>`;

  icons.link = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.79561 5.15704C5.43689 4.94993 5.31398 4.49124 5.52109 4.13252L6.22447 2.91422C6.7839 1.94526 7.68841 1.21958 8.74834 0.904364C9.81011 0.588603 10.9426 0.711181 11.8915 1.25906C12.8404 1.80693 13.5128 2.72637 13.7703 3.80377C14.0272 4.8793 13.851 6.02547 13.2916 6.99443L12.5882 8.21273C12.3811 8.57145 11.9224 8.69435 11.5637 8.48725C11.205 8.28014 11.0821 7.82145 11.2892 7.46273L11.9926 6.24443C12.3659 5.59782 12.4765 4.84373 12.3113 4.15236C12.1466 3.46284 11.7223 2.89345 11.1415 2.55809C10.5607 2.22274 9.85543 2.14005 9.17592 2.34213C8.49457 2.54476 7.89683 3.01761 7.52351 3.66422L6.82012 4.88252C6.61302 5.24124 6.15433 5.36414 5.79561 5.15704ZM4.00665 9.75549C3.63333 10.4021 3.5227 11.1562 3.68789 11.8476C3.85264 12.5371 4.27686 13.1065 4.85771 13.4418C5.43856 13.7772 6.14378 13.8599 6.82329 13.6578C7.50464 13.4552 8.10238 12.9823 8.4757 12.3357L9.17908 11.1174C9.38619 10.7587 9.84488 10.6358 10.2036 10.8429C10.5623 11.05 10.6852 11.5087 10.4781 11.8674L9.77474 13.0857C9.21531 14.0547 8.3108 14.7803 7.25087 15.0956C6.1891 15.4113 5.05666 15.2887 4.10771 14.7409C3.15876 14.193 2.48638 13.2736 2.22896 12.1962C1.97198 11.1206 2.14818 9.97445 2.70761 9.00549L3.411 7.78719C3.6181 7.42847 4.07679 7.30557 4.43551 7.51267C4.79423 7.71978 4.91714 8.17847 4.71003 8.53719L4.00665 9.75549ZM5.94312 10.0619C5.73602 10.4206 5.85892 10.8793 6.21764 11.0864C6.57636 11.2935 7.03506 11.1706 7.24216 10.8119L10.0557 5.9387C10.2628 5.57998 10.1399 5.12129 9.78118 4.91419C9.42247 4.70708 8.96377 4.82998 8.75667 5.1887L5.94312 10.0619Z" fill="#47474C"/>
</svg>`;

  // Add fonts to whitelist
  const Font = Quill.import('formats/font') as unknown as any;
  // We do not add Aref Ruqaa since it is the default
  Font.whitelist = [
    'open-sans',
    'roboto',
    'poppins',
    'urbanist',
    'dm-sans',
    'outfit',
    'inter',
    'public-sans',
    'sora',
    'lato',
    'brawler',
    'montserrat',
  ];
  Quill.register(Font, true);

  useEffect(() => {
    if (quillRef.current && !quillInstanceRef.current) {
      quillInstanceRef.current = new Quill(quillRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [['bold', 'italic', 'link']],
        },
      });

      quillInstanceRef.current.on('text-change', () => {
        const editorContent = quillInstanceRef.current?.root.innerHTML;
        const sanitizedContent = DOMPurify.sanitize(
          editorContent || DEFAULT_TEXT,
          { FORBID_ATTR: ['style'] },
        );
        changeQuestion(id, sanitizedContent);
        setHtmlContent(sanitizedContent);
      });

      if (isEditing) {
        quillInstanceRef.current?.root.focus();
      }

      quillInstanceRef.current.on('selection-change', (range) => {
        if (!range || range.length === 0) {
          // No selection, remove all highlights
          quillInstanceRef.current?.formatText(
            0,
            quillInstanceRef.current?.getLength(),
            { background: false },
          );
          setSelectedText(null);
        } else {
          // Selection exists
          const text =
            quillInstanceRef.current?.getText(range.index, range.length) ??
            null;

          // Clear previous highlights
          quillInstanceRef.current?.formatText(
            0,
            quillInstanceRef.current?.getLength(),
            { background: false },
          );

          // Highlight the selected word
          quillInstanceRef.current?.formatText(range.index, range.length, {
            background: 'none',
          });

          setSelectedText(text);
        }
      });

      // Add double-click listener to capture the word clicked
      quillRef.current.addEventListener('dblclick', () => {
        const selection = quillInstanceRef.current?.getSelection();
        if (selection) {
          const text = quillInstanceRef.current?.getText(0) || ''; // Get the entire text
          const clickedIndex = selection.index; // Index of where user clicked

          // Get the entire word based on the clicked index
          const word = getWordAt(text || '', clickedIndex);

          const start = findWordStart(text, clickedIndex);
          const end = findWordEnd(text, clickedIndex);

          setSelectedText(word);
          quillInstanceRef.current?.setSelection(start, end - start);
        } else {
          setSelectedText(null);
        }
      });
    }
  }, [id, label, changeQuestion, isEditing, isSelected]);

  // Helper function to get the word at the given index
  const getWordAt = (str: string, pos: number) => {
    const start = findWordStart(str, pos);
    const end = findWordEnd(str, pos);
    return str.slice(start, end).trim();
  };

  // Find the start of the word (i.e., the last space or newline before the click)
  const findWordStart = (str: string, pos: number) => {
    return str.slice(0, pos).search(/\S+$/);
  };

  // Find the end of the word (i.e., the first space or newline after the click)
  const findWordEnd = (str: string, pos: number) => {
    const right = str.slice(pos).search(/\s/);
    return right !== -1 ? pos + right : str.length;
  };

  const handleLabelChange = (e: React.FocusEvent<HTMLSpanElement>) => {
    const text = e.currentTarget.textContent;

    if (!text) {
      e.currentTarget.textContent = isHeading
        ? DEFAULT_HEADING_TEXT
        : DEFAULT_TEXT;
      changeQuestion(id, isHeading ? DEFAULT_HEADING_TEXT : DEFAULT_TEXT);
    } else {
      const sanitizedText = DOMPurify.sanitize(text);
      changeQuestion(id, sanitizedText);
    }
  };

  const handleSubtextChange = (e: React.FocusEvent<HTMLSpanElement>) => {
    const newSubtext = e.currentTarget.textContent;
    if (typeof newSubtext === 'string') {
      const sanitizedSubtext = DOMPurify.sanitize(newSubtext);
      changeSubText(id, sanitizedSubtext);
    }
  };

  //Links within rich text editor should be in primary color
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      .ql-editor a, .rich-text-link a {
        color: ${theme?.primary || 'rgba(197,229,103,1)'} !important;
        text-decoration: underline;
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [theme?.primary]);

  const renderDescription = () => {
    // check subText => Yes: show => No: Check isSelected => Yes: show => No: Don't show
    if (!isHeading) return null;

    const isDefaultSubText = subText && subText !== DEFAULT_SUBTEXT;
    const isEmptySubText = !subText || !isDefaultSubText;

    if (isEmptySubText && !isSelected) return null;

    return (
      <FormDescription className={cn('min-h-8')}>
        <span
          contentEditable={isBuilderMode}
          autoFocus
          onBlur={handleSubtextChange}
          className={cn(
            'h-8 w-full appearance-none whitespace-pre-wrap rounded-none border-0 bg-transparent p-0 pb-[1px] text-lg placeholder-gray-400 outline-none focus-visible:ring-0 focus-visible:ring-offset-0',
            font,
          )}
          style={{
            color: theme?.questions
              ? subText
                ? hexToRGBA(theme.questions, 0.7)
                : hexToRGBA(theme.questions, 0.5)
              : 'rgba(0, 0, 0, 0.3)',
            cursor: isBuilderMode ? 'pointer' : 'text',
          }}
          data-testid="custom-label-input"
        >
          {isDefaultSubText
            ? DOMPurify.sanitize(subText)
            : selectedElement?.id === id && DEFAULT_SUBTEXT}
        </span>
      </FormDescription>
    );
  };

  return (
    <FormField
      name={id}
      defaultValue={fieldSpecs?.defaultValue}
      render={({ field }) => {
        return (
          <FormItem {...field}>
            <>
              <FormLabel
                htmlFor={id}
                className={cn(
                  isHeading ? 'min-h-9' : 'min-h-7',
                  'relative',
                  selectedText ? '' : 'quill-toolbar-hidden',
                )}
                style={{
                  fontFamily: `var(--${font})`,
                }}
              >
                {isBuilderMode && isParagraph ? (
                  <div
                    ref={quillRef}
                    className={cn(
                      'h-full w-full whitespace-pre-wrap rounded-none border-0 bg-transparent !p-0 pt-[2px] placeholder-gray-400 outline-none focus-visible:ring-0 focus-visible:ring-offset-0',
                      isHeading
                        ? 'h-9 !min-h-9 !text-[28px] !font-medium leading-9 text-black'
                        : '!min-h-0 !text-lg !font-normal text-gray-900',
                    )}
                    style={{
                      fontFamily: `var(--${font})`,
                      color: theme?.questions ? theme.questions : '#000000',
                    }}
                  >
                    {/* Render HTML content */}
                    <div
                      onBlur={(e) => isBuilderMode && handleLabelChange(e)}
                      onClick={() => isBuilderMode && setIsEditing(true)}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          htmlContent || label || DEFAULT_TEXT,
                          { FORBID_ATTR: ['style'] },
                        ),
                      }}
                      style={{
                        cursor: 'text',
                        color: 'inherit',
                        fontSize: 'inherit',
                        fontWeight: 'inherit',
                        fontFamily: `var(--${font})`,
                      }}
                    />
                  </div>
                ) : isHeading ? (
                  <div className={cn(isHeading ? 'min-h-9' : 'min-h-7')}>
                    <span
                      contentEditable={isBuilderMode}
                      onBlur={(e) => isBuilderMode && handleLabelChange(e)}
                      className={cn(
                        'h-full w-full whitespace-pre-wrap rounded-none border-0 bg-transparent p-0 pt-[2px] font-medium placeholder-gray-400 outline-none focus-visible:ring-0 focus-visible:ring-offset-0',
                        isHeading
                          ? 'h-9 !min-h-9 text-[28px] font-medium leading-9 text-black'
                          : '!min-h-0 text-lg font-normal text-gray-900',
                        font,
                      )}
                      style={{
                        cursor: isBuilderMode ? 'pointer' : 'text',
                        color: theme?.questions ? theme.questions : '#000000',
                      }}
                    >
                      {label}
                    </span>
                  </div>
                ) : (
                  !isBuilderMode &&
                  isParagraph && (
                    <div
                      className={cn(
                        'rich-text-link h-full w-full whitespace-pre-wrap rounded-none border-0 bg-transparent !p-0 pt-[2px] placeholder-gray-400 outline-none focus-visible:ring-0 focus-visible:ring-offset-0',
                        isHeading
                          ? 'h-9 !min-h-9 !text-[28px] !font-medium leading-9 text-black'
                          : '!min-h-0 !text-lg !font-normal text-gray-900',
                      )}
                      style={{
                        fontFamily: `var(--${font})`,
                        color: theme?.questions ? theme.questions : '#000000',
                      }}
                    >
                      {/* Render HTML content */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            htmlContent || label || DEFAULT_TEXT,
                            { FORBID_ATTR: ['style'] },
                          ),
                        }}
                        style={{
                          cursor: 'text',
                          color: 'inherit',
                          fontSize: 'inherit',
                          fontWeight: 'inherit',
                          fontFamily: `var(--${font})`,
                        }}
                      />
                    </div>
                  )
                )}
              </FormLabel>
            </>
            {renderDescription()}
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

export default FormFieldText;
