'use client';

import {
  GetNftByAddressResponse,
  GetTokenByAddressResponse,
  GetTokensResponse,
  chainIdToNativeTokenMapper,
} from '@formo/shared';
import {
  ObjectSnakeCaseToCamelCase,
  Token as TokenType,
  WalletCondition,
} from '@formo/types';
import { useQuery } from '@tanstack/react-query';
import { ChevronDown, SearchIcon, X } from 'lucide-react';
import { UIEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  FieldErrors,
  UseFormReturn,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { Spinner } from '~/app/_components/common';
import useDebounce from '~/app/hooks/useDebounce';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '~/components/ui/select';
import { ChainId, chains, orderedChains } from '~/constants/token-gate';
import client from '~/lib/client';
import { cn } from '~/lib/utils';

import { TokenConditionSchema } from './TokenConditionForm';
import TokenSelectItem from './TokenSelectItem';

type TokenSelectProps = {
  setValue: UseFormSetValue<TokenConditionSchema>;
  values: Pick<TokenConditionSchema, 'chainId' | 'address'>;
  errors?: FieldErrors<TokenConditionSchema>;
  trigger: UseFormTrigger<TokenConditionSchema>;
  selectedToken: Token | null;
  setSelectedToken: React.Dispatch<React.SetStateAction<Token | null>>;
  label: string;
  type: Exclude<WalletCondition['type'], 'verified'>;
  dirtyFields: UseFormReturn<TokenConditionSchema>['formState']['dirtyFields'];
};

export type NFT = Token;

export type Token = ObjectSnakeCaseToCamelCase<
  Pick<
    TokenType,
    'symbol' | 'address' | 'name' | 'chain_id' | 'logo_uri' | 'decimals'
  >
>;

const TokenSelect: React.FC<TokenSelectProps> = ({
  setValue,
  values,
  errors,
  trigger,
  selectedToken,
  setSelectedToken,
  label,
  type,
  dirtyFields,
}) => {
  const { chainId, address } = values;

  const selectedChain = chains[chainId];

  const [page, setPage] = useState(1);
  const [textValue, setTextValue] = useState(address || '');
  const [query, setQuery] = useState(textValue); // Use address as the initial query if it exists
  // The fetched tokens from the search
  const [fetchedTokens, setFetchedTokens] = useState<Map<number, NFT[]>>(
    new Map(),
  );
  const done = useRef(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isNativeToken = type === 'native';

  // FOR SEARCHING BY NAME TOKENS
  const { data: tokens, isFetching: isFetchingTokens } =
    useQuery<GetTokensResponse>({
      queryKey: ['tokens', chainId, page, query],
      queryFn: async () =>
        (
          await client.get('/api/tokens', {
            params: {
              chainId,
              page,
              size: 10,
              searchBy: 'name',
              searchQuery: query,
            },
          })
        ).data,
      refetchOnWindowFocus: false,
      // The data is static and only updates when the user changes the chain
      staleTime: Infinity,
      // Only fetch the tokens when the chain is selected and the type is token
      enabled: !!selectedChain && type === 'erc20' && !query.startsWith('0x'),
    });

  // FOR SEARCHING BY ADDRESS NFT
  const { data: nft, isFetching: isFetchingNFT } =
    useQuery<GetNftByAddressResponse>({
      queryKey: ['nftByAddress', chainId, query],
      queryFn: async () =>
        (
          await client.get(`/api/tokens/nft/${query}`, {
            params: { chainId },
          })
        ).data,
      refetchOnWindowFocus: false,
      // The data is static and only updates when the user changes the chain
      staleTime: Infinity,
      // Only fetch the NFT when the chain is selected and the type is nft
      enabled: !!selectedChain && type === 'nft' && query.startsWith('0x'),
      retry: false,
    });

  // FOR SEARCHING BY ADDRESS TOKEN
  const { data: token, isFetching: isFetchingToken } =
    useQuery<GetTokenByAddressResponse>({
      queryKey: ['tokenByAddress', chainId, query],
      queryFn: async () =>
        (
          await client.get(`/api/tokens/${query}`, {
            params: { chainId, address: query },
          })
        ).data,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!selectedChain && type === 'erc20' && query.startsWith('0x'),
      retry: false,
    });

  const isFetching = isFetchingTokens || isFetchingNFT || isFetchingToken;

  const onSearch = useDebounce((search: string) => {
    done.current = false;
    setQuery(search.trim());
    if (type === 'erc20' && !search.startsWith('0x')) {
      // Reset the page when the user searches
      setPage(1);
      setFetchedTokens(new Map());
    }
  }, 500);

  const onSelect = useCallback(
    (token: Token) => {
      setValue('address', token.address);
      if (!dirtyFields.name) {
        setValue('name', `Owns ${token.symbol}`);
      }
      setSelectedToken(token);
      trigger('address');
    },
    [dirtyFields.name],
  );

  const onFetchMore = useCallback(
    (e: UIEvent) => {
      const target = e.target as HTMLDivElement;
      if (isFetching || done.current) return;

      // Load more tokens when the user reaches 80% of the scroll
      if (target.scrollHeight * 0.8 <= target.scrollTop + target.clientHeight) {
        setPage((prev) => prev + 1);
      }
    },
    [isFetching],
  );

  const onChainChange = useCallback((v: string) => {
    setValue('chainId', Number(v) as ChainId);
    setValue('address', '');
    if (isNativeToken) {
      setValue('name', `Owns ${chainIdToNativeTokenMapper[Number(v)]}`);
    }
    trigger(['chainId', 'address']);
    // Reset the search when the chain changes
    setFetchedTokens(new Map());
    setPage(1);
    setQuery('');
    setTextValue('');
    setSelectedToken(null);
    done.current = false;
  }, []);

  const onClearSelectedToken = useCallback(() => {
    setSelectedToken(null);
    setValue('address', '');
    trigger('address');
    setQuery('');
    setTextValue('');
  }, []);

  // Handle infinite scroll for fetching tokens
  useEffect(() => {
    if (done.current || !tokens) return;
    // If there are no tokens, we're done
    if (tokens.length === 0) {
      done.current = true;
      return;
    }
    // Select the token if it matches the address
    if (
      !isNativeToken &&
      tokens.length === 1 &&
      tokens[0]?.address === address
    ) {
      setSelectedToken(tokens[0] as Token);
    }
    // Add the tokens to the fetched tokens
    setFetchedTokens((prev) => {
      // Prevent duplicates
      if (prev.has(page)) return prev;
      const newData = new Map(prev);
      newData.set(page, tokens);
      return newData;
    });
  }, [tokens, page]);

  const handleSearchByAddress = useCallback(
    (token: Token) => {
      setValue('address', token.address);
      if (!dirtyFields.name) {
        setValue('name', `Owns ${token.symbol}`);
      }
      setSelectedToken(token);
      trigger('address');
      inputRef.current?.blur();
    },
    [dirtyFields],
  );

  // Select the token if it matches the address
  useEffect(() => {
    if (nft && nft.address === query) {
      handleSearchByAddress(nft);
    }
  }, [nft, query, handleSearchByAddress]);

  // Select the token if it matches the address
  useEffect(() => {
    if (token && token.address === query) {
      handleSearchByAddress(token);
    }
  }, [token, query, handleSearchByAddress]);

  return (
    <div className="flex flex-col">
      <Label className="text-base font-medium text-black">
        {label}
        <span className="px-1 text-base text-red-500">*</span>
      </Label>
      <div className="mt-2.5 flex gap-1.5">
        <Select
          value={chainId?.toString()}
          onValueChange={onChainChange}
          name="chainId"
        >
          <SelectTrigger
            type="button"
            className={cn(
              isNativeToken && '!w-full',
              'w-max min-w-0 flex-shrink gap-2.5 font-medium rounded-lg border border-gray-200 bg-white/40 px-2.5',
            )}
          >
            <img
              src={selectedChain?.icon.src}
              alt={selectedChain?.icon.alt}
              className="h-6 w-6 rounded-md"
            />
            {isNativeToken && (
              <div className="justify-start text-left w-full">
                {selectedChain?.name}
              </div>
            )}
          </SelectTrigger>
          <SelectContent className="px-1">
            {Object.values(orderedChains).map((chain) => (
              <SelectItem
                key={chain.id}
                value={chain.id.toString()}
                className="min-w-72 p-2"
              >
                <div className="flex items-center gap-2.5">
                  <img
                    src={chain.icon.src}
                    alt={chain.icon.alt}
                    className="h-6 w-6"
                  />
                  <span className="text-base font-medium text-black">
                    {chain.name}
                  </span>
                </div>
              </SelectItem>
            ))}
            <SelectItem value="_" disabled className="p-2">
              More chains coming soon
            </SelectItem>
          </SelectContent>
        </Select>
        {!isNativeToken && (
          <div className="group relative h-10 w-full flex-1 flex-shrink-0 items-center rounded-md border border-gray-200">
            {!selectedToken && (
              <SearchIcon
                size={22}
                className="absolute left-2.5 top-1/2 -translate-y-1/2 text-gray-600"
              />
            )}
            <Input
              placeholder={
                type === 'erc20' && chainId === 1
                  ? 'Search or paste address'
                  : 'Paste the address'
              }
              className={cn(
                'h-full rounded-md border-none pl-10 placeholder:text-base placeholder:text-gray-600 pr-7.5',
                selectedToken && 'pointer-events-none',
              )}
              disabled={!selectedChain}
              value={textValue}
              onChange={(e) => {
                const value = e.target.value;
                onSearch(value);
                setTextValue(value);
              }}
              name="address"
              autoComplete="off"
              ref={inputRef}
            />
            <div className="absolute right-2.5 top-1/2 z-10 flex -translate-y-1/2 gap-1.5 text-gray-700">
              {selectedToken && (
                <X
                  className="cursor-pointer"
                  size={16}
                  onClick={onClearSelectedToken}
                />
              )}
              {/* NFT does not support search */}
              {type !== 'nft' && !selectedToken && (
                <ChevronDown size={16} className="cursor-pointer" />
              )}
            </div>
            {selectedToken && (
              <div className="pointer-events-none absolute left-0 top-0 flex h-full w-full max-w-[calc(100%-30px)] cursor-pointer items-center gap-2.5 overflow-clip rounded-[inherit] bg-white pl-2.5">
                {selectedToken.logoUri && (
                  <img
                    src={selectedToken.logoUri}
                    alt={selectedToken.name}
                    className="h-[26px] w-[26px] rounded-full object-contain"
                  />
                )}
                <div className="flex items-end gap-2 w-full overflow-clip">
                  <span className="text-base font-medium leading-none text-black overflow-ellipsis max-w-full overflow-clip text-nowrap">
                    {selectedToken.name || selectedToken.address}
                  </span>
                  {selectedToken.symbol && (
                    <span className="pb-[0.5px] text-sm font-medium leading-none text-gray-700 overflow-clip overflow-ellipsis text-nowrap">
                      {selectedToken.symbol}
                    </span>
                  )}
                </div>
              </div>
            )}
            <div className="pointer-events-none absolute left-0 top-[calc(100%_+_8px)] w-full scale-95 overflow-clip rounded-md bg-white p-1.5 opacity-0 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.1),0px_-1px_2px_0px_rgba(0,0,0,0.05)] transition-all group-focus-within:pointer-events-auto group-focus-within:scale-100 group-focus-within:opacity-100">
              <div
                className="h-full max-h-72 overflow-auto"
                onScroll={onFetchMore}
              >
                {type === 'erc20' ? (
                  <>
                    {selectedToken ? (
                      <TokenSelectItem token={selectedToken} />
                    ) : fetchedTokens?.size > 0 ? (
                      Array.from(fetchedTokens.values()).flatMap((tokens) =>
                        tokens.map((token) => (
                          <TokenSelectItem
                            key={token.address}
                            token={token}
                            onSelect={onSelect}
                          />
                        )),
                      )
                    ) : null}
                    {!isFetching && (!token || fetchedTokens.size === 0) ? (
                      <div className="bg-white text-base text-gray-700 text-center">
                        <p>
                          {query ? 'No results.' : 'Search or paste address'}
                        </p>
                        <p className="text-sm">
                          {fetchedTokens.size === 0 &&
                            'More tokens are available by pasting the address'}
                        </p>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    {nft ? (
                      <TokenSelectItem token={nft} onSelect={onSelect} />
                    ) : null}
                    {!isFetching && !nft ? (
                      <div className="flex h-[calc(64px-12px)] items-center justify-center bg-white text-base text-gray-700">
                        {query ? 'No results' : 'Paste the address'}
                      </div>
                    ) : null}
                  </>
                )}
                {isFetching && (
                  <div className="flex h-16 items-center justify-center bg-white text-base text-gray-700">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {errors?.chainId?.message || errors?.address?.message ? (
        <p className="mt-1 text-sm text-red-500">
          {errors.chainId?.message || errors.address?.message}
        </p>
      ) : null}
    </div>
  );
};

export default TokenSelect;
