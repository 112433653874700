'use client';

import { X } from 'lucide-react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import Telegram from '~/components/icons/Telegram';
import { cn } from '~/lib/utils';

export type Options = {
  bot_id: string;
  request_access?: boolean;
  lang?: string;
};

export type TelegramUserData = {
  auth_date: number;
  first_name: string;
  id: number;
  last_name: string;
  username: string;
  // I think there could be other properties too
};

type ConnectTelegramProps = {
  value?: TelegramUserData | null;
  onChange?: (user: TelegramUserData | null) => void;
  font?: string;
  beforeRedirect?: () => void;
};

const ConnectTelegram = forwardRef<HTMLButtonElement, ConnectTelegramProps>(
  ({ onChange, font, value }, ref) => {
    const { theme } = useFormBuilder();

    const [profile, setProfile] = useState<TelegramUserData | null>(
      value || null,
    );
    const isConnected = !!profile;
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const onConnectTelegram = () => {
      if (isConnected) return;
      // Open a new window with the Telegram login widget
      window.open(
        `${location.protocol}//${process.env.NEXT_PUBLIC_DEFAULT_APP_HOST}/callback/telegram?redirect=${encodeURIComponent(location.href)}`,
        '_blank',
        'width=600,height=600',
      );
    };

    const onDisconnectTelegram = () => {
      setProfile(null);
      onChange?.(null);
    };

    useEffect(() => {
      if (isConnected) {
        intervalRef.current && clearInterval(intervalRef.current);
        return;
      }

      intervalRef.current = setInterval(() => {
        const telegramUserCookie = document.cookie.match(
          /__formo_telegram_user=(.*?)(;|$)/,
        );
        if (!telegramUserCookie) return;
        const telegramUser = telegramUserCookie[1];
        if (!telegramUser) return;
        try {
          const user = JSON.parse(telegramUser) as TelegramUserData;
          setProfile(user);
          onChange?.(user);
          // Clear the cookie after 1 second
          document.cookie =
            '__formo_telegram_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.formo.so';
        } catch (error) {
          console.log('parsing telegram user error', error);
        }
      }, 1000);

      return () => {
        if (intervalRef.current) clearInterval(intervalRef.current);
      };
    }, [isConnected]);

    useEffect(() => {
      setProfile(value || null);
    }, [value]);

    return (
      <div className="mt-1.5">
        <button
          ref={ref}
          type="button"
          onClick={onConnectTelegram}
          className={cn(
            'flex h-12 justify-start gap-2.5 rounded-md border-1.5 border-[rgba(236,236,236,0.3)] bg-[rgba(51,176,230)] px-4 py-2 text-white transition-all hover:bg-[rgba(51,176,230,0.9)] items-center',
            {
              'w-max max-w-full cursor-default': isConnected,
            },
            theme?.radius ? theme?.radius : 'rounded-md',
          )}
        >
          <div className="flex aspect-square w-[30px] min-w-[30px] flex-shrink-0 items-center justify-center rounded-[10px] border border-white/30 bg-[rgba(56,187,244)]">
            <Telegram size={18} />
          </div>
          <>
            {isConnected && profile ? (
              <div className="flex h-[30px] w-full items-center gap-2 overflow-clip rounded-[10px] bg-white/20 px-2.5">
                <span
                  className={cn(
                    'line-clamp-1 max-w-full whitespace-normal text-wrap text-lg font-medium',
                    font,
                  )}
                >
                  @{profile.username}
                </span>
                <X
                  size={16}
                  className="ml-auto min-w-0 flex-shrink-0 cursor-pointer text-white opacity-30"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDisconnectTelegram();
                  }}
                />
              </div>
            ) : (
              <span className={cn('text-lg font-medium leading-none', font)}>
                Connect Telegram
              </span>
            )}
          </>
        </button>
      </div>
    );
  },
);

export default ConnectTelegram;
