'use client';

import { useCallback, useEffect, useRef } from 'react';

type AnyFunction = (...args: any[]) => any;

const useDebounce = <T extends AnyFunction = AnyFunction>(
  callback: T,
  delay = 300,
) => {
  const ref = useRef<NodeJS.Timeout | null>(null);

  type Params = Parameters<T>;

  const debounce = useCallback(
    (...args: Params) => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
      ref.current = setTimeout(() => callback(...args), delay);
    },
    [callback, delay],
  );

  useEffect(() => {
    const timeout = ref.current;
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return debounce;
};

export default useDebounce;
