'use client';

import { X } from 'lucide-react';
import useTokenGate from '~/app/hooks/useTokenGate';
import { cn } from '~/lib/utils';

const ConditionDrawer = () => {
  const { openConditionDrawer, onCloseConditionDrawer, currentCondition } =
    useTokenGate();
  const Form = openConditionDrawer?.form || (() => null);

  return (
    <>
      <div
        className={cn(
          'fixed -right-full bottom-0 top-0 z-10 h-full w-full ',
          openConditionDrawer && 'right-0',
        )}
        onClick={onCloseConditionDrawer}
      ></div>
      <div
        className={cn(
          'sticky top-0 z-20 h-full w-full max-w-[520px] translate-x-full border-l bg-white px-8 py-7 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.1),0px_-1px_2px_0px_rgba(0,0,0,0.05)] transition-transform duration-300',
          openConditionDrawer && 'translate-x-0',
        )}
      >
        {openConditionDrawer && (
          <div className="flex flex-col gap-8">
            <div className="flex justify-between">
              <h2 className="text-xl font-medium text-black">
                {currentCondition?.index ? 'Edit' : 'Add'}{' '}
                {openConditionDrawer.name} requirement
              </h2>
              <button onClick={onCloseConditionDrawer}>
                <X size={24} className="cursor-pointer text-gray-700" />
              </button>
            </div>
            <Form />
          </div>
        )}
      </div>
    </>
  );
};

export default ConditionDrawer;
