'use client';

import { InputCheckboxGroupSchema } from '@formo/types';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import { CheckboxProps, CheckedState } from '@radix-ui/react-checkbox';
import { isArray } from 'lodash';
import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Checkbox } from '~/components/ui/checkbox';
import { Input } from '~/components/ui/input';
import { FieldSpecs, otherOptionKey } from '~/constants/fields';
import { Option } from '~/constants/fields';
import { cn } from '~/lib/utils';

import { DragHandle } from '../../Builder/components/common/buttons/DragHandle';
import AddOptionButton from '../../common/AddOptionButton';
import TrashV2 from '../../common/TrashV2';

type CheckboxesProps = CheckboxProps & {
  id?: string;
  options: Option[];
  allowOther?: boolean;
  placeHolder?: string;
  preFetch?: () => Promise<Option[]>;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: Option[]) => void;
  classNames?: {
    font?: string;
  };
  className?: string;
  theme?: Record<string, any>;
  isBuilderMode?: boolean;
};

const Checkboxes = forwardRef<HTMLButtonElement, CheckboxesProps>(
  (
    {
      id,
      options: initialOptions = [],
      allowOther = false,
      preFetch,
      onChange,
      value,
      // defaultValue,
      theme = {},
      classNames: { font } = {},
      isBuilderMode = false,
      ...props
    },
    ref,
  ) => {
    const {
      selectedElement,
      selectedStep,
      updateFieldOptionsOrder,
      deleteFieldOption,
      updateFieldOptionLabel,
    } = useFormBuilder();
    const [internalSelectedOptions, setInternalSelectedOptions] = useState<
      Option[]
    >([]);
    const [hoveredOptionId, setHoveredOptionId] = useState<string | null>(null);
    const [otherValue, setOtherValue] = useState<string | undefined>();
    const [options, setOptions] = useState<Option[]>(initialOptions);
    const [isOther, setIsOther] = useState(() => {
      if (!allowOther) return false;
      if (typeof otherValue !== 'string') return false;
      return !options.some((option) => option.value === otherValue);
    });

    const isSelected = selectedElement?.id === id;
    const field = selectedStep.fields.find((field) => field.id === id);

    const fieldSpecs = field?.fieldSpecs as InputCheckboxGroupSchema;

    const onCheck = (value: string, check: CheckedState) => {
      const isOption = value === otherOptionKey;
      // Toggle the other option
      isOption && setIsOther(check as boolean);
      // Clear the other option data if toggle off
      isOption && !check && otherValue && setOtherValue('');

      const newInternalSelectedOptions = [...internalSelectedOptions];

      if (check) {
        newInternalSelectedOptions.push(
          isOption
            ? { label: '', value }
            : options.find((opt) => opt.value === value)!,
        );
      } else {
        newInternalSelectedOptions.splice(
          newInternalSelectedOptions.findIndex((opt) => opt.value === value),
          1,
        );
      }

      setInternalSelectedOptions(newInternalSelectedOptions);

      onChange && onChange(newInternalSelectedOptions);
    };

    const onOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
      setOtherValue(e.target.value);
    };

    // For controlled components
    useEffect(() => {
      if (typeof value !== 'object' || !isArray(value)) return;
      const parseValue = [...value] as Option[];
      const innerIsOther = value.some(
        (option) => option.value === otherOptionKey,
      );

      setIsOther(innerIsOther);
      setInternalSelectedOptions(parseValue);
      setOtherValue(
        parseValue.find((e) => e.value === otherOptionKey)?.label as string,
      );
    }, [value]);

    useEffect(() => {
      if (selectedStep?.fields) {
        if ((field?.fieldSpecs as FieldSpecs)?.options) {
          setOptions((field?.fieldSpecs as FieldSpecs).options);
        }
      }
    }, [selectedStep.fields]);

    useEffect(() => {
      if (!preFetch) return;

      const handleFetchOptions = async () => {
        try {
          const fetchedOptions = await preFetch();
          setOptions(fetchedOptions);
          setIsOther(
            !fetchedOptions.some((option) => option.value === otherValue),
          );
        } catch (error) {
          console.log('[ERROR] Fetching options', error);
          setOptions([
            {
              label: 'Error fetching options',
              value: 'error',
              disabled: true,
            },
          ]);
          setOtherValue('error');
        }
      };

      handleFetchOptions();
    }, [preFetch]);

    const handleFocus = (
      event: React.FocusEvent<HTMLButtonElement | HTMLInputElement>,
    ) => {
      event.target.style.border = theme?.primary
        ? `1.5px solid ${theme.primary}`
        : '1.5px solid rgba(134, 219, 46, 0.8)';
      event.target.style.boxShadow = theme?.primary
        ? `0px 0px 4px 0px  ${theme?.primary}`
        : '0px 0px 4px 0px rgba(134, 219, 46, 1)';
      event.target.style.backgroundColor = 'rgba(255, 255, 255, 1)';
    };

    const handleBlur = (
      event: React.FocusEvent<HTMLButtonElement | HTMLInputElement>,
    ) => {
      event.target.style.border = '1.5px solid rgba(0, 0, 0, 0.1)';
      event.target.style.boxShadow = 'none';

      // update other value by event.target.value
      const otherRecordIndex = internalSelectedOptions.findIndex(
        (option) => option.value === otherOptionKey,
      );
      if (otherRecordIndex > -1) {
        internalSelectedOptions[otherRecordIndex]!.label = event.target.value;
        setInternalSelectedOptions(internalSelectedOptions);
        onChange && onChange(internalSelectedOptions);
      }
    };

    const onDragEnd = (result: DropResult) => {
      if (!result.destination) return;

      const updatedOptions = Array.from(options) as Option[];
      const [movedOption] = updatedOptions.splice(result.source.index, 1);
      updatedOptions.splice(result.destination.index, 0, movedOption!);

      setOptions(updatedOptions);
      updateFieldOptionsOrder(selectedStep?.id, id!, updatedOptions);
    };

    const handleLabelChange = (optionId: string, newLabel: string) => {
      if (!newLabel) {
        updateFieldOptionLabel(selectedStep?.id, id as string, optionId, '   ');
      } else {
        updateFieldOptionLabel(
          selectedStep?.id,
          id as string,
          optionId,
          newLabel,
        );
      }
    };

    const handleDeleteOption = (optionId: string) => {
      deleteFieldOption(selectedStep?.id, id!, optionId);
      setOptions((prevOptions) =>
        prevOptions.filter((option) => option.value !== optionId),
      );
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLSpanElement>) => {
      e.preventDefault();

      // Get text from the clipboard
      const text = e.clipboardData.getData('text/plain');

      // Insert the plain text at the cursor position
      document.execCommand('insertText', false, text);
    };

    const displayOptions = allowOther
      ? [...options, { value: otherOptionKey, label: 'Other' }]
      : options;

    const renderMinSelectionMessage = () => {
      if (
        !fieldSpecs ||
        typeof fieldSpecs.minSelection !== 'number' ||
        !fieldSpecs.minSelection
      )
        return null;

      // Check if number of selected options less than minSelection
      if (
        internalSelectedOptions.length &&
        internalSelectedOptions.length < fieldSpecs.minSelection
      )
        return (
          <p className="text-sm text-red-500">
            {`Select at least ${fieldSpecs.minSelection} ${
              fieldSpecs.minSelection === 1 ? 'option' : 'options'
            }`}
          </p>
        );

      return null;
    };

    const renderMaxSelectionMessage = () => {
      if (
        !fieldSpecs ||
        typeof fieldSpecs.maxSelection !== 'number' ||
        !fieldSpecs.maxSelection
      )
        return null;

      // Check if number of selected options bigger than maxSelection
      if (internalSelectedOptions.length > fieldSpecs.maxSelection)
        return (
          <p className="text-sm text-red-500">
            {`Select no more than ${fieldSpecs.maxSelection} ${
              fieldSpecs.maxSelection === 1 ? 'option' : 'options'
            }`}
          </p>
        );

      return null;
    };

    return (
      <>
        {isBuilderMode && isSelected && (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-checkbox-options" type="group">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {displayOptions.map(
                      ({ label, value }, index) =>
                        value !== otherOptionKey && (
                          <Draggable
                            key={'draggable-checkbox-' + index}
                            draggableId={'draggable-checkbox-' + index}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="relative flex items-center"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                }}
                                onMouseEnter={() => setHoveredOptionId(value)}
                                onMouseLeave={() => setHoveredOptionId(null)}
                              >
                                {provided && isBuilderMode && isSelected && (
                                  <DragHandle
                                    {...provided.dragHandleProps}
                                    className="min-w-3"
                                    containerClassName="min-w-3 !inline-flex"
                                  />
                                )}
                                <div
                                  className={cn(
                                    'flex items-center py-2 pr-3 max-w-[calc(100%-32px)]',
                                    isSelected ? 'pl-2' : 'pl-0',
                                  )}
                                >
                                  <Checkbox
                                    ref={ref}
                                    {...props}
                                    checked={internalSelectedOptions.some(
                                      (e) => e.value === value,
                                    )}
                                    onCheckedChange={(checked) =>
                                      onCheck(value, checked)
                                    }
                                    value={value}
                                    className={cn(props.className, '!mt-0')}
                                    type="button"
                                    theme={theme}
                                  ></Checkbox>
                                  <span
                                    contentEditable={
                                      isBuilderMode && label !== 'Other'
                                    }
                                    suppressContentEditableWarning
                                    className="break-word text-lg leading-[22px] outline-none ml-2.5 mr-5.5"
                                    onBlur={(e) =>
                                      handleLabelChange(
                                        value,
                                        e.target.textContent || '',
                                      )
                                    }
                                    onPaste={handlePaste}
                                  >
                                    {label}
                                  </span>
                                </div>
                                <div className="min-w-5 min-h-5 ml-auto">
                                  {hoveredOptionId === value &&
                                    isSelected &&
                                    isBuilderMode &&
                                    displayOptions.length > 1 && (
                                      <TrashV2
                                        className="cursor-pointer w-full h-full"
                                        onClick={() =>
                                          handleDeleteOption(value)
                                        }
                                      />
                                    )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ),
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {/* Other checkbox */}
            {allowOther && (
              <>
                <div className="relative flex items-center">
                  {isBuilderMode && isSelected && <div className="w-3"></div>}

                  <div
                    className={cn(
                      'flex-1 flex items-center py-2 pr-3',
                      isSelected ? 'pl-2' : 'pl-0',
                    )}
                  >
                    <Checkbox
                      ref={ref}
                      {...props}
                      checked={internalSelectedOptions.some(
                        (e) => e.value === otherOptionKey,
                      )}
                      onCheckedChange={(checked) =>
                        onCheck(otherOptionKey, checked)
                      }
                      value={otherOptionKey}
                      className={cn(props.className, '!mt-0')}
                      type="button"
                      theme={theme}
                    ></Checkbox>
                    <span className="break-word text-lg leading-[22px] outline-none ml-2.5">
                      Other
                    </span>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {(!isSelected || !isBuilderMode) &&
          displayOptions.map(({ label, value }, index) => (
            <div className="relative flex items-center" key={index}>
              {isBuilderMode && isSelected && (
                <div className="w-3 !inline-flex"></div>
              )}
              <div
                className={cn(
                  'flex items-center py-2 pr-3',
                  isSelected ? 'pl-2' : 'pl-0',
                )}
              >
                <Checkbox
                  ref={ref}
                  {...props}
                  checked={internalSelectedOptions.some(
                    (e) => e.value === value,
                  )}
                  onCheckedChange={(checked) => onCheck(value, checked)}
                  value={value}
                  className={cn(props.className, '!mt-0')}
                  type="button"
                  theme={theme}
                ></Checkbox>
                <span
                  contentEditable={isBuilderMode && label !== 'Other'}
                  suppressContentEditableWarning
                  className="break-word text-lg leading-[22px] outline-none ml-2.5 mr-5.5"
                  onBlur={(e) =>
                    handleLabelChange(value, e.target.textContent || '')
                  }
                  onPaste={handlePaste}
                >
                  {label}
                </span>
              </div>
            </div>
          ))}
        {allowOther && isOther && (
          <Input
            className={cn(
              'py-[13.5px] text-lg shadow focus:ring-0 focus:ring-offset-0',
              props.className,
              font,
            )}
            style={{
              color: theme?.answers ?? '#000000',
            }}
            type="text"
            value={otherValue}
            onChange={onOtherChange}
            placeholder="Type your answer here"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}
        {renderMinSelectionMessage()}
        {renderMaxSelectionMessage()}

        {isSelected && isBuilderMode && (
          <AddOptionButton
            fieldId={id!}
            label="Add"
            className="mt-2"
            setOptions={setOptions}
            options={options}
            classNames={font ? { font } : undefined}
          />
        )}
      </>
    );
  },
);

export default Checkboxes;
