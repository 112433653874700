import { X } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { forwardRef, useEffect, useRef, useState } from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Twitter } from '~/components/icons';
import { Button } from '~/components/ui/button';
import { cn } from '~/lib/utils';
import nanoid from '~/utils/nanoid';

import { Loader } from '../../common';

export type TwitterUserValue = {
  id: string;
  name: string;
  username: string;
  profile_image_url: string;
  protected: boolean;
  url: string;
  public_metrics: {
    followers_count: number;
    following_count: number;
    tweet_count: number;
    listed_count: number;
  };
  created_at: string;
  verified: boolean;
  verified_type: string;
};

type ConnectTwitterProps = {
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: TwitterUserValue | null) => void;
  value?: TwitterUserValue;
  beforeRedirect?: () => void;
  font?: string;
};

const clientId = process.env.NEXT_PUBLIC_TWITTER_OAUTH_2_CLIENT_ID!;
// See: https://developer.twitter.com/en/docs/authentication/oauth-2-0/authorization-code
const scope = 'offline.access users.read tweet.read';

const ConnectTwitter = forwardRef<HTMLDivElement, ConnectTwitterProps>(
  ({ beforeRedirect, onChange, value, font, ...props }, ref) => {
    const { theme } = useFormBuilder();
    const hasCalled = useRef(false);
    const [profile, setProfile] = useState<TwitterUserValue | null>(
      value || null,
    );
    const [loading, setIsLoading] = useState(false);
    const router = useRouter();

    const isConnected = !!profile;

    const onConnectTwitter = () => {
      if (loading || isConnected) return;
      setIsLoading(true);
      const challenge = nanoid(20);
      const state = nanoid(20);
      const redirect = `${window.location.origin}/callback/twitter`;

      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append('response_type', 'code');
      urlSearchParams.append('client_id', clientId);
      urlSearchParams.append('redirect_uri', redirect);
      urlSearchParams.append('scope', scope);
      urlSearchParams.append('code_challenge', challenge);
      urlSearchParams.append('code_challenge_method', 'plain');
      urlSearchParams.append('state', state);

      sessionStorage.setItem('twitter_oauth_state', state);
      sessionStorage.setItem('twitter_oauth_challenge', challenge);
      sessionStorage.setItem('twitter_redirect', window.location.href);

      beforeRedirect?.();

      const url = `https://twitter.com/i/oauth2/authorize?${urlSearchParams.toString()}`;

      router.push(url);
    };

    useEffect(() => {
      if (hasCalled.current || isConnected) return;
      hasCalled.current = true;

      const accessToken = sessionStorage.getItem('twitter_access_token');
      if (!accessToken) return;

      const getProfile = async () => {
        try {
          setIsLoading(true);

          const url = '/api/oauth/twitter/me';
          const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({ access_token: accessToken }),
          });
          if (!response.ok) throw new Error();
          const data = (await response.json()) as TwitterUserValue;
          onChange?.(data);
          setProfile(data);
        } catch (error) {
          console.error(error);
          setProfile(null);
          onDisconnectTwitter();
        }
        setIsLoading(false);
      };

      getProfile();
    }, [isConnected]);

    const onDisconnectTwitter = () => {
      hasCalled.current = false;
      sessionStorage.removeItem('twitter_access_token');
      sessionStorage.removeItem('twitter_token_type');
      onChange?.(null);
      setProfile(null);
    };

    return (
      <div className="flex items-center gap-2" ref={ref} {...props}>
        <Button
          type="button"
          className={cn(
            'flex h-12 justify-start gap-2.5 rounded-md border-1.5 border-[rgba(236,236,236,0.3)] bg-black px-4 py-2 text-white transition-all hover:bg-black/90',
            {
              'w-32': loading,
              'w-fit': !loading,
              'w-max max-w-full cursor-default': isConnected,
            },
            theme?.radius ? theme?.radius : 'rounded-md',
          )}
          onClick={onConnectTwitter}
          disabled={loading}
        >
          <div className="flex aspect-square w-[30px] min-w-[30px] flex-shrink-0 items-center justify-center rounded-[10px] border border-white/20 bg-black">
            <Twitter size={18} />
          </div>
          {loading ? (
            <div className="flex h-[30px] flex-1 items-center justify-center rounded-[10px] bg-[rgb(66,69,77)] px-2.5">
              <Loader size={20} className="animate-spin" />
            </div>
          ) : (
            <>
              {isConnected && profile ? (
                <div className="flex h-[30px] w-full items-center gap-2 overflow-clip rounded-[10px] bg-[rgb(66,69,77)] px-2.5">
                  <span
                    className={cn(
                      'line-clamp-1 max-w-full whitespace-normal text-wrap text-lg font-medium',
                      font,
                    )}
                  >
                    @{profile.username}
                  </span>
                  <X
                    size={16}
                    className="ml-auto min-w-0 flex-shrink-0 cursor-pointer text-white opacity-30"
                    onClick={onDisconnectTwitter}
                  />
                </div>
              ) : (
                <span className={cn('text-lg font-medium leading-none', font)}>
                  Connect X
                </span>
              )}
            </>
          )}
        </Button>
      </div>
    );
  },
);

export default ConnectTwitter;
