'use client';

import { forwardRef, useEffect, useState } from 'react';
import { ChevronFilledDown, ChevronFilledUp } from '~/components/icons';
import { cn } from '~/lib/utils';

type InputNumberProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value' | 'type' | 'defaultValue'
> & {
  value?: number;
  onChange?: (value: number) => void;
  defaultValue?: number;
  classNames?: {
    root?: string;
    input?: string;
    button?: string;
    buttonContainer?: string;
  };
  min?: number;
  max?: number;
  showButtons?: boolean;
  step?: number;
  decimals?: number;
};

const buttonClassName =
  'text-gray-600 disabled:text-gray-300 flex items-center justify-center h-full disabled:opacity-50 disabled:cursor-not-allowed active:opacity-80 hover:opacity-90 transition-opacity';

const toFixedShorten = (value: number, decimals: number) => {
  const str = value.toFixed(decimals);
  // Remove trailing zeros
  return str.replace(/(\.0+|0+)$/, '');
};

const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      className,
      value,
      onChange,
      classNames,
      defaultValue,
      min = -Infinity,
      max = Infinity,
      showButtons = true,
      step = 1,
      decimals = 0,
      ...props
    },
    ref,
  ) => {
    const [internalValue, setInternalValue] = useState<string>(() => {
      if (value !== undefined) {
        return toFixedShorten(value, decimals);
      }
      if (defaultValue !== undefined) {
        return toFixedShorten(defaultValue, decimals);
      }
      if (Number.isFinite(min)) {
        return toFixedShorten(min, decimals);
      }
      return '0';
    });

    const onChangeHandler = (value: string) => {
      if (min >= 0 && value.includes('-')) return;
      if (decimals === 0 && value.includes('.')) return;
      // Match only numbers, dots and minus sign AND not empty string
      if (!value.match(/^-?\d*\.?\d*$/) && value !== '') return;
      setInternalValue(value);
    };

    useEffect(() => {
      if (value !== undefined) {
        setInternalValue(toFixedShorten(value, decimals));
      }
    }, [value, decimals]);

    return (
      <div
        className={cn(
          'flex overflow-clip rounded-md border border-[var(--input-number-border)] focus-within:ring-2 focus-within:ring-black/90 focus-within:ring-offset-2',
          classNames?.root,
        )}
      >
        <input
          ref={ref}
          className={cn(
            'min-w-0 flex-1 rounded-l-md border-none bg-transparent focus:outline-none focus:ring-transparent focus:ring-offset-0',
            classNames?.input,
            className,
          )}
          value={internalValue}
          onChange={(e) => onChangeHandler(e.target.value)}
          onBlur={(e) => {
            if (
              e.target.value === '' ||
              e.target.value === '-0' ||
              isNaN(Number(e.target.value))
            ) {
              onChangeHandler(toFixedShorten(min, decimals));
              onChange?.(Number(toFixedShorten(min, decimals)));
            } else {
              const cast = Math.max(min, Math.min(max, Number(e.target.value)));
              const formatted = toFixedShorten(cast, decimals);
              console.log('blur', cast, formatted);
              onChangeHandler(formatted);
              onChange?.(Number(formatted));
            }
          }}
          {...props}
          min={min}
          max={max}
        />
        {showButtons && (
          <div
            className={cn(
              'flex w-[20px] flex-shrink-0 flex-col justify-between self-stretch border-l border-[var(--input-number-border)]',
              classNames?.buttonContainer,
            )}
          >
            <button
              className={cn(buttonClassName, classNames?.button)}
              onClick={() => {
                onChangeHandler(
                  toFixedShorten(Number(internalValue) + step, decimals),
                );
                onChange?.(
                  +toFixedShorten(Number(internalValue) + step, decimals),
                );
              }}
              disabled={Number(internalValue) >= max}
              type="button"
            >
              <ChevronFilledUp size={8.8} />
            </button>
            <div className="border-t border-[var(--input-number-border)]"></div>
            <button
              className={cn(buttonClassName, classNames?.button)}
              onClick={() => {
                onChangeHandler(
                  toFixedShorten(Number(internalValue) - step, decimals),
                );
                onChange?.(
                  +toFixedShorten(Number(internalValue) - step, decimals),
                );
              }}
              disabled={Number(internalValue) <= min}
              type="button"
            >
              <ChevronFilledDown size={8.8} />
            </button>
          </div>
        )}
      </div>
    );
  },
);

export default InputNumber;
