export const getTextColorBasedOnContrast = (
  bgColor: `#${string}` | `rgb(${string})` | string,
): ['white' | 'black', boolean] => {
  let rgb: [number, number, number] = [0, 0, 0];
  // Convert hex color to RGB if it's in hex format
  if (bgColor.startsWith('#')) {
    rgb = bgColor
      .slice(1)
      .match(/.{2}/g)
      ?.map((hex) => parseInt(hex, 16)) as [number, number, number];
  } else if (bgColor.startsWith('rgb')) {
    rgb = bgColor
      .slice(bgColor.indexOf('(') + 1, bgColor.indexOf(')'))
      .split(',')
      .map((value) => parseInt(value)) as [number, number, number];
  } else {
    return ['black', false];
  }
  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000,
  );

  const color = brightness > 125 ? 'black' : 'white';
  const isWhite = color === 'white';

  return [color, isWhite];
};
