'use client';

import { InputSchema } from '@formo/types';
import { FC } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { cn } from '~/lib/utils';

import { inputMapper } from './constants';

type InputMapperProps = {
  id: string;
  type: string;
  isBuilderMode?: boolean;
  /**
   * Controlled props from react-hook-form
   * @see https://react-hook-form.com/api/usecontroller
   */
  controlledProps: ControllerRenderProps;
  fieldSpecs: InputSchema['fieldSpecs'];
  className?: string;
  classNames?: { font: string };
  required?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  theme?: Record<string, any>;
  disabled?: boolean;
};

const InputMapper: FC<InputMapperProps> = ({
  id,
  type,
  controlledProps,
  fieldSpecs,
  className,
  classNames = {},
  theme = {},
  onFocus,
  onBlur,
  required,
  disabled,
  ...props
}) => {
  const Input = inputMapper[type];

  if (!Input) {
    return <div>Missing Component for type: {type}</div>;
  }

  const { Component } = Input({ fieldSpecs });

  return (
    <Component
      id={id}
      disabled={disabled}
      {...controlledProps}
      {...fieldSpecs}
      {...props}
      onFocus={onFocus}
      onBlur={onBlur}
      theme={theme}
      // Let react-hook-form handle the required prop
      required={required}
      className={cn(
        className ? className : 'border-[var(--primary-color)]',
        theme?.radius ? theme?.radius : 'rounded-md',
      )}
      classNames={classNames}
      style={{
        color: theme?.answers ? theme.answers : '#000000',
      }}
    />
  );
};

export default InputMapper;
