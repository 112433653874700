import { REGEX } from '@formo/shared';
import { forwardRef, useState } from 'react';
import { Link } from '~/components/icons';
import { Input, InputProps } from '~/components/ui/input';
import { cn } from '~/lib/utils';

type LinkInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (value: string, isValid: boolean) => void;
};

const LinkInput = forwardRef<HTMLInputElement, LinkInputProps>(
  ({ value, onChange, ...props }, ref) => {
    const [isValid, setIsValid] = useState(true);

    const validateLink = (link: string) => {
      const linkRegex = REGEX.LINK;
      return linkRegex.test(link);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const newIsValid = validateLink(newValue);
      setIsValid(newIsValid);
      onChange?.(newValue, newIsValid);
    };

    return (
      <div className="relative w-full">
        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3 ">
          <Link size={24} className="text-black/30" />
        </div>
        <Input
          {...props}
          ref={ref}
          type="text"
          value={value}
          onChange={handleChange}
          autoFocus={false}
          className={cn(
            props.className,
            'static mb-5 block w-full py-2 pl-10 pr-3',
            !isValid &&
              'border-red-500 focus:border-red-500 focus:ring-red-500',
          )}
        />
        <div className="absolute bottom-0 h-0">
          {!isValid && (
            <p className="pt-1 text-sm text-red-500">
              Please enter a valid link
            </p>
          )}
        </div>
      </div>
    );
  },
);

LinkInput.displayName = 'LinkInput';

export default LinkInput;
