import { REGEX } from '@formo/shared';
import { InputSchema } from '@formo/types';
import { FC } from 'react';
import { FieldValues, RegisterOptions } from 'react-hook-form';

import { Checkboxes } from '../../fields/Checkboxes';
import { DropdownSelect } from '../../fields/DropdownSelect';
import { EmailInput } from '../../fields/EmailInput';
import { LinkInput } from '../../fields/LinkInput';
import { LongTextInput } from '../../fields/LongTextInput';
import { MultipleChoices } from '../../fields/MultipleChoices';
import { Rating } from '../../fields/Rating';
import { ShortTextInput } from '../../fields/ShortTextInput';
import { FileField } from '../FileField';

type Specs<T extends string = string> = InputSchema['fieldSpecs'] & {
  type: T;
};

type InputMapper = Record<
  string,
  // eslint-disable-next-line no-unused-vars -- For typing
  (props: { fieldSpecs: Specs }) => {
    /**
     * React Component
     * Must be a controlled component
     */
    Component: FC<any>;
    /**
     * Custom validation rules
     */
    validation?: Omit<
      RegisterOptions<FieldValues, string>,
      'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    >;
  }
>;

export const inputMapper: InputMapper = {
  email: ({ fieldSpecs }) => ({
    Component: EmailInput,
    validation: {
      pattern: {
        value: /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/,
        message: 'Invalid email format',
      },
      validate: (value: string | undefined) => {
        const specs = fieldSpecs as Specs<'email'>;

        if (typeof value !== 'string') return;

        const [, domain] = value.split('@');
        // If no allowDomains are specified, allow all domains
        if (!specs.allowDomains || specs.allowDomains.length === 0 || !domain)
          return true;
        // If allowDomains are specified, only allow those domains
        return specs.allowDomains.includes(domain) ? true : 'Invalid domain';
      },
    },
  }),
  select: () => ({
    Component: DropdownSelect,
  }),
  text: ({ fieldSpecs }) => ({
    Component: ShortTextInput,
    validation: {
      validate: (value: string | null) => {
        if (typeof value !== 'string') return;
        if (fieldSpecs.required && !value) {
          return 'Content is required';
        }
      },
    },
  }),
  'radio-group': () => ({
    Component: MultipleChoices,
  }),
  rating: ({ fieldSpecs }) => ({
    Component: Rating,
    validation: {
      validate: (value: number) => {
        const specs = fieldSpecs as Specs<'rating'>;
        // If rating is required and value is 0, return an error
        if (fieldSpecs.required && value === 0) {
          return 'Rating is required';
        }
        // If no numOfStars are specified, default to 5
        const noOfStars = specs?.numOfStars || 5;
        // If value is not a number or is not within the range of 0 to noOfStars, return an error
        if (value > noOfStars || value < 0) {
          return 'Invalid rating';
        }
        return true;
      },
    },
  }),
  textarea: ({ fieldSpecs }) => ({
    Component: LongTextInput,
    validation: {
      validate: (value: string | null) => {
        if (typeof value !== 'string') return;
        if (fieldSpecs.required && !value) {
          return 'Content is required';
        }
      },
    },
  }),
  file: ({ fieldSpecs }) => ({
    Component: FileField,
    validation: {
      validate: (value: string | null) => {
        if (typeof value !== 'string') return;
        if (fieldSpecs.required && !value) {
          return 'File is required';
        }
      },
    },
  }),
  url: ({ fieldSpecs }) => ({
    Component: LinkInput,
    validation: {
      pattern: {
        value: REGEX.LINK,
        message: 'Invalid url format',
      },
      validate: (value: string | undefined) => {
        if (typeof value !== 'string') return;
        if (fieldSpecs.required && !value) {
          return 'Link is required';
        }
      },
    },
  }),
  'checkbox-group': () => ({
    Component: Checkboxes,
  }),
  // Add more input field types here
};
