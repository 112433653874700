'use client';

import { Star } from 'lucide-react';
import {
  FC,
  HTMLAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { cn } from '~/lib/utils';

export type RatingProps = {
  value?: number;
  defaultValue?: number;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: number) => void;
  numOfStars?: number;
  disabled?: boolean;
  readonly?: boolean;
  allowCancel?: boolean;
  filledComponent?: React.ReactNode;
  emptyComponent?: React.ReactNode;
  showNumber?: boolean;
  classNames?: {
    wrapper?: string;
    button?: string;
    number?: string;
  };
} & HTMLAttributes<HTMLDivElement>;

const Rating: FC<RatingProps> = forwardRef<HTMLDivElement, RatingProps>(
  (
    {
      defaultValue = 0,
      disabled = false,
      readonly = false,
      numOfStars = 5,
      allowCancel = false,
      showNumber = false,
      onChange,
      value,
      filledComponent = <Star fill="orange" color="orange" />,
      emptyComponent = <Star color="orange" />,
      classNames,
      className,
      ...props
    },
    ref,
  ) => {
    const [rating, setRating] = useState(
      // Clamp the value between 0 and numOfStars
      Math.max(Math.min(value || defaultValue || 0, numOfStars), 0),
    );
    const [hoverRating, setHoverRating] = useState(-1);

    const currentRating = hoverRating !== -1 ? hoverRating : rating;

    const handleOnChange = useCallback(
      (newValue: number) => {
        if (disabled || readonly) return;
        if (allowCancel && rating === newValue) {
          setRating(0);
          onChange?.(0);
          return;
        }
        onChange?.(newValue);
        setRating(newValue);
      },
      [rating],
    );

    const handleOnHover = useCallback(
      (newValue: number) => {
        if (disabled || readonly) return;
        setHoverRating(newValue);
      },
      [disabled, readonly],
    );

    useEffect(() => {
      if (typeof value === 'undefined') return;
      setRating((prev) => (prev === value ? prev : value));
    }, [value]);

    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          'flex space-x-1 shadow-transparent',
          className,
          classNames?.wrapper,
        )}
        onMouseLeave={() => setHoverRating(-1)}
      >
        {[...Array(numOfStars)].map((_, index) => {
          const starValue = index + 1;
          return (
            <button
              key={index}
              type="button"
              onClick={() => handleOnChange(starValue)}
              disabled={disabled || readonly}
              className={cn(
                'flex flex-col items-center justify-center space-y-1 focus:outline-none',
                disabled && 'cursor-not-allowed opacity-50',
                classNames?.button,
              )}
              onMouseEnter={() => handleOnHover(starValue)}
            >
              {currentRating >= starValue ? filledComponent : emptyComponent}
              {showNumber && (
                <span
                  className={cn(
                    'text-xs font-medium text-black/80',
                    classNames?.number,
                  )}
                >
                  {starValue}
                </span>
              )}
            </button>
          );
        })}
      </div>
    );
  },
);

export default Rating;
