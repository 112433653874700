'use client';

import {
  IDKitWidget,
  ISuccessResult,
  VerificationLevel,
} from '@worldcoin/idkit';
import { X } from 'lucide-react';
import { HTMLAttributes, forwardRef, useEffect, useState } from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { CheckCircle, WorldId } from '~/components/icons';
import { Button } from '~/components/ui/button';
import { cn } from '~/lib/utils';

import { Loader } from '../../common';

type ConnectWorldIdProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  onChange?: (value: ISuccessResult | null) => void;
  value?: ISuccessResult;
  disabled?: boolean;
  className?: string;
  font: string;
};

const app_id = process.env.NEXT_PUBLIC_WORLD_ID_APP_ID as `app_${string}`;
const action_id = process.env.NEXT_PUBLIC_WORLD_ID_ACTION_ID!;

const ConnectWorldId = forwardRef<HTMLDivElement, ConnectWorldIdProps>(
  ({ font, onChange, value, ...props }, ref) => {
    const { theme } = useFormBuilder();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState<ISuccessResult | null>(
      value || null,
    );

    const isConnected = !!profile;

    const onSuccess = (value: ISuccessResult) => {
      onChange?.(value);
      setProfile(value);
      setLoading(false);
    };

    const handleVerify = async (value: ISuccessResult) => {
      setLoading(true);
      const res = await fetch('/api/world-id/verify', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(value),
        method: 'POST',
      });

      if (!res.ok) {
        setLoading(false);
        throw new Error('Failed to verify');
      }

      setLoading(false);
    };

    const onDisconnect = () => {
      onChange?.(null);
      setProfile(null);
    };

    useEffect(() => {
      if (!value) return;
      setProfile(value);
    }, [value]);

    return (
      <IDKitWidget
        app_id={app_id}
        action={action_id}
        onSuccess={onSuccess}
        handleVerify={handleVerify}
        verification_level={VerificationLevel.Orb}
      >
        {({ open }) => {
          return (
            <div className="flex items-center gap-2" ref={ref} {...props}>
              <Button
                type="button"
                variant="outline"
                className={cn(
                  'flex h-12 justify-start gap-2.5 !border-1.5 bg-white border-black/5 px-4 py-2 text-white transition-all',
                  {
                    'w-32': loading,
                    'w-fit': !loading,
                    'w-max max-w-full cursor-default': isConnected,
                  },
                  theme?.radius ? theme?.radius : 'rounded-md',
                )}
                onClick={() => {
                  if (loading || isConnected) return;
                  open();
                }}
                disabled={loading}
              >
                <div className="flex aspect-square w-[30px] min-w-[30px] flex-shrink-0 items-center justify-center rounded-[10px] border border-black/10 bg-white">
                  <WorldId size={18} color="#030712" />
                </div>
                {loading ? (
                  <div className="flex h-[30px] flex-1 items-center justify-center rounded-[10px] px-2.5 bg-black/5">
                    <Loader size={20} className="animate-spin" />
                  </div>
                ) : (
                  <>
                    {isConnected && profile ? (
                      <div className="flex h-[30px] w-full items-center gap-2 overflow-clip rounded-[10px] bg-black/5 px-2.5">
                        <CheckCircle size={20} className="text-teal-500" />
                        <span className="text-lg font-medium text-black leading-none">
                          World ID Verified
                        </span>
                        <div className="w-[1.5px] h-5 bg-black/1" />
                        <X
                          size={16}
                          className="min-w-0 flex-shrink-0 cursor-pointer text-black/30"
                          onClick={onDisconnect}
                        />
                      </div>
                    ) : (
                      <span
                        className={cn(
                          'text-lg font-medium leading-none text-black',
                          font,
                        )}
                      >
                        Connect World ID
                      </span>
                    )}
                  </>
                )}
              </Button>
            </div>
          );
        }}
      </IDKitWidget>
    );
  },
);
export default ConnectWorldId;
