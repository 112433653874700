import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';
import { Info } from 'lucide-react';
import React from 'react';
import { Button } from '~/components/ui/button';
import { cn } from '~/lib/utils';

type InfoTooltipProps = {
  content: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  classNames?: { content: string };
  isDark?: boolean;
  position?: 'top' | 'bottom';
  onClick?: () => void;
  disabled?: boolean;
} & React.RefAttributes<HTMLButtonElement>;

const InfoTooltip: React.FC<InfoTooltipProps> = (
  {
    content = '',
    children,
    className = '',
    classNames = { content: '' },
    isDark = false,
    position = 'top',
    onClick,
    disabled,
  },
  props,
) => {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            type="button"
            className={cn(
              'max-h-[20px] p-0',
              className,
              !children && 'max-w-[25px]',
            )}
            onClick={onClick}
            disabled={disabled}
            {...props}
          >
            {children ? children : <Info />}
          </Button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            side={position}
            sideOffset={5}
            className={cn(
              'relative z-50 rounded-[6px] px-2 py-1.5 text-sm font-normal',
              classNames?.content,
              isDark
                ? 'border border-white/10 bg-black/80 text-white'
                : 'border border-black/10 bg-white/80 text-black',
            )}
          >
            {content}
            <TooltipArrow
              className={cn(isDark ? 'fill-black/80' : 'fill-white/80')}
            />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};

export default InfoTooltip;
