import { X } from 'lucide-react';
import {
  Dispatch,
  ForwardedRef,
  SetStateAction,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import {
  FieldValues,
  SubmitHandler,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { FileUploadIcon } from '~/components/icons';
import FileUploadSpinner from '~/components/icons/FileUploadSpinner';
import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import { cn } from '~/lib/utils';
import { bytesToMB } from '~/utils/bytesToMB';

import { FileType, UploadStatus } from '../shared/common';

enum FileUploadErrorCode {
  ExceedMaxSize = 'file-too-large',
  InvalidType = 'file-invalid-type',
}

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const uploadTimeout = 2000;

const UploadedFile: React.FC<{
  file: any;
  removeFile: () => void;
  uploadStatus: UploadStatus;
  progress: number;
}> = ({ file = {}, removeFile, uploadStatus, progress }) => {
  const { theme } = useFormBuilder();
  const { path, size, error } = file;

  function getErrorMessage(code: FileUploadErrorCode | string): string {
    switch (code) {
      case FileUploadErrorCode.ExceedMaxSize:
        return 'The file exceeds the 10 MB size limit.';
      case FileUploadErrorCode.InvalidType:
        return 'Please upload PDF, ZIP, TXT, DOC, or image';
      default:
        return 'Please try again';
    }
  }

  return (
    <div
      className={cn(
        'grid grid-cols-12 h-full w-full items-center rounded-[8px] text-base-dark bg-white border-1  px-3 py-3',
        error ? 'border-red-400' : 'border-black/10',
      )}
      style={{ boxShadow: '2px 2px 6px 0px rgba(54, 54, 54, 0.05)' }}
    >
      <div className="col-span-11 flex items-center">
        <div>
          {uploadStatus === UploadStatus.Uploading ? (
            <FileUploadSpinner color={theme?.primary || '#A7E567'} />
          ) : (
            <FileUploadIcon isSuccess={!error} />
          )}
        </div>
        <div className="flex h-full w-full rounded-md text-left space-y-2 flex-col ps-[14px]">
          <div
            className={cn(
              'text-base leading-[16px] font-medium w-full break-all',
              error ? 'text-red-500' : 'text-black',
            )}
          >
            {path}
          </div>
          {uploadStatus === UploadStatus.Uploading ? (
            <div className="my-5 flex w-full items-center">
              <div
                className="flex h-[3px] w-full overflow-hidden rounded-full bg-gray-200"
                role="progressbar"
                aria-valuenow={1}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="flex flex-col justify-center overflow-hidden whitespace-nowrap rounded-full text-center text-xs text-white transition duration-500"
                  style={{
                    width: `${progress}%`,
                    backgroundColor: theme?.primary || '#A7E567',
                  }}
                ></div>
              </div>
            </div>
          ) : error ? (
            <div
              className={'text-[13px] leading-[13px] font-medium text-red-300'}
            >
              Upload failed. {getErrorMessage(error?.code)}
            </div>
          ) : (
            <div
              className={
                'text-[13px] leading-[13px] font-medium text-gray-600 opacity-50'
              }
            >
              {`${bytesToMB(size)}MB`}
            </div>
          )}
        </div>
      </div>

      <div className="col-span-1 flex justify-end">
        <Button
          variant="ghost"
          type="button"
          className="flex flex-end justify-center h-8 w-8 items-center px-1 py-1 hover:bg-transparent"
          onClick={removeFile}
        >
          <X
            className={cn(
              'h-8 w-8 text-gray-600 transition-colors',
              error
                ? 'text-negative-400 fill-negative-400'
                : 'text-gray-600 fill-gray-600',
            )}
          />
        </Button>
      </div>
    </div>
  );
};

type FileUploaderProps = {
  fieldId: string;
  onSubmit: SubmitHandler<{ file: FileType }>;
  file: FileType | null;
  setFile: Dispatch<SetStateAction<FileType | null>>;
  className?: string;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  font?: string;
};

const FileUploader = forwardRef<HTMLDivElement, FileUploaderProps>(
  (
    { fieldId, onSubmit, file, setFile, className, setValue, register, font },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    // Co
    const { form } = useFormBuilder();
    const [progress, setProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
      UploadStatus.Select,
    );

    const onDrop = useCallback(
      <T extends File>(acceptedFiles: T[], rejectedFiles: FileRejection[]) => {
        if (uploadStatus === UploadStatus.Done) {
          removeFile();
          return;
        }

        if (acceptedFiles?.length) {
          const uploadedFile = acceptedFiles[0];
          const reader = new FileReader();
          setFile(
            Object.assign(uploadedFile!, {
              preview: URL.createObjectURL(uploadedFile!),
            }) as FileType,
          );
          setValue(
            fieldId,
            {
              name: uploadedFile?.name,
              src: `${form?.id}/${fieldId}`,
              size: uploadedFile?.size,
            } || {},
          );
          reader.onload = () => {
            setTimeout(() => {
              setUploadStatus(UploadStatus.Done);
              onSubmit({
                file: Object.assign(uploadedFile!, {
                  preview: URL.createObjectURL(uploadedFile!),
                }) as FileType,
              });
            }, uploadTimeout);
          };
          reader.onprogress = (event) => {
            setUploadStatus(UploadStatus.Uploading);
            if (event.lengthComputable) {
              const percentComplete = (event.loaded / event.total) * 100;
              setProgress(percentComplete);
            }
          };
          reader.readAsDataURL(uploadedFile as Blob);
        }

        if (rejectedFiles?.length) {
          const rejectedFile = rejectedFiles[0]!.file;
          setFile(
            Object.assign(rejectedFile!, {
              preview: URL.createObjectURL(rejectedFile!),
              error: rejectedFiles[0]!.errors[0],
            }) as FileType,
          );
        }
      },
      [],
    );

    const { getRootProps, isDragActive } = useDropzone({
      accept: {
        'application/pdf': [],
        'application/zip': [],
        'text/plain': [],
        'application/msword': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          [],
        'image/*': [],
      },
      maxSize: MAX_FILE_SIZE,
      maxFiles: 1,
      onDrop,
    });

    const removeFile = () => {
      setFile(null);
      setProgress(0);
      setUploadStatus(UploadStatus.Select);
    };

    return (
      <div
        className={cn(
          'relative flex items-center justify-center !border-0',
          className,
          font,
        )}
        {...getRootProps({})}
        ref={ref}
      >
        <div
          className={cn(
            'flex h-full w-full items-center justify-center rounded-[8px] text-end text-base font-normal text-white',
          )}
        >
          <div
            className={cn(
              'w-full bg-neutral-50 rounded-[8px] !border-0 focus:outline-none',
              isDragActive ? 'bg-green-500/5' : 'bg-neutral-50',
            )}
          >
            <div
              className={cn(
                'flex h-full w-full flex-col items-center border-2 rounded-[8px] border-dashed px-2.5 py-2.5 hover:cursor-pointer',
                isDragActive ? 'border-primary' : 'border-gray-500',
              )}
            >
              <Input className="sr-only" type="text" {...register(fieldId)} />
              {!file ? (
                <div className="py-2.5 flex flex-col items-center">
                  <img src="/illustrations/file-upload.svg" alt="upload-file" />
                  <p className="text-base font-medium text-gray-800 leading-normal">
                    Drag and drop or click to select files
                  </p>
                  <p className="mt-0.5 text-sm font-medium leading-normal text-gray-600">
                    Maximum size: 10MB
                  </p>
                </div>
              ) : (
                <UploadedFile
                  file={file}
                  removeFile={removeFile}
                  uploadStatus={uploadStatus}
                  progress={progress}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default FileUploader;
