import { StepSchema } from '@formo/types';
import { FC } from 'react';

import FieldMapper from '../FieldMapper/FieldMapper';

type FormStepProps = StepSchema & {
  theme?: Record<string, any>;
};

const FormStep: FC<FormStepProps> = ({ fields, theme }) => {
  return (
    <div className="max-w-full overflow-clip p-1">
      <div className="space-y-8">
        {fields.map((field) => (
          <FieldMapper key={field.id} {...field} theme={theme} />
        ))}
      </div>
    </div>
  );
};

export default FormStep;
