'use client';

/* eslint-disable no-case-declarations */
import {
  BaseFieldSchema,
  FormResponse,
  IdentitySchema,
  StepSchema,
  TokenGateCondition,
  TokenGateSettingRequirement,
} from '@formo/types';
import { Button } from '@formo/ui';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTokenGate from '~/app/hooks/useTokenGate';
import { Form } from '~/components/ui/form';
import { StepType } from '~/constants/fields';
import { FORM_STATUS } from '~/constants/status';
import { cn } from '~/lib/utils';
import { getTextColorBasedOnContrast } from '~/utils/color';
import { hexToRGBA } from '~/utils/hexToRGBA';
import { loadSessionStorage } from '~/utils/storage';

import { FormStep } from '../FormStep';
import { TokenGateStatus } from './TokenGateStep';
import { handleStepConditions, validateTokenGate } from './utils';

type FormRenderProps = {
  id: string;
  status: FORM_STATUS;
  steps: StepSchema[];
  settings: Record<string, any>;
  onSubmit: (
    value: any,
    steps: string[],
  ) => Promise<FormResponse | Error> | void;
  isPreview?: boolean;
  className?: string;
  childClassnames?: {
    spacing: string;
  };
  activeStepIndex?: number;
  setActiveStepIndex?: React.Dispatch<React.SetStateAction<number>>;
  sameAsPublished?: boolean;
};

type TokenGateValue = Map<
  keyof TokenGateSettingRequirement, // The type of the requirement
  Record<
    string, // The condition id
    {
      value: any; // This can be amount, address, username, etc.
      isValid: boolean;
    }
  >
>;

const FormRenderer: FC<FormRenderProps> = ({
  onSubmit,
  steps,
  id,
  status: formStatus,
  isPreview = false,
  className,
  activeStepIndex = 0,
  setActiveStepIndex = () => {},
  settings,
  ...props
}) => {
  const {
    availableRequirements,
    requirements,
    requirementsCount,
    tokenGatePage,
  } = useTokenGate();
  const form = useForm({ values: loadSessionStorage(id) });
  const {
    formState: { errors },
  } = form;

  const [tokenGateValues, setTokenGateValues] = useState<TokenGateValue>(
    new Map(),
  );
  // Store the previous identity values to check if the user has updated the field
  const identityRef = useRef<object[]>([]);
  const [status, setStatus] = useState<
    Map<keyof TokenGateSettingRequirement, TokenGateStatus[]>
  >(new Map());
  const [validRequirements, setValidRequirements] = useState<number>(0);

  const [isSubmit, setIsSubmit] = useState(false);
  const prevSteps = useRef<string[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const activeStep = steps[activeStepIndex];

  const isErrorActiveStep = Object.keys(errors).some((key) =>
    activeStep?.fields.some((field) => field.id === key),
  );

  const closedField: BaseFieldSchema = {
    id: 'closed_field',
    label: 'Form closed',
    subText: 'This form is no longer accepting responses.',
    fieldType: 'closed',
    fieldSpecs: {
      hideIcon: false,
      hideFormo: false,
    },
  };

  const watch = JSON.stringify(
    form.watch(steps.flatMap((s) => s.fields.map((f) => f.id))),
  );

  useEffect(() => {
    if (!activeStep) return;

    if (activeStep.type === StepType.AUTH) {
      return;
    }
    // Check nextStepCondition to handle logic map
    const nextStepId = handleStepConditions(form.getValues(), activeStep);
    const nextStepIndex = steps.findIndex((s) => s.id === nextStepId);
    if (nextStepIndex !== -1) {
      setIsSubmit(steps[nextStepIndex]?.type === StepType.ENDING);
    }
  }, [activeStepIndex, steps, watch]);

  // MUST BE STRINGIFY TO AVOID INFINITE LOOP
  const watchTokenGate = JSON.stringify(
    form.watch(tokenGatePage.fields?.map((f) => f.id) ?? []),
  );

  useEffect(() => {
    // Check if the active step is an auth step and handle validations
    if (!activeStep) return;

    if (
      activeStep.type !== StepType.AUTH ||
      tokenGatePage.fields?.length === 0
    ) {
      return;
    }

    // Check if the identity fields have been updated
    const newWatch = JSON.parse(watchTokenGate) as object[];

    const changedIds = newWatch.reduce<Set<string>>((acc, field, index) => {
      // If the value has been disconnected, change from 'has value' -> 'null'
      if (!field && identityRef.current[index]) {
        const type = tokenGatePage.fields?.[index]?.fieldSpecs
          .type as keyof TokenGateSettingRequirement;
        setTokenGateValues((prev) => {
          const newValues = new Map(prev);
          newValues.delete(type);
          return newValues;
        });
        setStatus((prev) => {
          const newStatus = new Map(prev);
          newStatus.delete(type);
          return newStatus;
        });
        return acc;
      }

      // Add the field id to the list if the field has been updated
      if (!_.isEqual(field, identityRef.current[index])) {
        acc.add(tokenGatePage.fields?.[index]?.id ?? '');
      }
      return acc;
    }, new Set());

    const validate = async () => {
      const newTokenGateValues = new Map(tokenGateValues) as TokenGateValue;
      for (const field of tokenGatePage.fields) {
        if (!changedIds.has(field.id)) {
          continue;
        }

        const currentRequirementValue = form.getValues(field.id);
        if (!currentRequirementValue) {
          continue;
        }

        const specs = field.fieldSpecs as IdentitySchema['fieldSpecs'] & {
          conditions: TokenGateCondition[];
        };

        const gateValue = (newTokenGateValues.get(specs.type) || {}) as Record<
          string,
          { value: any; isValid: boolean }
        >;

        specs.conditions.forEach(async (condition, index) => {
          setStatus((prev) => {
            const newStatus = new Map(prev);
            const status = newStatus.get(specs.type) || [];
            status[index] = TokenGateStatus.LOADING;
            newStatus.set(specs.type, status);
            return newStatus;
          });
          const res = await validateTokenGate(
            condition,
            currentRequirementValue,
            specs,
          );
          setStatus((prev) => {
            const newStatus = new Map(prev);
            const status = newStatus.get(specs.type) || [];
            status[index] = res.isValid
              ? TokenGateStatus.VALID
              : TokenGateStatus.INVALID;
            newStatus.set(specs.type, status);
            return newStatus;
          });
          gateValue[`${index}`] = res;
          newTokenGateValues.set(specs.type, gateValue);
        });
      }

      setTokenGateValues(newTokenGateValues);
      identityRef.current = newWatch;
    };

    validate();
  }, [activeStep, watchTokenGate]);

  const onNextStep = (nextStepId: string) => {
    const nextStepIndex = steps.findIndex((s) => s.id === nextStepId);
    if (nextStepIndex !== -1) {
      setActiveStepIndex(nextStepIndex);
      setIsSubmit(false);
      (containerRef.current as HTMLDivElement).scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      console.error('[ERROR] Next step not found');
    }
  };

  const onPrevStep = () => {
    const prevStep = prevSteps.current.pop();
    const prevStepIndex = steps.findIndex((s) => s.id === prevStep);
    if (prevStepIndex !== -1) {
      setActiveStepIndex(prevStepIndex);
    } else {
      console.error('[ERROR] Previous step not found');
    }
  };

  const isValidTokenGate = useMemo(() => {
    // If the token gate is not active, the user has fulfilled the requirements
    if (
      !activeStep ||
      activeStep.type !== StepType.AUTH ||
      !settings.tokenGate
    ) {
      return true;
    }

    const statuses: Array<
      | 'wallet'
      | 'discord'
      | 'twitter'
      | 'telegram'
      | 'farcaster'
      | 'world-id'
      | 'solana'
    > = [
      'wallet',
      'discord',
      'twitter',
      'telegram',
      'farcaster',
      'world-id',
      'solana',
    ];

    // Count the total number of valid entries across the status types
    const validRequirements = statuses.reduce((count, statusType) => {
      const validCount =
        status.get(statusType)?.filter((value) => value === 'valid').length ||
        0;
      return count + validCount;
    }, 0);

    setValidRequirements(validRequirements);
    // All
    if (settings.tokenGate.type === 'all') {
      // empty array .every() returns true
      if (validRequirements === 0) return false;
      return validRequirements > 0 && validRequirements === requirementsCount;
    }

    // Check if 'wallet' has enough valid entries
    // At least
    return validRequirements >= settings?.tokenGate?.count;
  }, [
    status,
    settings,
    activeStep,
    settings?.tokenGate?.count,
    requirementsCount,
  ]);

  const onInternalSubmit = async (values: any) => {
    console.log('onInternalSubmit values', values);
    // If the active step is an auth step, move to the next step
    if (!activeStep) return;
    if (activeStep.type === StepType.AUTH && isValidTokenGate) {
      // Save the token gate values to the form
      Array.from(tokenGateValues.entries()).forEach(([key, value]) => {
        Object.entries(value).forEach(([id, condition]) => {
          form.setValue(`token-gate-${key}-${id}`, condition);
        });
      });
      setActiveStepIndex((prev) => prev + 1);
      return;
    }
    try {
      prevSteps.current.push(activeStep.id);
      if (isSubmit) {
        const res = await onSubmit(values, prevSteps.current);
        if (res && !('id' in res)) {
          console.error(res);
          return;
        }
      }
      const nextStepId = handleStepConditions(form.getValues(), activeStep);
      onNextStep(nextStepId);
    } catch (error) {
      console.error(error);
    }
  };

  if (formStatus === FORM_STATUS.CLOSED && !isPreview)
    return (
      <div
        className="h-full max-h-full w-full overflow-clip"
        style={{
          backgroundColor: settings?.backgroundColor ?? '#F7F7F7',
          backgroundImage: settings?.backgroundImage
            ? `url(${settings?.backgroundImage?.preview})`
            : 'none',
          backgroundSize: 'cover',
        }}
      >
        <div
          className="flex max-h-full w-full justify-center overflow-y-auto pt-[10%]"
          ref={containerRef}
        >
          <ClosedField {...closedField} />
        </div>
      </div>
    );

  if (!activeStep) return <div>Empty Page</div>;

  return (
    <div
      className="h-full w-full overflow-clip"
      style={{
        backgroundColor: settings?.backgroundColor ?? '#F7F7F7',
        backgroundImage: settings?.backgroundImage
          ? `url(${settings?.backgroundImage?.preview})`
          : 'none',
        backgroundSize: 'cover',
      }}
    >
      <div
        className={cn(
          'relative flex h-full w-full items-center justify-center overflow-y-auto pb-[calc(66px+40px)] max-[720px]:pt-[56px]',
          settings?.logo ? 'pt-14' : 'pt-10',
        )}
        ref={containerRef}
      >
        <div className="relative w-full max-w-[720px] h-fit pt-[30px] my-auto">
          {settings?.logo && (
            <div className="absolute -top-[30px] w-full pl-2 sm:pl-0">
              <Image
                src={settings?.logo?.preview}
                alt="Form logo"
                className="mb-3 max-h-[40px] w-auto"
                style={{ objectFit: 'contain' }}
                width={160}
                height={40}
                priority
              />
            </div>
          )}
          <div
            className={cn(
              'form-wrapper relative flex h-max w-full flex-col rounded-xl border border-black/10 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.04)] overflow-hidden',
              className,
              activeStep.type !== StepType.AUTH &&
                activeStep.type !== StepType.ENDING &&
                'flex-grow',
            )}
            {...props}
            title={undefined}
          >
            {activeStep.type === StepType.AUTH && (
              <div
                className={cn(
                  'shadow-[0px 2px 10px 0px rgba(0, 0, 0, 0.04)] flex flex-col gap-2 rounded-t-xl bg-white px-10 py-6',
                  settings?.transparency ? 'bg-opacity-80' : 'bg-opacity-90',
                  settings?.font,
                )}
              >
                <h1
                  className="text-2xl font-medium"
                  style={{
                    color: settings?.questions,
                  }}
                >
                  Requirements to access
                </h1>
                <div className="flex items-center gap-2.5">
                  <span
                    className="text-sm text-gray-300"
                    style={{
                      color: settings?.questions
                        ? hexToRGBA(settings?.questions, 0.7)
                        : 'rgba(0, 0, 0, 0.3)',
                    }}
                  >
                    To view this form, you must satisfy{' '}
                    {settings.tokenGate?.type === 'all'
                      ? 'ALL'
                      : settings.tokenGate?.count}{' '}
                    of the requirements below
                  </span>
                  <span className="rounded-[11px] bg-black/5 px-2 py-0.5 text-sm text-positive-base">
                    {validRequirements}/{requirementsCount}
                  </span>
                </div>
              </div>
            )}
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onInternalSubmit)}
                className={cn(
                  settings?.font,
                  'flex w-full flex-col justify-between rounded-b-[inherit] bg-white px-6 sm:px-10 shadow-[0px_-6px_32px_0px_rgba(0,0,0,0.05)]',
                  settings?.transparency ? 'bg-opacity-90' : 'bg-opacity-100',
                )}
              >
                <div className="space-y-4 py-6 pb-2 sm:py-10 sm:pb-4">
                  {activeStep.type === StepType.AUTH ? (
                    <TokenGateStep
                      requirements={requirements}
                      tokenGatePage={tokenGatePage as StepSchema}
                      availableRequirements={availableRequirements}
                      status={status}
                    />
                  ) : activeStep.type === StepType.WELCOME ? (
                    <>
                      {/* -margin same size as the padding of the form */}
                      <div className="-mx-10 -mt-10 rounded-[inherit] overflow-clip">
                        <div className="relative w-full h-[200px]">
                          <Image
                            src={
                              settings?.coverImage?.preview ||
                              '/illustrations/checker.png'
                            }
                            alt="cover"
                            fill
                            className="object-cover object-center"
                            priority
                            sizes="(max-width: 720px) 100vw, 720px"
                          />
                        </div>
                        <div className="px-[42px] py-8">
                          <h2
                            className={cn(
                              settings?.font,
                              'text-base-dark-450 text-[30px] font-medium break-words',
                            )}
                          >
                            {activeStep.fields[0]?.label}
                          </h2>
                          <p
                            className={cn(
                              settings?.font,
                              'text-base-dark-450 whitespace-pre-wrap break-words',
                            )}
                          >
                            {activeStep.fields[0]?.subText}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <FormStep {...activeStep} theme={settings} />
                  )}
                </div>
                {activeStep.type !== StepType.ENDING &&
                  activeStep.type !== StepType.CLOSED && (
                    <NavigationButtons
                      isPreview={isPreview}
                      activeStepIndex={activeStepIndex}
                      setActiveStepIndex={setActiveStepIndex}
                      steps={steps}
                      isSubmit={isSubmit}
                      isErrorActiveStep={isErrorActiveStep}
                      onPrevStep={onPrevStep}
                      theme={settings}
                      isValidTokenGate={isValidTokenGate}
                      isPrevTokenGate={
                        steps[activeStepIndex - 1]?.type === StepType.AUTH
                      }
                    />
                  )}
              </form>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const TokenGateStep = dynamic(() => import('./TokenGateStep'), {
  loading: () => (
    <div className="w-full">
      <div className="space-y-6 py-3">
        <div className="animate-pulse bg-gray-200 h-10 rounded-md"></div>
        <div className="animate-pulse bg-gray-200 h-10 rounded-md"></div>
      </div>
    </div>
  ),
});

const ClosedField = dynamic(() => import('../ClosedField/ClosedField'), {
  loading: () => (
    <div className="flex flex-col items-center animate-pulse">
      <div className="mb-7 w-[62px] h-[62px] bg-gray-200 rounded-full" />
      <div className="w-[300px] h-[36px] bg-gray-200 rounded-md" />
      <div className="mt-1 w-[250px] h-[24px] bg-gray-200 rounded-md" />
      <div className="mt-8 w-[26px] h-[26px] bg-gray-200 rounded-md" />
      <div className="mt-2 w-[120px] h-[20px] bg-gray-200 rounded-md" />
    </div>
  ),
  ssr: false,
});

type NavigationButtonsProps = {
  isPreview: boolean;
  activeStepIndex: number;
  setActiveStepIndex: React.Dispatch<React.SetStateAction<number>>;
  steps: StepSchema[];
  isSubmit: boolean;
  isErrorActiveStep: boolean;
  onPrevStep: () => void;
  theme?: Record<string, any>;
  isValidTokenGate?: boolean;
  isPrevTokenGate?: boolean;
};

const NavigationButtons: FC<NavigationButtonsProps> = ({
  isPreview,
  activeStepIndex,
  setActiveStepIndex,
  steps,
  isSubmit,
  isErrorActiveStep,
  onPrevStep,
  isValidTokenGate,
  isPrevTokenGate,
  theme,
}) => {
  const disabledBack = isPreview && activeStepIndex === 0;
  const disabledNext = isPreview
    ? activeStepIndex === steps.length - 1
    : isErrorActiveStep || !isValidTokenGate;

  const nextBg = disabledNext
    ? 'rgb(232,232,232)'
    : (theme?.primary ?? 'rgba(0, 0, 0, 1)');

  const [nextText, isDarkBackground] = getTextColorBasedOnContrast(nextBg);

  const nextBtnText = (() => {
    if (steps[activeStepIndex]?.type === StepType.AUTH) return 'Continue';
    if (isSubmit) return 'Submit';
    return 'Next';
  })();

  return (
    <>
      <style>{`
        .custom-button-hover:hover {
          background-image: linear-gradient(
            ${
              isDarkBackground
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)'
            },
            ${
              isDarkBackground
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)'
            }
          ) !important;
        }
      `}</style>

      <div className="-mx-6 sm:-mx-10 mt-auto">
        <div className="w-full bg-gray-50 px-6 sm:px-10 py-5 rounded-b-xl border-t border-gray-100">
          <div className="flex justify-center items-center gap-4">
            {activeStepIndex !== 0 && !isPrevTokenGate && (
              <Button
                variant="soft"
                className={cn(
                  theme?.font,
                  theme?.radius || 'rounded-md',
                  'w-24 text-sm',
                )}
                type="button"
                onClick={
                  isPreview
                    ? () => {
                        setActiveStepIndex((prev) => Math.max(prev - 1, 0));
                      }
                    : onPrevStep
                }
                disabled={disabledBack}
              >
                Back
              </Button>
            )}
            <Button
              variant="primary"
              className={cn(
                theme?.font,
                theme?.radius || 'rounded-md',
                'transition-colors duration-200',
                'custom-button-hover',
                'w-24 text-sm',
              )}
              type={isPreview ? 'button' : 'submit'}
              disabled={disabledNext}
              style={{
                backgroundColor: nextBg,
                color: nextText,
                borderColor: nextBg,
              }}
              onClick={() => {
                if (!isPreview) return;
                setActiveStepIndex((prev) =>
                  Math.min(prev + 1, steps.length - 1),
                );
              }}
            >
              {nextBtnText}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default FormRenderer;
