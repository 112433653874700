'use client';

import { ImageSchema } from '@formo/types';
import Image from 'next/image';
import { FC } from 'react';
import { ImageAlignment } from '~/constants/fields';
import { cn } from '~/lib/utils';

import { ChangeImageModal } from './ChangeImageModal';
import { UploadImageType } from './shared/common';

type ImageFieldProps = {
  id?: string;
  fieldSpecs: ImageSchema['fieldSpecs'];
  className?: string;
  isBuilderMode?: boolean;
};

type ImageFieldSpecsType = {
  file: Record<string, unknown>;
  type: UploadImageType;
  dialogTriggerText: string;
  storageName: string;
  height: number;
  imageAlignment: ImageAlignment;
};

const ImageField: FC<ImageFieldProps> = ({
  id: fieldId = '',
  fieldSpecs,
  className,
  isBuilderMode = false,
  ...props
}) => {
  const { file, type, dialogTriggerText, storageName, height, imageAlignment } =
    fieldSpecs as ImageFieldSpecsType;

  const defaultImageName = 'default.jpg';

  return (
    <div
      className={cn(
        'relative flex items-center justify-center rounded-md border-white border-opacity-30',
        className,
      )}
      {...props}
    >
      <div
        className={cn(
          'relative block rounded-md',
          imageAlignment === ImageAlignment.Center ? 'w-[80%]' : 'w-full',
          height ? 'h-[280px] max-h-[280px]' : 'h-[200px] max-h-[200px]',
        )}
      >
        {isBuilderMode && (
          <div
            className="absolute inset-0 z-10 flex items-center justify-center rounded-md text-base font-normal text-white"
            style={{
              background: 'rgba(47, 47, 50, 0.5)',
              backgroundSize: 'cover',
            }}
          >
            <ChangeImageModal
              fieldId={fieldId}
              type={type}
              triggerText={dialogTriggerText}
              blobName={storageName}
              initialFile={
                (file?.preview as string)?.split('/')[
                  (file?.preview as string)?.split('/').length - 1
                ] !== defaultImageName
                  ? (file as any)
                  : {}
              }
            />
          </div>
        )}
        <Image
          src={file?.preview as string}
          alt={(file?.name as string) || 'Image preview'}
          className="rounded-md object-cover"
          fill
          sizes={imageAlignment === ImageAlignment.Center ? '80vw' : '100vw'}
          priority={type === UploadImageType.Logo}
          quality={75}
        />
      </div>
    </div>
  );
};

export default ImageField;
