'use client';

import { RadioGroupProps } from '@radix-ui/react-radio-group';
import { X } from 'lucide-react';
import React, { ReactNode, useEffect, useState } from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import { RadioGroup, RadioGroupItem } from '~/components/ui/radio-group';
import { Table, TableBody, TableCell, TableRow } from '~/components/ui/table';
import { FieldSpecs, otherOptionKey } from '~/constants/fields';
import { Option } from '~/constants/fields';
import { cn } from '~/lib/utils';

import AddOptionButton from '../../common/AddOptionButton';

type DefaultTemplateProps = {
  value: string;
  label: ReactNode;
  isSelected?: boolean;
  disabled?: boolean;
  style?: Record<string, string>;
  isBuilderMode?: boolean;
  theme?: Record<string, any>;
  classNames?: { font: string };
  horizontalAlignment?: boolean;
  onSelect: (value: string) => void;
} & Record<string, any>;

const DefaultTemplate: React.FC<DefaultTemplateProps> = ({
  label,
  value,
  isSelected,
  disabled = false,
  style,
  isBuilderMode,
  theme = {},
  classNames: { font } = {},
  horizontalAlignment = false,
  onSelect,
}) => {
  const {
    updateFieldOptionLabel,
    deleteFieldOption,
    setAllowOtherOption,
    selectedStep,
    selectedElement,
  } = useFormBuilder();

  const [hovered, setHovered] = React.useState(false);

  const handleLabelChange = (e: React.FormEvent<HTMLSpanElement>) => {
    const inputElement = e.currentTarget;
    const newLabel = inputElement.textContent;
    if (!newLabel) {
      e.currentTarget.textContent = '';
      updateFieldOptionLabel(
        selectedStep.id,
        selectedElement?.id as string,
        value,
        '    ',
      );
    } else {
      updateFieldOptionLabel(
        selectedStep.id,
        selectedElement?.id as string,
        value,
        newLabel,
      );
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLSpanElement>) => {
    e.preventDefault();

    // Get text from the clipboard
    const text = e.clipboardData.getData('text/plain');

    // Insert the plain text at the cursor position
    document.execCommand('insertText', false, text);
  };

  const handleDeleteOption = (optionId: string) => {
    deleteFieldOption(
      selectedStep?.id,
      selectedElement?.id as string,
      optionId,
    );
    if (optionId === otherOptionKey)
      setAllowOtherOption(
        selectedStep?.id,
        selectedElement?.id as string,
        false,
      );
  };

  const handleClickEdit = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    e.preventDefault();
    onSelect(value);
  };

  return (
    <Label htmlFor={value} className="cursor-pointer overflow-visible">
      <TableRow
        style={
          isSelected
            ? theme?.primary
              ? {
                  maxWidth: '100%',
                  border: `1px solid ${theme?.primary}`,
                  boxShadow: `0px 0px 4px 0px ${theme?.primary}`,
                  backgroundColor: hovered
                    ? 'rgba(0, 0, 0, 0.05)'
                    : 'rgb(255,255,255)',
                  ...style,
                }
              : {
                  maxWidth: '100%',
                  border: '1px solid rgba(134, 219, 46, 1)',
                  boxShadow: '0px 0px 4px 0px rgba(134, 219, 46, 1)',
                  backgroundColor: hovered
                    ? 'rgba(0, 0, 0, 0.05)'
                    : 'rgb(255,255,255)',
                  ...style,
                }
            : {
                maxWidth: '100%',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                transition: 'background-color 0.3s ease',
                backgroundColor: hovered
                  ? 'rgba(0, 0, 0, 0.03)'
                  : 'rgb(255,255,255)',
                ...style,
              }
        }
        className={cn(
          'relative flex items-center space-x-3 overflow-visible border-1 px-4.5 py-3 shadow',
          theme?.radius ? theme?.radius : 'rounded-lg',
          horizontalAlignment ? 'w-fit max-w-full' : 'w-full max-w-full',
        )}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <TableCell className="flex flex-col justify-start p-0">
          <RadioGroupItem
            value={value}
            id={value}
            disabled={disabled}
            className="border focus:border focus:ring-0 focus:ring-offset-0"
            style={{
              border: isSelected
                ? theme?.primary
                  ? `1px solid ${theme?.primary}`
                  : '1px solid rgba(167, 229, 103, 1)'
                : '1px solid rgba(3, 7, 18, 0.3)',
              color: isSelected
                ? theme?.primary
                  ? theme?.primary
                  : 'rgba(167, 229, 103, 1)'
                : 'transparent',
            }}
          />
        </TableCell>
        <TableCell
          style={{
            cursor: 'pointer',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
          className="min-h-7 w-full rounded-none border-0 bg-transparent p-0 text-lg leading-6 focus:bg-opacity-50 focus:bg-none focus-visible:ring-0 focus-visible:ring-offset-0"
          tabIndex={0}
        >
          <span
            contentEditable={isBuilderMode && label !== 'Other'}
            className={cn(
              disabled
                ? 'cursor-not-allowed'
                : isBuilderMode
                  ? 'cursor-text'
                  : 'cursor-pointer',
              'inline-block min-h-7 mt-1 w-full min-w-16 outline-none',
              font,
            )}
            onBlur={(e) => (isBuilderMode ? handleLabelChange(e) : null)}
            onClick={handleClickEdit}
            onPaste={(e) => (isBuilderMode ? handlePaste(e) : null)}
            tabIndex={0}
          >
            {label ? label : '&nbsp;'}
          </span>
        </TableCell>
        {isBuilderMode && hovered && (
          <button
            type="button"
            className="absolute right-[-7px] top-[-7px] flex h-5 w-5 items-center justify-center rounded-full border-0 bg-gray-400 p-0 transition-colors"
            onClick={() => handleDeleteOption(value)}
          >
            <X className="h-4 w-4 fill-white text-white transition-colors" />
          </button>
        )}
      </TableRow>
    </Label>
  );
};

type MultipleChoicesProps = Omit<RadioGroupProps, 'onChange'> & {
  id: string;
  options: Option[];
  allowOther?: boolean;
  isBuilderMode?: boolean;
  template?: React.FC<DefaultTemplateProps>;
  onChange?: (value: string) => void;
  classNames?: {
    font?: string;
    input?: string;
  };
  preFetch?: () => Promise<Option[]>;
  theme?: Record<string, any>;
};

const MultipleChoices = React.forwardRef<
  React.ElementRef<typeof RadioGroup>,
  MultipleChoicesProps
>(
  (
    {
      id,
      options: initialOptions = [],
      template: Template = DefaultTemplate,
      allowOther,
      isBuilderMode,
      classNames: { input: inputClassName, font } = {},
      onChange,
      defaultValue,
      value,
      disabled,
      preFetch,
      theme = {},
      ...props
    },
    ref,
  ) => {
    const { selectedElement, selectedStep } = useFormBuilder();

    const [internalValue, setInternalValue] = useState(value ?? defaultValue);
    const [options, setOptions] = useState<Option[]>(initialOptions);
    const [isOther, setIsOther] = useState(() => {
      if (!allowOther) return false;
      if (typeof internalValue !== 'string') return false;
      return !options.some((option) => option.value === internalValue);
    });

    const isSelected = selectedElement?.id === id;
    const field = selectedStep?.fields?.find((field) => field.id === id);

    const onOptionChange = (value: string) => {
      if (value === otherOptionKey) {
        setInternalValue('');
        onChange?.('');
        setIsOther(true);
      } else {
        setInternalValue(value);
        onChange?.(value);
        setIsOther(false);
      }
    };

    const onOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInternalValue(e.target.value);
      onChange?.(e.target.value);
    };

    React.useEffect(() => {
      if (value === undefined) return;
      const isOther = !options.some((option) => option.value === value);
      setInternalValue((prev) => (prev === value ? prev : value));
      setIsOther((prev) => prev || isOther);
    }, [value]);

    useEffect(() => {
      if (selectedStep?.fields) {
        if ((field?.fieldSpecs as FieldSpecs)?.options) {
          setOptions((field?.fieldSpecs as FieldSpecs).options);
          setIsOther(
            typeof internalValue === 'string' &&
              !(field?.fieldSpecs as FieldSpecs).options.some(
                (option) => option.value === internalValue,
              ),
          );
        }
      }
    }, [selectedStep, internalValue]);

    useEffect(() => {
      if (!preFetch) return;

      const handleFetchOptions = async () => {
        try {
          const fetchedOptions = await preFetch();
          setOptions(fetchedOptions);
          setIsOther(
            !fetchedOptions.some((option) => option.value === internalValue),
          );
        } catch (error) {
          console.log('[ERROR] Fetching options', error);
          setOptions([
            {
              label: 'Error fetching options',
              value: 'error',
              disabled: true,
            },
          ]);
          setInternalValue('error');
        }
      };

      handleFetchOptions();
    }, [preFetch]);

    const handleFocusOtherInput = (
      event: React.FocusEvent<HTMLInputElement, Element>,
    ) => {
      event.target.style.border = theme?.primary
        ? `1.5px solid ${theme.primary}`
        : '1.5px solid #86DB2ECC';
      event.target.style.boxShadow = theme?.primary
        ? `0px 0px 4px 0px ${theme?.primary}`
        : '0px 0px 4px 0px rgba(134, 219, 46, 1)';
      event.target.style.backgroundColor = 'rgba(255, 255, 255, 1)';
    };

    const handleBlurOtherInput = (
      event: React.FocusEvent<HTMLInputElement, Element>,
    ) => {
      event.target.style.border = '1.5px solid rgba(0, 0, 0, 0.1)';
      event.target.style.boxShadow = 'none';
    };

    const { horizontalAlignment = true } =
      (field?.fieldSpecs as FieldSpecs) || ({} as FieldSpecs);

    return (
      <>
        <RadioGroup
          ref={ref}
          {...props}
          onValueChange={onOptionChange}
          value={isOther ? otherOptionKey : internalValue}
          disabled={disabled}
          className="gap-0"
        >
          <Table className="w-max max-w-full overflow-visible">
            <TableBody
              className={cn(
                'border-separate border-spacing-y-2 gap-2 overflow-visible px-1 pr-1.5 pt-1.5',
                horizontalAlignment
                  ? 'flex w-full flex-row flex-wrap'
                  : 'flex w-full flex-col',
              )}
            >
              {options.map((option) => (
                <Template
                  key={option.value}
                  {...option}
                  isSelected={option.value === internalValue}
                  isBuilderMode={isBuilderMode}
                  theme={theme}
                  classNames={font ? { font } : undefined}
                  horizontalAlignment={
                    (horizontalAlignment as boolean)! ?? false
                  }
                  onSelect={onOptionChange}
                />
              ))}
              {allowOther && (
                <Template
                  key={otherOptionKey}
                  label="Other"
                  value={otherOptionKey}
                  isSelected={isOther}
                  isBuilderMode={isBuilderMode}
                  theme={theme}
                  classNames={font ? { font } : undefined}
                  horizontalAlignment={
                    (horizontalAlignment as boolean) ?? false
                  }
                  onSelect={onOptionChange}
                />
              )}
              {allowOther && isOther && (
                <Input
                  type="text"
                  style={
                    theme?.primary
                      ? {
                          border: `1px solid ${theme?.primary}`,
                          boxShadow: `0px 0px 4px 0px ${theme?.primary}`,
                          backgroundColor: 'rgb(255,255,255)',
                        }
                      : {
                          border: '1px solid rgba(134, 219, 46, 1)',
                          boxShadow: '0px 0px 4px 0px rgba(134, 219, 46, 1)',
                          backgroundColor: 'rgb(255,255,255)',
                        }
                  }
                  className={cn(
                    'mr-2 flex w-full items-center border-1 px-5.5 py-3 text-lg shadow',
                    theme?.radius ? theme?.radius : '',
                    font,
                    inputClassName,
                  )}
                  onChange={onOtherChange}
                  value={internalValue}
                  placeholder="Type your answer here"
                  onFocus={handleFocusOtherInput}
                  onBlur={handleBlurOtherInput}
                />
              )}
            </TableBody>
          </Table>
        </RadioGroup>
        {isBuilderMode && isSelected && (
          <AddOptionButton
            fieldId={id!}
            label="Add"
            setOptions={setOptions}
            options={options}
            className="mt-2 rounded-[6px]"
            classNames={font ? { font } : undefined}
          />
        )}
      </>
    );
  },
);

export default MultipleChoices;
