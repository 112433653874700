import { X } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { forwardRef, useEffect, useRef, useState } from 'react';
import useConnectDiscord from '~/app/hooks/useConnectDiscord';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Button } from '~/components/ui/button';
import { cn } from '~/lib/utils';
import { DiscordUser, UseConnectDiscordParams } from '~/types/discordTypes';

import { Loader } from '../../common';

type ConnectDiscordProps = {
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: DiscordUser | null) => void;
  value?: DiscordUser;
  beforeRedirect?: () => void;
  font: string;
};

const clientId = process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID;
const redirectUri = process.env.NEXT_PUBLIC_DISCORD_REDIRECT_URI;

const ConnectDiscord = forwardRef<HTMLDivElement, ConnectDiscordProps>(
  ({ onChange, value, font, ...props }, ref) => {
    const hasCalled = useRef(false);
    const router = useRouter();
    const { theme } = useFormBuilder();
    const [profile, setProfile] = useState<DiscordUser | null>(value || null);
    const [loadingUser, setIsLoading] = useState(false);
    const isConnected = !!profile;

    const discordLoginParams: UseConnectDiscordParams = {
      clientId: clientId!,
      redirectUri: redirectUri,
      responseType: 'code',
      scopes: ['identify', 'email'],
      onSuccess: (response) => {
        console.log('Login successful:', response);
      },
      onFailure: (error) => {
        console.error('Login failed:', error);
      },
    };

    const { buildUrl, loadingDiscordData } =
      useConnectDiscord(discordLoginParams);

    useEffect(() => {
      if (hasCalled.current || isConnected) return;
      hasCalled.current = true;

      const tokenType = sessionStorage.getItem('discord_token_type');
      const accessToken = sessionStorage.getItem('discord_access_token');
      if (!accessToken || !tokenType) return;

      const getProfile = async () => {
        try {
          setIsLoading(true);
          if (!accessToken) {
            return new Response('Missing access token', { status: 400 });
          }

          if (!tokenType) {
            return new Response('Missing token type', { status: 400 });
          }

          const url = 'https://discord.com/api/users/@me';
          const res = await fetch(url, {
            headers: {
              authorization: `${tokenType} ${accessToken}`,
            },
          });

          if (!res.ok) {
            throw new Error('Failed to get user');
          }

          const data = await res.json();
          onChange?.(data);
          setProfile(data);
        } catch (error) {
          const message =
            error instanceof Error ? error.message : 'An error occurred';
          console.error(message);
          setProfile(null);
          onDisconnectDiscord();
        }
        setIsLoading(false);
      };

      getProfile();
    }, [isConnected]);

    const onConnectDiscord = () => {
      if (isConnected) {
        return;
      }
      const url = buildUrl();
      router.push(url);
    };

    const onDisconnectDiscord = () => {
      hasCalled.current = false;
      sessionStorage.removeItem('discord_access_token');
      sessionStorage.removeItem('discord_token_type');
      onChange?.(null);
      setProfile(null);
    };

    return (
      <div className="flex items-center gap-2" ref={ref} {...props}>
        <Button
          type="button"
          className={cn(
            'flex h-12 justify-start gap-3 rounded-md border-1.5 border-white/20 bg-discord px-4 py-2 text-white transition-all hover:bg-discord/90',
            {
              'w-32': loadingUser || loadingDiscordData,
              'w-fit': !loadingUser || loadingDiscordData,
              'w-max max-w-full cursor-default': isConnected,
            },
            theme?.radius ? theme?.radius : 'rounded-md',
          )}
          onClick={onConnectDiscord}
          disabled={loadingUser || loadingDiscordData}
        >
          <div className="flex aspect-square w-[30px] min-w-[30px] flex-shrink-0 items-center justify-center rounded-[10px] border border-white/20 bg-discord">
            <img src="/icons/discord.svg" />
          </div>
          {loadingUser || loadingDiscordData ? (
            <div className="flex h-[30px] flex-1 items-center justify-center rounded-[10px] bg-discord-username px-2.5">
              <Loader size={20} className="animate-spin" data-testid="loader" />
            </div>
          ) : (
            <>
              {isConnected && profile ? (
                <div
                  className="flex h-[30px] w-full items-center gap-2 overflow-clip rounded-[10px] bg-discord-username px-2.5"
                  data-testid="disconnect-discord"
                >
                  <span
                    className={cn(
                      'line-clamp-1 max-w-full whitespace-normal text-wrap text-lg font-medium',
                      font,
                    )}
                  >
                    @{profile.username}
                  </span>
                  <X
                    size={16}
                    className="ml-auto min-w-0 flex-shrink-0 cursor-pointer text-white/30"
                    onClick={onDisconnectDiscord}
                  />
                </div>
              ) : (
                <span className={cn('text-lg font-medium leading-none', font)}>
                  Connect Discord
                </span>
              )}
            </>
          )}
        </Button>
      </div>
    );
  },
);

export default ConnectDiscord;
