/* eslint-disable no-case-declarations */
import {
  GroupConditionSchema,
  IdentitySchema,
  SingleConditionSchema,
  StepSchema,
  TokenGateCondition,
} from '@formo/types';
import client from '~/lib/client';

export const singleConditionHandler = (
  values: Record<string, any>,
  condition: SingleConditionSchema,
): boolean => {
  const { field, check } = condition;
  const value = values[field];

  const fn = new Function('value', `return ${check}`);
  return !!fn(value);
};

export const groupConditionHandler = (
  values: Record<string, any>,
  condition: GroupConditionSchema,
): boolean => {
  const { operator, child } = condition;
  if (operator === 'AND') {
    return child.every((condition) => {
      if ('field' in condition) {
        return !!singleConditionHandler(values, condition);
      }
      return !!groupConditionHandler(values, condition);
    });
  }
  if (operator === 'OR') {
    return child.some((condition) => {
      if ('field' in condition) {
        return !!singleConditionHandler(values, condition);
      }
      return !!groupConditionHandler(values, condition);
    });
  }
  if (operator === 'XOR') {
    return (
      child.filter((condition) => {
        if ('field' in condition) {
          return !!singleConditionHandler(values, condition);
        }
        return !!groupConditionHandler(values, condition);
      }).length === 1
    );
  }
  return false;
};

export const handleStepConditions = (
  values: Record<string, any>,
  activeStep: StepSchema,
) => {
  const { conditions, defaultNextStep } = activeStep;

  // If there are conditions
  if (conditions) {
    for (const condition of conditions) {
      // Check if it's a single condition
      const isSingle = 'field' in condition;
      const { nextStep: nextStepId } = condition;

      // Single condition
      if (isSingle) {
        const result = singleConditionHandler(values, condition);
        // If not met, go to the next condition
        if (!result) continue;

        // If condition is met, go to the next step
        return nextStepId;
      }

      // Group condition
      const result = groupConditionHandler(values, condition);
      // If not met, go to the next condition
      if (!result) continue;

      // If condition is met, go to the next step
      return nextStepId;
    }
  }

  // If there are no conditions OR no conditions are met
  return defaultNextStep;
};

const validateNft = async (
  condition: Extract<TokenGateCondition, { type: 'nft' | 'erc20' | 'native' }>,
  currentRequirementValue: Record<string, any>,
) => {
  const { chainId, tokenAddress } = condition;
  const address = currentRequirementValue.address;

  const urlSearchParams = new URLSearchParams({
    chainId: chainId.toString(),
    address,
    contractAddress: tokenAddress,
  });

  try {
    const res = await client.get(`/api/tokens/nft/balance?${urlSearchParams}`);

    const { data } = res;
    const balance = Number(data.balance);

    return {
      value: balance,
      isValid: balance >= condition.count,
    };
  } catch (error) {
    console.error(error);
    return {
      value: 0,
      isValid: false,
    };
  }
};

const validateToken = async (
  condition: Extract<TokenGateCondition, { type: 'nft' | 'erc20' | 'native' }>,
  currentRequirementValue: Record<string, any>,
) => {
  const { chainId, tokenAddress, decimals } = condition;
  const address = currentRequirementValue.address;

  const urlSearchParams = new URLSearchParams({
    chainId: chainId.toString(),
    address,
    tokenAddress,
  });

  try {
    const res = await client.get(`/api/tokens/balance?${urlSearchParams}`);

    const { data } = res;
    const balance = decimals
      ? Number(data.tokenBalance) / 10 ** decimals
      : Number(data.tokenBalance);

    return {
      value: balance,
      isValid: balance >= condition.count,
    };
  } catch (error) {
    console.error(error);
    return {
      value: 0,
      isValid: false,
    };
  }
};

const validateNativeToken = async (
  condition: Extract<TokenGateCondition, { type: 'erc20' | 'nft' | 'native' }>,
  currentRequirementValue: Record<string, any>,
) => {
  const { chainId } = condition;
  const address = currentRequirementValue.address;

  const urlSearchParams = new URLSearchParams({
    chainId: chainId.toString(),
    address,
  });

  try {
    const res = await client.get(
      `/api/tokens/native/balance?${urlSearchParams}`,
    );

    const { data } = res;
    const balance = Number(data.tokenBalance) / 10 ** 18;

    return {
      value: balance,
      isValid: balance >= condition.count,
    };
  } catch (error) {
    console.error(error);
    return {
      value: 0,
      isValid: false,
    };
  }
};

export const validateTokenGate = async (
  condition: TokenGateCondition,
  currentRequirementValue: Record<string, any>,
  specs: IdentitySchema['fieldSpecs'] & {
    conditions: TokenGateCondition[];
  },
) => {
  switch (condition.type) {
    case 'verified':
      if (specs.type === 'wallet') {
        // Check if the wallet is verified
        return {
          value: currentRequirementValue.address,
          isValid: true,
        };
      }
      if (
        specs.type === 'discord' ||
        specs.type === 'twitter' ||
        specs.type === 'telegram' ||
        specs.type === 'farcaster'
      ) {
        return {
          value: currentRequirementValue.username,
          isValid: true,
        };
      }
      if (specs.type === 'world-id') {
        return {
          value: currentRequirementValue.verification_level,
          isValid: true,
        };
      }
      if (specs.type === 'solana') {
        return {
          value: currentRequirementValue.address,
          isValid: true,
        };
      }
      return {
        value: null,
        isValid: false,
      };
    case 'nft':
      return validateNft(condition, currentRequirementValue);
    case 'erc20':
      return validateToken(condition, currentRequirementValue);
    case 'native':
      return validateNativeToken(condition, currentRequirementValue);
    // case 'role':
    // case 'age':
    // case 'member':
    // case 'follower':
    // case 'follows':
    default:
      return {
        value: null,
        isValid: false,
      };
  }
};
