import { FC } from 'react';

import { ClosedField } from '../ClosedField';
import { FieldEnding } from '../EndingField';
import FormFieldInput from '../FormFieldInput/FormFieldInput';
import { FormFieldText } from '../FormFieldText';
import { IdentityField } from '../IdentityField';
import { ImageField } from '../ImageField';

type FieldMapper = Record<string, FC<any>>;

export const fieldMapper: FieldMapper = {
  input: FormFieldInput,
  text: FormFieldText,
  identity: IdentityField,
  image: ImageField,
  ending: FieldEnding,
  closed: ClosedField,
  // Add more form field types here
};
