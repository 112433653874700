'use client';

import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import * as React from 'react';
import { cn } from '~/lib/utils';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    theme?: Record<string, any>;
  }
>(({ className, theme, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    type="button"
    className={cn(
      'peer h-5 w-5 shrink-0 rounded-xs border data-[state=unchecked]:!border-[rgba(3,7,18,0.3)] data-[state=checked]:border-gray-100 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:border-black/10 disabled:bg-gray-50 disabled:cursor-not-allowed data-[state=checked]:!bg-green-500 data-[state=checked]:text-primary-foreground',
      props.disabled && 'data-[state=checked]:bg-gray-50',
      className,
    )}
    style={{
      border: theme?.primary
        ? `1px solid ${theme?.primary}`
        : '1px solid rgba(3, 7, 18, 0.3)',
      color: theme?.primary ? theme?.primary : 'rgba(167, 229, 103, 1)',
    }}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn(
        'flex items-center justify-center text-current',
        props.disabled ? 'text-gray-300' : 'text-white',
      )}
    >
      <Check className="h-4 w-4" strokeWidth={3} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
