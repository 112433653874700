import { SignInButton, UseSignInData, useSignIn } from '@farcaster/auth-kit';
import { useProfile } from '@farcaster/auth-kit';
import '@farcaster/auth-kit/styles.css';
import { X } from 'lucide-react';
import { forwardRef, useEffect } from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Button } from '~/components/ui/button';
import { cn } from '~/lib/utils';

import { Loader } from '../../common';

type ConnectFarcasterProps = {
  onChange?: (value: UseSignInData | null) => void;
  font: string;
};

const ConnectFarcaster = forwardRef<HTMLDivElement, ConnectFarcasterProps>(
  ({ onChange, font, ...props }, ref) => {
    const { theme } = useFormBuilder();
    const { isPolling, signOut: disconnectFarcaster } = useSignIn({
      onSuccess: ({ fid }) => console.log('Your fid:', fid),
    });
    const {
      isAuthenticated,
      profile: { username },
    } = useProfile();

    useEffect(() => {
      const signInButtons = document.querySelectorAll(
        '.fc-authkit-signin-button button',
      );

      signInButtons.forEach((signInButton) => {
        const span = signInButton.querySelector('span');
        if (span) {
          span.textContent = 'Connect Farcaster';
        }

        const svg = signInButton.querySelector('svg');
        if (svg) {
          const customDiv = document.createElement('div');
          customDiv.className =
            'flex aspect-square w-[30px] min-w-[30px] flex-shrink-0 items-center justify-center rounded-[10px] border border-white/20 bg-farcaster';

          const img = document.createElement('img');
          img.src = '/icons/farcaster.svg';

          customDiv.appendChild(img);

          svg.replaceWith(customDiv);
        }
      });
    }, []);

    const onConnectFarcaster = (profile: UseSignInData) => {
      if (profile) {
        onChange?.(profile);
      }
    };

    const onDisconnectFarcaster = () => {
      disconnectFarcaster();
      onChange?.(null);
    };

    return (
      <div className="flex items-center gap-2" ref={ref} {...props}>
        {isAuthenticated ? (
          <Button
            type="button"
            className={cn(
              'flex h-12 justify-start gap-3 rounded-md border-1.5 border-white/20 bg-farcaster px-4 py-2 text-white transition-all hover:bg-farcaster/90',
              {
                'w-32': isPolling,
                'w-fit': !(isPolling || isAuthenticated),
                'w-max max-w-full cursor-default': isAuthenticated,
              },
              theme?.radius ? theme?.radius : 'rounded-md',
            )}
            disabled={isPolling}
          >
            <div className="flex aspect-square w-[30px] min-w-[30px] flex-shrink-0 items-center justify-center rounded-[10px] border border-white/20 bg-farcaster">
              <img src="/icons/farcaster.svg" />
            </div>
            {isPolling ? (
              <div className="flex h-[30px] flex-1 items-center justify-center rounded-[10px] bg-white/20 px-2.5">
                <Loader
                  size={20}
                  className="animate-spin"
                  data-testid="loader"
                />
              </div>
            ) : (
              <>
                {username && (
                  <div
                    className="flex h-[30px] w-full items-center gap-2 overflow-clip rounded-[10px] bg-white/20 px-2.5"
                    data-testid="disconnect-farcaster"
                  >
                    <span
                      className={cn(
                        'line-clamp-1 max-w-full whitespace-normal text-wrap text-lg font-medium',
                        font,
                      )}
                    >
                      @{username}
                    </span>
                    <X
                      size={16}
                      className="ml-auto min-w-0 flex-shrink-0 cursor-pointer text-white/30"
                      onClick={onDisconnectFarcaster}
                    />
                  </div>
                )}
              </>
            )}
          </Button>
        ) : (
          <SignInButton onSuccess={(profile) => onConnectFarcaster(profile)} />
        )}
      </div>
    );
  },
);

export default ConnectFarcaster;
