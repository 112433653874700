import { ClosedSchema } from '@formo/types';
import React from 'react';

import { CloseFormIcon, Formo } from '../../common';

interface ClosedFieldProps extends ClosedSchema {
  id: string;
  label: string;
  subText?: string;
}

const ClosedField: React.FC<ClosedFieldProps> = ({
  label,
  subText,
  fieldSpecs,
  ...props
}) => {
  return (
    <div className="flex flex-col items-center" {...props}>
      {!fieldSpecs?.hideIcon && <CloseFormIcon className="mb-7" size={62} />}

      <h2 className="text-wrap break-all text-center text-[30px] font-semibold text-black focus-visible:outline-none">
        {label}
      </h2>

      {subText && (
        <h3 className="mt-1 text-wrap break-all text-center text-base text-gray-700 focus-visible:outline-none">
          {subText}
        </h3>
      )}

      {!fieldSpecs?.hideFormo && (
        <>
          <img
            src="/logo.svg"
            alt="formo icon"
            className="mt-8 h-[26px] w-[26px]"
          />
          <div className="mt-2 flex items-center gap-1.5">
            <p className="text-gray-900">Powered by</p>
            <Formo size={13} />
          </div>
        </>
      )}
    </div>
  );
};

export default ClosedField;
