import { useCallback, useEffect, useState } from 'react';
import { UseConnectDiscord } from '~/types/discordTypes';
import {
  fetchUser,
  generateUrl,
  getCallbackResponse,
  normalizeDiscordConfig,
  shouldHandleCallback,
} from '~/utils/oauth/discord';

const useConnectDiscord: UseConnectDiscord = ({
  onSuccess,
  onFailure,
  ...options
}) => {
  const [loadingDiscordData, setLoadingDiscordData] = useState<boolean>(false);
  const discordConfig = normalizeDiscordConfig(options);
  sessionStorage.setItem('discord_redirect', window.location.href);

  const handleCallback = useCallback(async () => {
    const { type, error, token, code } = getCallbackResponse();

    if (type !== null) {
      setLoadingDiscordData(true);
      const url = new URL(window.location.origin);
      url.search = '';
      url.hash = '';
      history.replaceState(null, '', url);
    }
    if (error && onFailure) {
      await onFailure(error);
    }
    if (code && onSuccess) {
      await onSuccess(code);
    }

    if (token && onSuccess) {
      const user = await fetchUser(token);
      await onSuccess({
        ...token,
        user,
      });
    }
    if (type !== null) {
      setLoadingDiscordData(false);
    }
  }, []);

  useEffect(() => {
    if (shouldHandleCallback()) {
      handleCallback().catch(console.error);
    }
  }, []);

  const buildUrl = () => generateUrl(discordConfig);
  return {
    buildUrl,
    loadingDiscordData,
  };
};

export default useConnectDiscord;
