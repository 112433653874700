'use client';

import useTokenGate from '~/app/hooks/useTokenGate';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';

const ConditionModal = () => {
  const {
    openConditionSelect,
    onCloseConditionSelect,
    availableRequirements,
    onOpenConditionDrawer,
  } = useTokenGate();

  return (
    <Dialog
      open={!!openConditionSelect}
      onOpenChange={(v) => {
        if (!v) {
          onCloseConditionSelect();
        }
      }}
    >
      <DialogContent className="w-full max-w-lg rounded-lg">
        <DialogHeader>
          <DialogTitle>Add requirement</DialogTitle>
        </DialogHeader>
        {openConditionSelect && (
          <div className="grid grid-cols-3 gap-3">
            {Object.values(
              availableRequirements[openConditionSelect].conditions,
            ).map((c) => (
              <button
                key={c.type}
                className="flex h-28 flex-col items-center justify-center gap-1 rounded-10 bg-black/5 text-base font-medium text-black-500 transition-colors hover:bg-black/10 active:bg-black/15"
                onClick={() => onOpenConditionDrawer(c)}
              >
                <img src={c.icon.src} alt={c.icon.alt} />
                {c.name}
              </button>
            ))}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ConditionModal;
