import { X } from 'lucide-react';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { SubmitHandler, useForm } from 'react-hook-form';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Button } from '~/components/ui/button';
import { DialogFooter } from '~/components/ui/dialog';
import { Input } from '~/components/ui/input';
import { cn } from '~/lib/utils';

import { ImageFile, UploadImageType, UploadStatus } from '../shared/common';

const MAX_FILE_SIZE = 1024 * 1024 * 3;
const uploadTimeout = 2000;

const UploadedImage: React.FC<{
  file: any;
  removeFile: () => void;
  uploadStatus: UploadStatus;
  progress: number;
}> = ({ file = {}, removeFile, uploadStatus, progress }) => {
  const { path, preview, error } = file;

  return (
    <div className="relative max-h-[250px] min-w-[560px] items-center rounded-md shadow-lg">
      <div
        className="absolute h-full w-full rounded-md text-end text-base font-normal text-white"
        style={{
          backgroundImage:
            'linear-gradient(0deg, rgba(3, 7, 18, 0.8) 2.35%, rgba(3, 7, 18, 0.4) 24.98%, rgba(3, 7, 18, 0.05) 83.53%)',
          backgroundSize: 'cover',
        }}
      >
        <div className="absolute bottom-3 left-3 w-full text-left">
          {path}
          <p
            className={`relative leading-[21px] ${error || file?.size > MAX_FILE_SIZE ? 'text-red-500' : 'opacity-50'}`}
          >
            {error
              ? `Failed to upload: ${error.message}`
              : file?.size > MAX_FILE_SIZE
                ? 'Failed to upload'
                : uploadStatus === UploadStatus.Uploading
                  ? `Uploading ${progress}%`
                  : 'Upload completed'}
          </p>

          {uploadStatus === UploadStatus.Uploading && (
            <div className="relative my-2.5 flex w-full max-w-[536px] items-center gap-x-3 whitespace-nowrap">
              <div
                className="flex h-1.5 w-full overflow-hidden rounded-full bg-gray-200 dark:bg-green-900"
                role="progressbar"
                aria-valuenow={1}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div
                  className="flex flex-col justify-center overflow-hidden whitespace-nowrap rounded-full bg-green-500 text-center text-xs text-white transition duration-500 dark:bg-blue-500"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
      <img
        data-testid="uploaded-image"
        src={preview}
        alt={path}
        height={250}
        className="object-center aspect-w-5 aspect-h-3 block h-full max-h-[250px] w-full rounded-md object-cover"
      />
      <Button
        variant="outline"
        type="button"
        className="z-50 absolute right-3 top-3 flex h-7 w-7 items-center justify-center rounded-full border-1.5 border-transparent bg-gray-400 px-1 py-1 transition-colors hover:border-gray-200/50 hover:bg-gray-400"
        onClick={removeFile}
      >
        <X className="h-6 w-6 fill-white text-white transition-colors" />
      </Button>
    </div>
  );
};

const ImageUploader: React.FC<{
  onSubmit: SubmitHandler<{ image: File }>;
  internalFile: ImageFile | null;
  setInternalFile: Dispatch<SetStateAction<ImageFile | null>>;
  file: ImageFile | null;
  setFile: Dispatch<SetStateAction<ImageFile | null>>;
  setSaved: Dispatch<SetStateAction<boolean>>;
  type: UploadImageType;
}> = ({ onSubmit, internalFile, setInternalFile, file, setSaved, type }) => {
  const { preview, theme, setPreview } = useFormBuilder();
  const { handleSubmit, reset } = useForm<{ image: File }>();

  const [shouldShowPreview, setShouldShowPreview] = useState<boolean>(true);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Select,
  );
  const [image, setImage] = useState<string>(theme[type]);

  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles?.length) {
        const uploadedFile = acceptedFiles[0];
        const reader = new FileReader();
        setShouldShowPreview(true);
        setInternalFile(
          Object.assign(uploadedFile!, {
            preview: URL.createObjectURL(uploadedFile!),
          }) as ImageFile,
        );
        setPreview((prev) => ({
          ...prev,
          [`${type}`]: Object.assign(uploadedFile!, {
            preview: URL.createObjectURL(uploadedFile!),
          }) as ImageFile,
        }));
        setImage(URL.createObjectURL(uploadedFile!));
        reader.onload = () => {
          setTimeout(() => {
            setUploadStatus(UploadStatus.Done);
          }, uploadTimeout);
        };
        reader.onprogress = (event) => {
          setUploadStatus(UploadStatus.Uploading);
          if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            setProgress(percentComplete);
          }
        };
        reader.readAsDataURL(uploadedFile as Blob);
      }

      if (rejectedFiles?.length) {
        const rejectedFile = rejectedFiles[0]!.file;
        setInternalFile(
          Object.assign(rejectedFile!, {
            preview: URL.createObjectURL(rejectedFile!),
            error: rejectedFiles[0]!.errors[0],
          }) as ImageFile,
        );
      }
    },
    [],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxSize: undefined,
    maxFiles: 1,
    onDrop,
  });

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    if (internalFile?.preview) {
      return () => URL.revokeObjectURL(internalFile?.preview);
    }
  }, [internalFile]);

  const removeFile = () => {
    setShouldShowPreview(false);
    setProgress(0);
    setUploadStatus(UploadStatus.Select);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div {...getRootProps({})} className="max-w-[560px] bg-neutral-50">
        {shouldShowPreview === false ||
        !(image || internalFile?.preview || file?.preview) ? (
          <div
            className={
              'flex h-full flex-col items-center rounded-md border-2 border-dashed border-gray-500 px-6 py-5'
            }
            style={
              isDragActive
                ? {
                    borderImage:
                      'repeating-linear-gradient(90deg, #bfef8e, #bfef8e 10px, transparent 10px, transparent 20px)',
                    backgroundImage:
                      'linear-gradient(0deg, rgba(248, 252, 244, 0.20) 72.88%, rgba(150, 225, 104, 0.16) 129.18%), var(--primary-10, #F8FCF4)',
                  }
                : {
                    borderImage:
                      'repeating-linear-gradient(90deg, #bfef8e, #bfef8e 10px, transparent 10px, transparent 20px)',
                  }
            }
          >
            <img src="/illustrations/file-upload.svg" alt="upload-file" />
            <p className="text-sm font-medium text-gray-800">
              Drag and drop your files here
            </p>
            <p className="mt-0.5 text-xs font-medium text-gray-600">
              Maximum size: 3MB
            </p>
            <Input
              data-testid="image-input"
              type="file"
              className="sr-only"
              {...getInputProps({ name: 'image' })}
            />
          </div>
        ) : (
          <UploadedImage
            file={internalFile || file || preview[type] || theme[type]}
            removeFile={removeFile}
            uploadStatus={uploadStatus}
            progress={progress}
          />
        )}
      </div>
      {internalFile?.preview || theme[type] || preview[type] ? (
        <DialogFooter className="relative mt-2.5 flex justify-end">
          <Button
            type="submit"
            className={cn(
              'min-w-[110px]',
              internalFile?.error
                ? 'border-1.5 border-neutral-200 bg-white focus:bg-gray-50'
                : '',
            )}
            onClick={async (e) => {
              if (internalFile?.error) {
                e.preventDefault();
                setInternalFile(null);
                reset();
              }
              setSaved(true);
            }}
          >
            {internalFile?.error ||
            (internalFile && internalFile?.size > MAX_FILE_SIZE)
              ? 'Retry'
              : 'Save'}
          </Button>
        </DialogFooter>
      ) : (
        ''
      )}
    </form>
  );
};

export default ImageUploader;
