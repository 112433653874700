import * as DialogPrimitive from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { useParams } from 'next/navigation';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ChangeImageTrigger from '~/app/_components/form/ImageField/shared/ChangeImageTrigger';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/components/ui/dialog';
import { useToast } from '~/components/ui/use-toast';
import { cn } from '~/lib/utils';
import { useSupabaseBucket } from '~/shared/utils/useSupabase';
import nanoid from '~/utils/nanoid';

import { ImageUploader } from '../ImageUploader';
import {
  ImageFile,
  UploadImageType,
  storagePath,
  supabaseUrl,
} from '../shared/common';
import { checkFileExists } from '../shared/utils';

const ChangeImageModal: React.FC<{
  fieldId?: string;
  type: UploadImageType;
  triggerText?: string;
  blobName: string;
  initialFile?: ImageFile | null;
  setImage?: Dispatch<SetStateAction<string>>;
}> = ({
  fieldId = '',
  type,
  triggerText,
  blobName,
  initialFile = null,
  // setImage,
  ...props
}) => {
  const supabase = useSupabaseBucket();
  const { formId } = useParams<{ formId: string }>();
  const { theme, preview, setPreview, setTheme, changeImage } =
    useFormBuilder();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [saved, setSaved] = useState(false);

  const [internalFile, setInternalFile] = useState<ImageFile | null>(
    initialFile,
  );
  const [file, setFile] = useState<ImageFile | null>(initialFile); // Temporary state for file inside modal
  const { toast } = useToast();

  const url = `${supabaseUrl}/${storagePath}/${blobName}`;

  useEffect(() => {
    if (!(type === UploadImageType.FormImage) && theme?.[type]) {
      setInternalFile(theme?.[type]);
      setFile(theme?.[type]); // Keep temp file in sync with external state
    }
  }, [theme, type]);

  async function uploadImage(file: ImageFile) {
    try {
      const isImageExists = await checkFileExists(blobName, formId, fieldId);
      const { data, error } = await handleImageUpload(
        file,
        isImageExists !== false,
      );

      if (data) {
        handleSuccess(data.path, file);
      } else if (error) {
        handleError(`An error occurred while uploading: ${error.message}`);
      }
    } catch (error) {
      handleError(`An error occurred: ${error}`);
    }
  }

  async function handleImageUpload(file: ImageFile, isImageExists: boolean) {
    if (type === UploadImageType.FormImage) {
      return isImageExists
        ? supabase.storage
            .from(blobName)
            .update(`${formId}/${fieldId}`, file, { upsert: true })
        : supabase.storage
            .from(blobName)
            .upload(`${formId}/${fieldId}`, file, { upsert: true });
    } else {
      return supabase.storage
        .from(blobName)
        .upload(`${formId}/${nanoid()}`, file, { upsert: true });
    }
  }

  async function handleSuccess(path: string, file: ImageFile) {
    const previewUrl = `${url}/${path}`;
    const lastModified = await checkFileExists(blobName, formId, fieldId);

    if (
      type === UploadImageType.BackgroundImage ||
      type === UploadImageType.Logo
    ) {
      setTheme((prev) => ({
        ...prev,
        [`${type}`]: { ...file, preview: `${previewUrl}?v=${lastModified}` },
      }));

      setPreview((prev) => ({
        ...prev,
        [`${type}`]: { ...file, preview: `${previewUrl}?v=${lastModified}` },
      }));
    }

    if (fieldId) {
      changeImage(
        { ...file, preview: `${previewUrl}?v=${lastModified}` },
        fieldId,
      );
    }

    setFile(
      (prev) =>
        ({ ...prev, preview: `${previewUrl}?v=${lastModified}` }) as ImageFile,
    );

    toast({
      title: 'Success',
      description: 'File uploaded successfully',
    });
  }

  function handleError(message: string) {
    toast({
      title: 'Error',
      description: message,
    });
  }

  const renderTriggerButton = () => {
    if (type === UploadImageType.FormImage) {
      return (
        <ChangeImageTrigger
          setIsDialogOpen={setIsDialogOpen}
          triggerText={triggerText ?? 'Upload'}
        />
      );
    } else if (
      (type === UploadImageType.Logo ||
        type === UploadImageType.BackgroundImage) &&
      triggerText
    ) {
      return (
        <button
          type="button"
          className={cn(
            'flex max-h-[33px] max-w-[78px] items-center justify-center rounded-lg border-1 border-white border-opacity-30 bg-white/20 px-2.5 py-1.5 text-base font-medium text-white transition-colors hover:text-primary focus:border-primary focus:bg-green-300/20 focus:text-primary',
          )}
          onClick={() => setIsDialogOpen(true)}
        >
          {triggerText}
        </button>
      );
    } else {
      return (
        <div
          className="cursor-pointer bg-inherit pr-1 hover:bg-inherit"
          onClick={() => setIsDialogOpen(true)}
        >
          <img src="/icons/upload-bg.svg" />
        </div>
      );
    }
  };

  const onSubmit = () => {
    if (internalFile) {
      uploadImage(internalFile); // Upload using temp file
    }
    setInternalFile(null);
    setIsDialogOpen(false);
  };

  const handleCloseModal = () => {
    // Reset temp file state when modal closes without saving
    if (saved) {
      setFile(preview?.[UploadImageType.CoverImage]?.preview);
    }
    setPreview({});
    setInternalFile(null);
  };

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(open) => {
        setIsDialogOpen(open);
        if (!open) handleCloseModal();
      }}
    >
      <DialogTrigger {...props}>{renderTriggerButton()}</DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="min-w-[600px] gap-3 rounded-lg"
        classNames={{ close: 'hidden' }}
      >
        <DialogHeader className="flex flex-row items-center justify-between space-y-0">
          <DialogTitle className="font-medium">Files</DialogTitle>
          <DialogPrimitive.Close className="flex items-center rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <X className="h-6 w-6" onClick={handleCloseModal} />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>
        </DialogHeader>
        <ImageUploader
          internalFile={internalFile}
          setInternalFile={setInternalFile}
          file={file}
          setFile={setFile}
          type={type}
          onSubmit={onSubmit}
          setSaved={setSaved}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ChangeImageModal;
