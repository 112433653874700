import React, { Dispatch, SetStateAction, useState } from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { cn } from '~/lib/utils';
import { hexToRGBA } from '~/utils/hexToRGBA';

type ChangeImageTriggerProps = {
  triggerText: string;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  className?: string;
} & Record<string, any>;

const ChangeImageTrigger: React.FC<ChangeImageTriggerProps> = ({
  triggerText,
  setIsDialogOpen,
  className = '',
}) => {
  const { theme } = useFormBuilder();
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const getButtonStyles = (): React.CSSProperties => {
    const defaultStyles: React.CSSProperties = {
      backgroundColor: isClicked
        ? 'rgba(191, 239, 142, 0.23)'
        : 'rgba(255, 255, 255, 0.22)',
      borderColor: isClicked
        ? 'rgba(167, 229, 103, 1)'
        : 'rgba(255, 255, 255, 0.3)',
      color: isHovered
        ? 'rgba(167, 229, 103, 1)'
        : isClicked
          ? 'rgba(167, 229, 103, 1)'
          : 'rgba(255, 255, 255, 1)',
    };

    if (theme?.primary) {
      const primaryColor = theme.primary;
      return {
        borderColor: isClicked
          ? hexToRGBA(primaryColor, 0.3)
          : 'rgba(255, 255, 255, 0.3)',
        backgroundColor: isClicked
          ? hexToRGBA(primaryColor, 0.23)
          : 'rgba(255, 255, 255, 0.22)',
        color: isHovered
          ? hexToRGBA(primaryColor, 1)
          : isClicked
            ? hexToRGBA(primaryColor, 1)
            : 'rgba(255, 255, 255, 1)',
      };
    }

    return {
      ...defaultStyles,
    };
  };

  return (
    <button
      type="button"
      className={cn(
        'flex max-h-fit max-w-fit items-center justify-center rounded-lg border-1 px-2.5 py-1.5 font-medium transition-colors',
        theme?.font,
        className,
      )}
      onClick={() => setIsDialogOpen(true)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => {
        setIsHovered(false);
        setIsClicked(true);
      }}
      onMouseUp={() => setIsClicked(false)}
      style={getButtonStyles()}
    >
      {triggerText}
    </button>
  );
};

export default ChangeImageTrigger;
