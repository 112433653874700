import { forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input, InputProps } from '~/components/ui/input';

type ShortTextInputProps = InputProps;

const ShortTextInput = forwardRef<HTMLInputElement, ShortTextInputProps>(
  ({ ...props }, ref) => {
    const { setError, clearErrors, formState } = useFormContext();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { minLength, maxLength, ...shortTextProps } = props;
    const [value, setValue] = useState(shortTextProps.value || '');

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const val = e.target.value;
      setValue(val);

      shortTextProps.onChange?.(e);

      if (
        typeof minLength === 'number' &&
        typeof maxLength === 'number' &&
        formState.errors[shortTextProps.name!]
      ) {
        if (
          !val.length ||
          (val.length > minLength && val.length <= maxLength)
        ) {
          clearErrors(shortTextProps.name!);
        }
        return;
      }

      if (typeof minLength === 'number' && val.length < minLength) {
        setError(shortTextProps.name!, {
          type: 'minLength',
          message: `Field must be at least ${minLength} characters long`,
        });
      }

      if (typeof maxLength === 'number' && val.length > maxLength) {
        setError(shortTextProps.name!, {
          type: 'maxLength',
          message: `Field must be no more than ${maxLength} characters long`,
        });
      }
    };

    return (
      <Input
        {...shortTextProps}
        ref={ref}
        type="text"
        autoFocus={false}
        value={value}
        onChange={onChange}
      />
    );
  },
);

export default ShortTextInput;
