'use client';

import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { CheckCircle, ChevronDown, Pencil } from 'lucide-react';
import { HTMLAttributes, forwardRef, useEffect, useState } from 'react';
import nacl from 'tweetnacl';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Button } from '~/components/ui/button';
import { useToast } from '~/components/ui/use-toast';
import { cn } from '~/lib/utils';
import nanoid from '~/utils/nanoid';
import truncateAddress from '~/utils/truncateTokenAddress';

import { Loader, Wallet } from '../../common';
import './ConnectSolana.css';

export type SolanaWalletValue = {
  address: string;
  signature: string;
  message: string;
} | null;

export type ConnectSolanaProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: SolanaWalletValue) => void;
  value?: SolanaWalletValue;
  disabled?: boolean;
  className?: string;
  config?: {
    message?: string;
  };
  font: string;
};

const ConnectSolana = forwardRef<HTMLDivElement, ConnectSolanaProps>(
  ({ disabled, className, onChange, value, config, ...props }, ref) => {
    const { theme, form } = useFormBuilder();

    const wallet = useWallet();

    const [isSigning, setIsSigning] = useState(false);

    const { toast } = useToast();

    const signMessage = async () => {
      try {
        if (
          !wallet.signMessage ||
          !wallet.connected ||
          !wallet.publicKey ||
          !form
        )
          return;
        setIsSigning(true);

        const message = `app.formo.so wants you to sign in with your account:\n${wallet.publicKey.toString()}\n\nSigning this message allows you to verify your ownership of this wallet. Signing is a safe, gas-less transaction that does not in any way give Formo permission to perform any transactions with your wallet.\n\nURI: ${location.href}\nVersion: 1\nChain ID: 900\nNonce: ${nanoid()}\nIssued At: ${new Date().toISOString()}\nForm ID: ${form?.id}\n${config?.message || ''}`;

        const signature = await wallet.signMessage?.(
          new TextEncoder().encode(message),
        );

        const decodedSignature = Buffer.from(signature).toString('base64');

        onChange?.({
          address: wallet.publicKey.toString(),
          signature: decodedSignature,
          message,
        });
      } catch (error: any) {
        toast({
          title: 'Error',
          description: error?.message || 'An error occurred',
        });
      }
      setIsSigning(false);
    };

    useEffect(() => {
      if (!wallet.publicKey) {
        onChange?.(null);
        return;
      }
      if (wallet.publicKey.toString() !== value?.address) {
        onChange?.(null);
        return;
      }
      if (value) {
        const encodedSignature = Buffer.from(value.signature, 'base64');
        const verified = nacl.sign.detached.verify(
          new TextEncoder().encode(value.message),
          encodedSignature,
          wallet.publicKey.toBuffer(),
        );
        if (!verified) {
          onChange?.(null);
        }
      }
    }, [wallet.publicKey, value]);

    return (
      <div
        ref={ref}
        className={cn('flex w-max max-w-full items-center gap-5', className)}
        {...props}
      >
        <WalletMultiButton
          disabled={disabled}
          className={cn(theme?.radius ? theme.radius : 'rounded-md')}
        >
          <div className="flex h-[30px] w-[30px] flex-shrink-0 items-center justify-center overflow-clip rounded-10 border border-black/25 bg-black/10 text-black shadow-[3px_2px_10px_0px_rgba(3,7,18,0.1)]">
            <Wallet />
          </div>
          {wallet.connecting ? (
            <span className="text-black font-medium text-lg">
              Connecting...
            </span>
          ) : wallet.connected && wallet.publicKey ? (
            <span className="text-medium flex h-[30px] min-w-0 flex-1 items-center overflow-clip rounded-10 bg-[rgba(52,52,52,0.1)] px-2.5 text-lg text-black/80 font-medium">
              {truncateAddress(wallet.publicKey.toString())}
              <ChevronDown size={20} className="ml-2" />
            </span>
          ) : (
            <span className="text-black font-medium text-lg">
              Connect Wallet
            </span>
          )}
        </WalletMultiButton>
        {wallet.connected && !value ? (
          <Button
            type="button"
            className={cn(
              'h-10 min-w-max flex-shrink-0 gap-2.5 rounded-full bg-black px-4 py-2 text-lg text-white shadow-[1px_1px_4px_0px_rgba(0,0,0,0.08)] hover:bg-black/80',
              isSigning &&
                'border border-black/5 bg-black/10 disabled:opacity-100',
            )}
            disabled={disabled || isSigning}
            onClick={signMessage}
          >
            {isSigning ? (
              <>
                <Loader
                  size={20}
                  className="animate-spin text-[rgba(106,106,111,1)]"
                />
                <span className="leading-none text-[rgba(106,106,111,1)]">
                  Verifying
                </span>
              </>
            ) : (
              <>
                <Pencil size={20} />
                <span className="leading-none">Sign to verify</span>
              </>
            )}
          </Button>
        ) : value ? (
          <Button
            className={cn(
              theme?.radius || 'rounded-full',
              'border border-black/5 bg-white/50 hover:bg-white/50 flex flex-shrink-0 min-w-max h-10 gap-2.5 px-4 py-2 text-lg shadow-[1px_1px_4px_0px_rgba(0,0,0,0.08)] disabled:opacity-100',
            )}
            disabled
          >
            <CheckCircle size={20} className="text-teal-500" />
            <span className="leading-none text-teal-500">Verified</span>
          </Button>
        ) : null}
      </div>
    );
  },
);

export default ConnectSolana;
