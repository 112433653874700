'use client';

import { InputSchema } from '@formo/types';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputMapper } from '~/app/_components/form/InputMapper';
import { inputMapper } from '~/app/_components/form/InputMapper/constants';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Separator } from '~/components/ui/separator';
import { ChoiceType, RatingType } from '~/constants/fields';
import { cn } from '~/lib/utils';
import { hexToRGBA } from '~/utils/hexToRGBA';

type FormFieldInputProps = {
  id: string;
  label: string;
  subText?: string;
  type?: string;
  fieldSpecs: InputSchema['fieldSpecs'] & {
    minLength?: number;
    maxLength?: number;
  };
  theme?: Record<string, any>;
  classNames?: { font?: string };
  isBuilderMode?: boolean;
  hideLabel?: boolean;
};

const FormFieldInput: FC<FormFieldInputProps> = ({
  id,
  label,
  subText,
  fieldSpecs,
  theme = {},
  isBuilderMode = false,
  classNames: { font } = {},
  hideLabel = false,
  ...props
}) => {
  const { control } = useFormContext();
  const { changeQuestion } = useFormBuilder();
  const [isCustomLabelFocused, setIsCustomLabelFocused] = useState(false);

  const additionalValidation = inputMapper[fieldSpecs?.type]?.({
    fieldSpecs,
  }).validation;

  const handleCustomLabelFocused = () => {
    setIsCustomLabelFocused(true);
  };

  const handleCustomLabelBlurred = (e: React.FocusEvent<HTMLSpanElement>) => {
    const newLabel = e.currentTarget.textContent;
    if (!newLabel) {
      e.currentTarget.textContent = 'Enter your question';
      changeQuestion(id, 'Enter your question');
    } else {
      changeQuestion(id, newLabel);
    }
    setIsCustomLabelFocused(false);
  };

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (
        fieldSpecs?.type === ChoiceType.RadioGroup ||
        fieldSpecs?.type === RatingType.Rating
      )
        return;

      event.target.style.border = theme?.primary
        ? `1.5px solid ${theme.primary}`
        : '1.5px solid rgba(134, 219, 46, 0.8)';
      event.target.style.boxShadow = theme?.primary
        ? `0px 0px 4px 0px ${theme.primary}`
        : '0px 0px 4px 0px rgba(134, 219, 46, 1)';
      event.target.style.backgroundColor = 'rgba(255, 255, 255, 1)';
    },
    [fieldSpecs, theme],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setIsCustomLabelFocused(false);
      if (
        fieldSpecs?.type === ChoiceType.RadioGroup ||
        fieldSpecs?.type === RatingType.Rating
      )
        return;

      event.target.style.border = '1.5px solid rgba(0, 0, 0, 0.1)';
      event.target.style.boxShadow = 'none';
    },
    [fieldSpecs],
  );

  const renderAsterisk = () =>
    fieldSpecs?.required && (
      <span className={cn('px-1 text-lg text-red-500', font)}>*</span>
    );

  const renderLabel = (isBuilder: boolean) => {
    //Disable Ctrl+Z
    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        // Check if Ctrl (or Cmd on Mac) and Z are pressed
        if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
          event.preventDefault();
          console.log('Undo (Ctrl+Z) is disabled');
        }
      };
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    return (
      <div
        className="h-max w-full max-w-full"
        style={
          isCustomLabelFocused && isBuilder
            ? {
                backgroundImage: theme?.primary
                  ? `linear-gradient(0deg, ${hexToRGBA(theme.primary, 0.03)}, ${hexToRGBA(
                      theme.primary,
                      0.03,
                    )}),linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05))`
                  : 'linear-gradient(0deg, rgba(134, 219, 46, 0.03), rgba(134, 219, 46, 0.03)),linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05))',
                cursor: 'pointer',
              }
            : { cursor: 'auto' }
        }
      >
        <div>
          <span
            contentEditable={isBuilder}
            suppressContentEditableWarning
            className={cn(
              'h-full w-max whitespace-pre-wrap rounded-none border-0 px-0.5 text-lg font-medium leading-7 placeholder-gray-400 outline-none focus-visible:ring-0 focus-visible:ring-offset-0',
              font,
            )}
            data-testid="custom-label-input"
            tabIndex={1}
            autoFocus
            onFocus={handleCustomLabelFocused}
            onBlur={handleCustomLabelBlurred}
          >
            {label}
          </span>
          <span>{renderAsterisk()}</span>
        </div>
        {isCustomLabelFocused && isBuilder ? (
          <Separator
            className="h-[2px] w-full"
            style={{
              backgroundColor: theme?.primary ?? 'rgba(134, 219, 46, 0.8)',
            }}
          />
        ) : (
          <div className="h-[2px]"></div>
        )}
      </div>
    );
  };

  //Disable Ctrl+Z
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if Ctrl (or Cmd on Mac) and Z are pressed
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        event.preventDefault();
        console.log('Undo (Ctrl+Z) is disabled');
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <FormField
      control={control}
      name={id}
      rules={{
        required: {
          value: fieldSpecs?.required,
          message: `${label} is required`,
        },
        minLength: {
          value: fieldSpecs?.minLength as number,
          message: `Field must be at least ${fieldSpecs?.minLength} characters long`,
        },
        maxLength: {
          value: fieldSpecs?.maxLength as number,
          message: `Field must be no more than ${
            fieldSpecs?.maxLength
          } characters long`,
        },
        ...additionalValidation,
      }}
      render={({ field }) => (
        <FormItem>
          {!hideLabel && (
            <FormLabel
              className={cn('flex w-full items-center')}
              style={{
                cursor: isBuilderMode ? 'pointer' : 'auto',
                color: theme?.questions ?? '#000000',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
              }}
            >
              {renderLabel(isBuilderMode)}
            </FormLabel>
          )}
          {subText && (
            <FormDescription
              style={{
                color: theme?.questions
                  ? hexToRGBA(theme.questions, 0.7)
                  : 'rgba(0, 0, 0, 0.3)',
              }}
              className={cn('text-base', font)}
            >
              {subText}
            </FormDescription>
          )}
          <FormControl>
            <InputMapper
              id={id}
              type={fieldSpecs?.type}
              controlledProps={field}
              fieldSpecs={fieldSpecs}
              isBuilderMode={isBuilderMode}
              className={cn(
                'relative mt-1.5 text-lg outline-none placeholder:font-normal placeholder:text-black/30 focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0',
                fieldSpecs.type !== 'radio-group' &&
                  fieldSpecs.type !== 'checkbox-group' &&
                  fieldSpecs.type !== 'rating' &&
                  'shadow',
                font,
              )}
              classNames={{ font: theme?.font }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              theme={theme}
              {...props}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default FormFieldInput;
