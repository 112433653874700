import { EndingSchema } from '@formo/types';
import React, { useRef, useState } from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { cn } from '~/lib/utils';
import { hexToRGBA } from '~/utils/hexToRGBA';

import { EndingCheck, Formo } from '../../common';

const DEFAULT_ENDING_TITLE = 'Enter your ending title';
const DEFAULT_SUBTEXT = 'Description';

type FieldEndingProps = {
  id: string;
  label: string;
  subText?: string;
  isBuilderMode?: boolean;
  theme?: Record<string, any>;
  classNames?: { font?: string };
} & EndingSchema;

const FieldEnding: React.FC<FieldEndingProps> = ({
  id,
  label,
  subText = '',
  fieldSpecs,
  classNames: { font } = {},
  isBuilderMode = false,
  theme = {},
  ...props
}) => {
  const { changeQuestion, changeSubText } = useFormBuilder();
  const [focusHeader, setFocusHeader] = useState(false);
  const [focusSubtext, setFocusSubtext] = useState(false);

  const headerRef = useRef<HTMLHeadingElement | null>(null);
  const subTextRef = useRef<HTMLHeadingElement | null>(null);

  const handleBlurLabel = (e: React.FormEvent<HTMLHeadingElement>) => {
    const newLabel = e.currentTarget.textContent;
    setFocusHeader(false);
    if (!newLabel) {
      e.currentTarget.textContent = DEFAULT_ENDING_TITLE;
      changeQuestion(id, '');
    } else {
      changeQuestion(id, newLabel);
    }
  };

  const handleBlurSubtext = (e: React.FormEvent<HTMLHeadingElement>) => {
    const newSubtext = e.currentTarget.textContent;
    setFocusSubtext(false);
    if (!newSubtext) {
      e.currentTarget.textContent = DEFAULT_SUBTEXT;
      changeSubText(id, '');
    } else {
      changeSubText(id, newSubtext);
    }
  };

  return (
    <div className="flex flex-col items-center" {...props}>
      {!fieldSpecs?.hideIcon && (
        <EndingCheck
          className="mb-7"
          size={62}
          color={theme?.primary || 'rgba(134, 219, 46, 0.8)'}
        />
      )}

      {focusHeader ? (
        <h2
          ref={headerRef}
          className={cn(
            'text-wrap break-word border-b-2 text-center text-[30px] font-semibold text-black focus-visible:outline-none ',
            font,
          )}
          style={{
            borderColor: theme?.primary || 'rgba(134, 219, 46, 0.8)',
            color: theme?.questions
              ? hexToRGBA(
                  theme?.questions,
                  !label || label === DEFAULT_ENDING_TITLE ? 0.3 : 1,
                )
              : `rgba(0, 0, 0, ${!label || label === DEFAULT_ENDING_TITLE ? '0.3 ' : '1'})`,
          }}
          contentEditable
          onBlur={handleBlurLabel}
        >
          {isBuilderMode && !label ? DEFAULT_ENDING_TITLE : label}
        </h2>
      ) : (
        <h2
          className={cn(
            'text-wrap break-word text-center text-[30px] font-semibold text-black focus-visible:outline-none',
            font,
          )}
          style={{
            color: theme?.questions
              ? hexToRGBA(
                  theme?.questions,
                  !label || label === DEFAULT_ENDING_TITLE ? 0.3 : 1,
                )
              : `rgba(0, 0, 0, ${!label || label === DEFAULT_ENDING_TITLE ? '0.3' : '1'})`,
          }}
          onClick={() => {
            if (!isBuilderMode) return;
            setFocusHeader(true);
            setFocusSubtext(false);
            setTimeout(() => {
              headerRef.current?.focus();
            }, 0);
          }}
        >
          {isBuilderMode && !label ? DEFAULT_ENDING_TITLE : label}
        </h2>
      )}
      <>
        {focusSubtext ? (
          <h3
            ref={subTextRef}
            className={cn(
              'mt-2 text-wrap break-word border-b-2 text-center text-base text-gray-700 focus-visible:outline-none',
              font,
            )}
            style={{
              borderColor: theme?.primary || 'rgba(134, 219, 46, 0.8)',
              color: theme?.questions
                ? hexToRGBA(
                    theme?.questions,
                    !subText || subText === DEFAULT_SUBTEXT ? 0.3 : 1,
                  )
                : `rgba(160, 160, 163, ${!subText || subText === DEFAULT_SUBTEXT ? '0.3' : '1'})`,
            }}
            contentEditable
            onBlur={handleBlurSubtext}
          >
            {isBuilderMode && !subText ? DEFAULT_SUBTEXT : subText}
          </h3>
        ) : (
          <h3
            className={cn(
              'mt-1 text-wrap break-word text-center text-base text-gray-700 focus-visible:outline-none',
              font,
            )}
            style={{
              borderColor: theme?.primary || 'rgba(134, 219, 46, 0.8',
              color: theme?.questions
                ? hexToRGBA(
                    theme?.questions,
                    !subText || subText === DEFAULT_SUBTEXT ? 0.3 : 1,
                  )
                : `rgba(160, 160, 163, ${!subText || subText === DEFAULT_SUBTEXT ? '0.3' : '1'})`,
            }}
            onClick={() => {
              if (!isBuilderMode) return;
              setFocusSubtext(true);
              setFocusHeader(false);
              setTimeout(() => {
                subTextRef.current?.focus();
              }, 0);
            }}
          >
            {isBuilderMode && !subText ? DEFAULT_SUBTEXT : subText}
          </h3>
        )}
      </>
      {!fieldSpecs?.hideFormo && (
        <>
          <img
            src="/logo.svg"
            alt="formo icon"
            className="mt-8 h-[26px] w-[26px]"
          />
          <div className="mt-2 flex items-center gap-1.5">
            <p className="text-gray-900">Powered by</p>
            <Formo size={13} />
          </div>
        </>
      )}
    </div>
  );
};

export default FieldEnding;
