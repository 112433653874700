export const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
export const storagePath = process.env.NEXT_PUBLIC_SUPABASE_STORAGE_PATH;
export const storageName = 'files';

export type FileType = File & {
  preview: string;
  path?: string;
  error?: string;
};

export enum UploadStatus {
  Select = 'select',
  Uploading = 'uploading',
  Done = 'done',
}

export enum UploadFileType {
  FormFile = 'formFile',
  BackgroundFile = 'backgroundFile',
  Logo = 'logo',
}
