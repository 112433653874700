import { FC, HTMLAttributes } from 'react';

type FileUploadSpinnerProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const FileUploadSpinner: FC<FileUploadSpinnerProps> = ({
  size = 42,
  color = '#A7E567',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 42 42"
      fill="none"
      className="ease-in-out animate-spin"
      {...props}
    >
      <path
        d="M36.751 20.9969C36.7508 24.3229 35.6977 27.5635 33.7426 30.2542C31.7875 32.945 29.0308 34.9477 25.8675 35.9754C22.7042 37.0031 19.2968 37.003 16.1336 35.9751C12.9704 34.9472 10.2138 32.9444 8.25881 30.2535C6.30387 27.5627 5.25095 24.322 5.25098 20.996C5.251 17.67 6.30396 14.4293 8.25895 11.7385C10.2139 9.04767 12.9706 7.04483 16.1338 6.017C19.297 4.98917 22.7045 4.98912 25.8677 6.01687"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileUploadSpinner;
