import { useSupabaseBucket } from '~/shared/utils/useSupabase';

export async function getStorageImage(storageName: string, filePath: string) {
  try {
    const supabase = useSupabaseBucket();
    const {
      data: { publicUrl },
    } = supabase.storage.from(storageName).getPublicUrl(filePath, {
      transform: {
        width: 500,
        height: 600,
      },
    });
    console.log('supabase data', publicUrl);
    return publicUrl;
  } catch (error) {
    console.error('Error fetching storage image:', error);
    return null;
  }
}

export async function checkFileExists(
  storageName: string,
  folderPath: string,
  filePath: string,
) {
  const supabase = useSupabaseBucket();
  const { data, error } = await supabase.storage
    .from(storageName)
    .list(folderPath);

  if (error) {
    console.error(error);
    return false;
  }

  if (data) {
    const files = data.filter((item) => item.name === filePath);

    return files[0]?.metadata?.lastModified
      ? new Date(files[0]?.metadata?.lastModified).getTime()
      : false;
  }

  return false;
}
