import { IdentitySchema } from '@formo/types';
import { FC } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { cn } from '~/lib/utils';

import { identityMapper } from './constants';

type IdentityMapperProps = {
  id: string;
  type: string;
  controlledProps: ControllerRenderProps;
  fieldSpecs: IdentitySchema['fieldSpecs'];
  className?: string;
  beforeRedirect?: () => void;
  theme?: Record<string, any>;
};

/**
 * Used to map the identity field type to the correct component
 * @example <IdentityMapper id="test-id" type="wallet" />
 * // render <ConnectWallet />
 * @returns The component to render
 */
const IdentityMapper: FC<IdentityMapperProps> = ({
  controlledProps,
  fieldSpecs,
  id,
  type,
  className,
  theme = {},
  ...props
}) => {
  const Identity = identityMapper[type];

  if (!Identity) {
    return <div>Missing Component for type: {type}</div>;
  }

  const { Component } = Identity({ fieldSpecs });

  return (
    <Component
      {...controlledProps}
      {...theme}
      {...fieldSpecs}
      {...props}
      id={id}
      className={cn(className)}
    />
  );
};

export default IdentityMapper;
