import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Textarea, TextareaProps } from '~/components/ui/textarea';
import { cn } from '~/lib/utils';

type LongTextInputProps = TextareaProps;

const LongTextInput = forwardRef<HTMLTextAreaElement, LongTextInputProps>(
  ({ className, style, ...props }, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { minLength, maxLength, ...textareaProps } = props;
    const { setError, clearErrors, formState } = useFormContext();
    const [value, setValue] = useState(textareaProps.value || '');
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    useLayoutEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, [textareaProps.value, textareaProps.rows]); // Adjust height when value changes

    const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
      const val = e.target.value;
      setValue(val);
      textareaProps.onChange?.(e);

      if (
        typeof minLength === 'number' &&
        typeof maxLength === 'number' &&
        formState.errors[textareaProps.name!]
      ) {
        if (
          !val.length ||
          (val.length > minLength && val.length <= maxLength)
        ) {
          clearErrors(textareaProps.name!);
        }
        return;
      }

      if (typeof minLength === 'number' && val.length < minLength) {
        setError(textareaProps.name!, {
          type: 'minLength',
          message: `Field must be at least ${minLength} characters long`,
        });
      }

      if (typeof maxLength === 'number' && val.length > maxLength) {
        setError(textareaProps.name!, {
          type: 'maxLength',
          message: `Field must be no more than ${maxLength} characters long`,
        });
      }
    };

    return (
      <Textarea
        {...textareaProps}
        ref={(element) => {
          // Set the current property of the ref using functional update
          if (ref) {
            if (typeof ref === 'function') {
              ref(element);
            } else {
              ref.current = element;
            }
          }
          textareaRef.current = element;
        }}
        className={cn(className, 'min-h-10 w-full')}
        style={{
          resize: 'none',
          overflow: 'hidden',
          ...style,
        }} // Prevent manual resizing
        autoFocus={false}
        value={value}
        onChange={onChange}
      />
    );
  },
);

export default LongTextInput;
