'use client';

import { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { InputProps } from '~/components/ui/input';
import { useToast } from '~/components/ui/use-toast';
import { useSupabaseBucket } from '~/shared/utils/useSupabase';

import { FileUploader } from './FileUploader';
import {
  FileType,
  storageName,
  storagePath,
  supabaseUrl,
} from './shared/common';
import { checkFileExists } from './shared/utils';

type FileFieldProps = {
  id?: string;
  className?: string;
  isBuilderMode?: boolean;
  classNames?: { font?: string };
} & InputProps;

const FileField = forwardRef<HTMLInputElement, FileFieldProps>(
  (
    {
      id: fieldId = '',
      className,
      classNames: { font } = {},
      isBuilderMode = false,
    },
    ref,
  ) => {
    const { register, setValue } = useFormContext();
    const supabase = useSupabaseBucket();
    const { form, setPreview } = useFormBuilder();
    const { toast } = useToast();

    const [innerFile, setInnerFile] = useState<FileType | null>(null);

    const url = `${supabaseUrl}/${storagePath}/${storageName}`;

    async function uploadFile(file: FileType) {
      try {
        if (form) {
          const isFileExists = await checkFileExists(
            storageName,
            form.id,
            fieldId,
          );
          const response = await handleFileUpload(
            file,
            !(isFileExists === false),
          );

          if (response?.data) {
            handleSuccess(response.data.path, file);
          } else if (response?.error) {
            handleError(
              `An error occurred while uploading: ${response.error.message}`,
            );
          }
        }
      } catch (error) {
        handleError(`An error occurred: ${error}`);
      }
    }

    async function handleFileUpload(file: FileType, isFileExists: boolean) {
      if (form) {
        return isFileExists
          ? supabase.storage
              .from(storageName)
              .update(`${form.id}/${fieldId}`, file, { upsert: true })
          : supabase.storage
              .from(storageName)
              .upload(`${form.id}/${fieldId}`, file, { upsert: true });
      }
    }

    async function handleSuccess(path: string, file: FileType) {
      if (form) {
        const previewUrl = `${url}/${path}`;

        setInnerFile((prev) =>
          prev ? { ...prev, preview: previewUrl, size: file?.size } : null,
        );

        toast({
          title: 'Success',
          description: 'File uploaded successfully',
        });
      }
    }

    function handleError(message: string) {
      toast({
        title: 'Error',
        description: message,
      });
    }

    function onSubmit({ file }: { file: FileType }) {
      // Do not submit file in builder screen
      if (file && !isBuilderMode) {
        uploadFile(file);
      }
      setPreview({});
    }

    useEffect(() => {
      // Revoke the data uris to avoid memory leaks
      if (innerFile?.preview) {
        return () => URL.revokeObjectURL(innerFile?.preview);
      }
    }, [innerFile]);

    return (
      <FileUploader
        file={innerFile}
        setFile={setInnerFile}
        setValue={setValue}
        fieldId={fieldId}
        onSubmit={onSubmit}
        register={register}
        className={className}
        font={font}
        ref={ref}
      />
    );
  },
);

export default FileField;
