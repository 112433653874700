import {
  IdentitySchema,
  StepSchema,
  TokenGateCondition,
  TokenGateSettingRequirement,
} from '@formo/types';
import { FC } from 'react';
import { availableRequirements } from '~/constants/token-gate';

import Condition from '../../settings/TokenGate/Condition';
import { FieldMapper } from '../FieldMapper';

export enum TokenGateStatus {
  LOADING = 'loading',
  VALID = 'valid',
  INVALID = 'invalid',
}

interface TokenGateStepProps {
  requirements: TokenGateSettingRequirement;
  tokenGatePage: StepSchema;
  availableRequirements: typeof availableRequirements;
  status: Map<keyof TokenGateSettingRequirement, TokenGateStatus[]>;
}

const TokenGateStep: FC<TokenGateStepProps> = ({
  requirements,
  tokenGatePage,
  availableRequirements,
  status,
}) => {
  const requirementOrder = [
    'wallet',
    'solana',
    'discord',
    'twitter',
    'telegram',
    'farcaster',
    'world-id',
  ] as const;

  const sortedFields = [...tokenGatePage.fields].sort((a, b) => {
    const typeA = requirementOrder.indexOf(
      a.fieldSpecs.type as (typeof requirementOrder)[number],
    );
    const typeB = requirementOrder.indexOf(
      b.fieldSpecs.type as (typeof requirementOrder)[number],
    );
    return typeA - typeB;
  });

  return (
    <div className="w-full">
      <div className="space-y-6 py-3">
        {sortedFields.map((field) => {
          const specs = field.fieldSpecs as IdentitySchema['fieldSpecs'];
          const requirement = specs.type ? requirements[specs.type] : [];

          return (
            <div key={field.id} className="m-0 py-0">
              <FieldMapper
                {...field}
                hideLabel
                id={`token-gate-${specs.type}`}
              />
              <div className="pt-3">
                {requirement?.map((req, index) => (
                  <Condition
                    key={`${field.id}-${index}`}
                    index={index}
                    parentType={specs.type}
                    availableConditions={
                      availableRequirements[specs.type]?.conditions || {}
                    }
                    {...(req as TokenGateCondition)}
                    isResponder
                    status={status?.get(specs.type)?.[index]}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TokenGateStep;
