import { BaseFieldSchema } from '@formo/types';
import { FC } from 'react';

import { fieldMapper } from './constants';

type FieldMapperProps = BaseFieldSchema & {
  hideLabel?: boolean;
  theme?: Record<string, any>;
};

const FieldMapper: FC<FieldMapperProps> = ({
  fieldType,
  hideLabel,
  ...props
}) => {
  const Component = fieldMapper[fieldType];

  if (!Component) {
    return <div>Missing Field for fieldType: {fieldType}</div>;
  }

  return (
    <Component
      {...props}
      hideLabel={hideLabel}
      classNames={{ font: props.theme?.font }}
    />
  );
};

export default FieldMapper;
