import { TokenGateCondition, TokenGateSettingRequirement } from '@formo/types';
import TokenConditionForm from '~/app/_components/settings/TokenGate/tokens/TokenConditionForm';

export type AvailableCondition<
  T extends
    keyof TokenGateSettingRequirement = keyof TokenGateSettingRequirement,
> = {
  name: string;
  type: TokenGateCondition['type'];
  icon: {
    src: string;
    alt: string;
  };
  form: React.FC;
  requirementType: T;
};

export type AvailableConditionRecord = Partial<
  Record<
    Exclude<
      NonNullable<TokenGateSettingRequirement['wallet']>[number]['type'],
      'verified'
    >,
    AvailableCondition<'wallet'>
  >
>;

export type AvailableRequirement<
  T extends
    keyof TokenGateSettingRequirement = keyof TokenGateSettingRequirement,
> = {
  name: string;
  type: T;
  icon: {
    src: string;
    alt: string;
  };
  conditions: AvailableConditionRecord;
};

const availableWalletRequirements: AvailableRequirement<'wallet'> = {
  name: 'Connect Wallet (EVM)',
  type: 'wallet',
  icon: {
    src: '/icons/connect-wallet-field.svg',
    alt: 'connect wallet requirement icon',
  },
  conditions: {
    nft: {
      name: 'NFT',
      type: 'nft',
      icon: {
        src: '/icons/condition-nft.svg',
        alt: 'nft condition icon',
      },
      form: TokenConditionForm,
      requirementType: 'wallet',
    },
    erc20: {
      name: 'ERC20 Token',
      type: 'erc20',
      icon: {
        src: '/icons/condition-erc20-token.svg',
        alt: 'token condition icon',
      },
      form: TokenConditionForm,
      requirementType: 'wallet',
    },
    native: {
      name: 'Native Token',
      type: 'native',
      icon: {
        src: '/icons/condition-native-token.svg',
        alt: 'token condition icon',
      },
      form: TokenConditionForm,
      requirementType: 'wallet',
    },
  },
};

const availableDiscordRequirements: AvailableRequirement<'discord'> = {
  name: 'Connect Discord',
  type: 'discord',
  icon: {
    src: '/icons/connect-discord-field.svg',
    alt: 'connect discord requirement icon',
  },
  conditions: {},
};

const availableTwitterRequirements: AvailableRequirement<'twitter'> = {
  name: 'Connect Twitter',
  type: 'twitter',
  icon: {
    src: '/icons/connect-x-field.svg',
    alt: 'connect twitter requirement icon',
  },
  conditions: {},
};

const availableFarcasterRequirements: AvailableRequirement<'farcaster'> = {
  name: 'Connect Farcaster',
  type: 'farcaster',
  icon: {
    src: '/icons/connect-farcaster-field.svg',
    alt: 'connect farcaster requirement icon',
  },
  conditions: {},
};

const availableTelegramRequirements: AvailableRequirement<'telegram'> = {
  name: 'Connect Telegram',
  type: 'telegram',
  icon: {
    src: '/icons/connect-telegram-field.svg',
    alt: 'connect telegram requirement icon',
  },
  conditions: {},
};

const availableWorldIdRequirements: AvailableRequirement<'world-id'> = {
  name: 'Connect World ID',
  type: 'world-id',
  icon: {
    src: '/icons/connect-world-id-field.svg',
    alt: 'connect world id requirement icon',
  },
  conditions: {},
};

const availableSolanaRequirements: AvailableRequirement<'solana'> = {
  name: 'Connect Wallet (Solana)',
  type: 'solana',
  icon: {
    src: '/icons/connect-wallet-field.svg',
    alt: 'connect solana requirement icon',
  },
  conditions: {},
};

export const availableRequirements = {
  wallet: availableWalletRequirements,
  solana: availableSolanaRequirements,
  discord: availableDiscordRequirements,
  twitter: availableTwitterRequirements,
  telegram: availableTelegramRequirements,
  farcaster: availableFarcasterRequirements,
  'world-id': availableWorldIdRequirements,
};

export const chains = {
  1: {
    id: 1,
    name: 'Ethereum',
    icon: {
      src: '/icons/eth-icon.svg',
      alt: 'Ethereum icon',
    },
  },
  11155111: {
    id: 11155111,
    name: 'Ethereum Sepolia',
    icon: {
      src: '/icons/eth-icon.svg',
      alt: 'Ethereum icon',
    },
  },
  8453: {
    id: 8453,
    name: 'Base',
    icon: {
      src: '/icons/base-icon.svg',
      alt: 'Base icon',
    },
  },
  84532: {
    id: 84532,
    name: 'Base Sepolia',
    icon: {
      src: '/icons/base-icon.svg',
      alt: 'Base icon',
    },
  },
  42161: {
    id: 42161,
    name: 'Arbitrum',
    icon: {
      src: '/icons/arbitrum-icon.svg',
      alt: 'Arbitrum icon',
    },
  },
  42170: {
    id: 42170,
    name: 'Arbitrum Nova',
    icon: {
      src: '/icons/arbnova-icon.svg',
      alt: 'Arbitrum Nova icon',
    },
  },
  421613: {
    id: 421613,
    name: 'Arbitrum Sepolia',
    icon: {
      src: '/icons/arbitrum-icon.svg',
      alt: 'Arbitrum icon',
    },
  },
  10: {
    id: 10,
    name: 'Optimism',
    icon: {
      src: '/icons/optimism-icon.svg',
      alt: 'Optimism icon',
    },
  },
  11155420: {
    id: 11155420,
    name: 'Optimism Sepolia',
    icon: {
      src: '/icons/optimism-icon.svg',
      alt: 'Optimism icon',
    },
  },
  137: {
    id: 137,
    name: 'Polygon',
    icon: {
      src: '/icons/polygon-icon.svg',
      alt: 'Polygon icon',
    },
  },
  80002: {
    id: 80002,
    name: 'Polygon Amoy',
    icon: {
      src: '/icons/polygon-icon.svg',
      alt: 'Polygon icon',
    },
  },
  1101: {
    id: 1101,
    name: 'Polygon ZkEVM',
    icon: {
      src: '/icons/polygonzkevm-icon.svg',
      alt: 'Polygon ZkEVM icon',
    },
  },
  2442: {
    id: 2442,
    name: 'Polygon ZkEVM Cardona',
    icon: {
      src: '/icons/polygonzkevm-icon.svg',
      alt: 'Polygon ZkEVM icon',
    },
  },
  43114: {
    id: 43114,
    name: 'Avalanche',
    icon: {
      src: '/icons/avalanche-icon.svg',
      alt: 'Avalanche icon',
    },
  },
  43113: {
    id: 43113,
    name: 'Avalanche Fuji',
    icon: {
      src: '/icons/avalanche-icon.svg',
      alt: 'Avalanche icon',
    },
  },
  5000: {
    id: 5000,
    name: 'Mantle',
    icon: {
      src: '/icons/mantle-icon.svg',
      alt: 'Mantle icon',
    },
  },
  5003: {
    id: 5003,
    name: 'Mantle Sepolia',
    icon: {
      src: '/icons/mantle-icon.svg',
      alt: 'Mantle icon',
    },
  },
  59144: {
    id: 59144,
    name: 'Linea',
    icon: {
      src: '/icons/linea-icon.svg',
      alt: 'Linea icon',
    },
  },
  59141: {
    id: 59141,
    name: 'Linea Sepolia',
    icon: {
      src: '/icons/linea-icon.svg',
      alt: 'Linea icon',
    },
  },
  534352: {
    id: 534352,
    name: 'Scroll',
    icon: {
      src: '/icons/scroll-icon.svg',
      alt: 'Scroll icon',
    },
  },
  534351: {
    id: 534351,
    name: 'Scroll Sepolia',
    icon: {
      src: '/icons/scroll-icon.svg',
      alt: 'Scroll icon',
    },
  },
  324: {
    id: 324,
    name: 'zkSync Era',
    icon: {
      src: '/icons/zksync-icon.svg',
      alt: 'zkSync icon',
    },
  },
  300: {
    id: 300,
    name: 'zkSync Era Sepolia',
    icon: {
      src: '/icons/zksync-icon.svg',
      alt: 'zkSync icon',
    },
  },
  81457: {
    id: 81457,
    name: 'Blast',
    icon: {
      src: '/icons/blast-icon.svg',
      alt: 'Blast icon',
    },
  },
  168587773: {
    id: 168587773,
    name: 'Blast Sepolia',
    icon: {
      src: '/icons/blast-icon.svg',
      alt: 'Blast icon',
    },
  },
  56: {
    id: 56,
    name: 'BNB Smart Chain',
    icon: {
      src: '/icons/bnb-icon.svg',
      alt: 'BNB icon',
    },
  },
  97: {
    id: 97,
    name: 'BNB Smart Chain Testnet',
    icon: {
      src: '/icons/bnb-icon.svg',
      alt: 'BNB icon',
    },
  },
  1513: {
    id: 1513,
    name: 'Story Testnet',
    icon: {
      src: '/icons/story-icon.svg',
      alt: 'Story icon',
    },
  },
  480: {
    id: 480,
    name: 'World Chain',
    icon: {
      src: '/icons/world-chain-icon.svg',
      alt: 'World Chain icon',
    },
  },
  4801: {
    id: 4801,
    name: 'World Chain Sepolia',
    icon: {
      src: '/icons/world-chain-icon.svg',
      alt: 'World Chain icon',
    },
  },
  80084: {
    id: 80084,
    name: 'Berachain bArtio',
    icon: {
      src: '/icons/berachain-icon.svg',
      alt: 'Berachain icon',
    },
  },
  7777777: {
    id: 7777777,
    name: 'Zora',
    icon: {
      src: '/icons/zora-icon.svg',
      alt: 'Zora icon',
    },
  },
  999999999: {
    id: 999999999,
    name: 'Zora Sepolia',
    icon: {
      src: '/icons/zora-icon.svg',
      alt: 'Zora icon',
    },
  },
  252: {
    id: 252,
    name: 'Fraxtal',
    icon: {
      src: '/icons/fraxtal-icon.svg',
      alt: 'Fraxtal icon',
    },
  },
  2522: {
    id: 2522,
    name: 'Fraxtal Testnet',
    icon: {
      src: '/icons/fraxtal-icon.svg',
      alt: 'Fraxtal icon',
    },
  },
  1946: {
    id: 1946,
    name: 'Soneium Minato',
    icon: {
      src: '/icons/soneium-icon.ico',
      alt: 'Soneium icon',
    },
  },
  11124: {
    id: 11124,
    name: 'Abstract Testnet',
    icon: {
      src: '/icons/abstract-icon.png',
      alt: 'Abstract icon',
    },
  },
  13371: {
    id: 13371,
    name: 'ImmutableX',
    icon: {
      src: '/icons/immutablex-icon.svg',
      alt: 'ImmutableX icon',
    },
  },
  13473: {
    id: 13473,
    name: 'ImmutableX Testnet',
    icon: {
      src: '/icons/immutablex-icon.svg',
      alt: 'ImmutableX icon',
    },
  },
  34443: {
    id: 34443,
    name: 'Mode',
    icon: {
      src: '/icons/mode-icon.svg',
      alt: 'Mode icon',
    },
  },
  919: {
    id: 919,
    name: 'Mode Testnet',
    icon: {
      src: '/icons/mode-icon.svg',
      alt: 'Mode icon',
    },
  },
  100: {
    id: 100,
    name: 'Gnosis',
    icon: {
      src: '/icons/gnosis-icon.svg',
      alt: 'Gnosis icon',
    },
  },
  10200: {
    id: 10200,
    name: 'Gnosis Testnet',
    icon: {
      src: '/icons/gnosis-icon.svg',
      alt: 'Gnosis icon',
    },
  },
  42220: {
    id: 42220,
    name: 'Celo',
    icon: {
      src: '/icons/celo-icon.svg',
      alt: 'Celo icon',
    },
  },
  44787: {
    id: 44787,
    name: 'Celo Alfajores',
    icon: {
      src: '/icons/celo-icon.svg',
      alt: 'Celo icon',
    },
  },
  1301: {
    id: 1301,
    name: 'Unichain Sepolia',
    icon: {
      src: '/icons/unichain-icon.svg',
      alt: 'Unichain icon',
    },
  },
  8217: {
    id: 8217,
    name: 'Kaia',
    icon: {
      src: '/icons/kaia-icon.svg',
      alt: 'Kaia icon',
    },
  },
  1001: {
    id: 1001,
    name: 'Kaia Kairos',
    icon: {
      src: '/icons/kaia-icon.svg',
      alt: 'Kaia icon',
    },
  },
  911867: {
    id: 911867,
    name: 'Odyssey',
    icon: {
      src: '/icons/odyssey-icon.svg',
      alt: 'Odyssey icon',
    },
  },
  690: {
    id: 690,
    name: 'Redstone',
    icon: {
      src: '/icons/redstone-icon.jpg',
      alt: 'Redstone icon',
    },
  },
  17069: {
    id: 17069,
    name: 'Redstone Garnet',
    icon: {
      src: '/icons/redstone-icon.jpg',
      alt: 'Redstone icon',
    },
  },
  2818: {
    id: 2818,
    name: 'Morph',
    icon: {
      src: '/icons/morph-icon.jpeg',
      alt: 'Morph icon',
    },
  },
  2810: {
    id: 2810,
    name: 'Morph Testnet',
    icon: {
      src: '/icons/morph-icon.jpeg',
      alt: 'Morph icon',
    },
  },
  763373: {
    id: 763373,
    name: 'Ink Sepolia',
    icon: {
      src: '/icons/ink-icon.jpg',
      alt: 'Ink icon',
    },
  },
} as const;

// Define an array of keys in the desired order
const chainOrder: (keyof typeof chains)[] = [
  1, 11155111, 11124, 42161, 421613, 42170, 43114, 43113, 8453, 84532, 80084,
  81457, 168587773, 56, 97, 42220, 44787, 252, 2522, 100, 10200, 690, 17069,
  13371, 13473, 763373, 8217, 1001, 59144, 59141, 5000, 5003, 34443, 919, 2818,
  2810, 10, 11155420, 911867, 137, 80002, 1101, 2442, 534352, 534351, 1946,
  1513, 1301, 480, 4801, 324, 300, 7777777, 999999999,
];

// Map over the ordered keys to get the ordered chains
export const orderedChains = chainOrder.map((key) => chains[key]);

export type ChainId = keyof typeof chains;

export enum SearchBy {
  Name = 'name',
  Symbol = 'symbol',
  Address = 'address',
}
